export default {
  menu: {
    changeAvatar: 'Изменить фотографию',
    logOut: ''
  },
  button: {
    search: 'Поиск'
  },
  router: {
    null: '',
    services: 'Сервис',
    servicesId: 'Сервис',
    invoices: 'Счета',
    invoicesId: 'Счета',
    requisites: 'Реквизиты',
    costs: 'Тарифы',
    newsFeed: 'События',
    places: 'Помещение',
    placesOrders: 'Заказы',
    placesOrdersId: 'Заказы',
    placesHistory: 'История',
    placesPasses: 'Пропуска',
    placesPassesForm: 'Форма создания пропуска',
    placesPassesPermanentForm: 'Форма создания постоянного пропуска',
    placesInfo: 'Информация',
    placesPeople: 'Люди',
    placesPeopleId: 'Люди',
    placesDocuments: 'Документы',
    placesCounters: 'Счетчики',
    placesCountersId: 'Счетчики',
    pollsId: 'Опрос',
    newsId: 'Новость',
    payments: 'История оплат',
    placesChat: 'Сообщения',
    info: 'Меню',
    faq: 'Вопросы и ответы',
    about: 'О приложении',
    forum: 'Форум',
    documents: 'Документы',
    contacts: 'Контакты',
    cameras: 'Камеры',
    offices: 'Офисы продаж',
    building: 'Дом'
  },
  error: {
    wrongImageType: 'Неверный формат изображения'
  },
  success: {
    imageUpdated: 'Изображение успешно обновлено'
  },
  vuetify: {
    badge: 'знак',
    close: 'Закрыть',
    dataIterator: {
      noResultsText: 'Не найдено подходящих записей',
      loadingText: 'Запись загружается...'
    },
    dataTable: {
      itemsPerPageText: 'Строк на странице:',
      ariaLabel: {
        sortDescending: 'Упорядочено по убыванию.',
        sortAscending: 'Упорядочено по возрастанию.',
        sortNone: 'Не упорядочено.',
        activateNone: 'Активируйте, чтобы убрать сортировку.',
        activateDescending: 'Активируйте для упорядочивания убыванию.',
        activateAscending: 'Активируйте для упорядочивания по возрастанию.'
      },
      sortBy: 'Сортировать по'
    },
    dataFooter: {
      itemsPerPageText: 'Записей на странице:',
      itemsPerPageAll: 'Все',
      nextPage: 'Следующая страница',
      prevPage: 'Предыдущая страница',
      firstPage: 'Первая страница',
      lastPage: 'Последняя страница',
      pageText: '{0}-{1} из {2}'
    },
    datePicker: {
      itemsSelected: '{0} выбран'
    },
    noDataText: 'Отсутствуют данные',
    carousel: {
      prev: 'Предыдущий слайд',
      next: 'Следующий слайд',
      ariaLabel: {
        delimiter: 'Слайд {0} из {1}'
      }
    },
    calendar: {
      moreEvents: 'Еще {0}'
    },
    fileInput: {
      counter: 'Файлов: {0}',
      counterSize: 'Файлов: {0} (всего {1})'
    },
    timePicker: {
      am: 'AM',
      pm: 'PM'
    }
  }
}
