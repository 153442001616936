
import { Component, Vue } from 'vue-property-decorator'
import { $post } from '@/plugins/axios'
import { PageModule } from '@/store/page'
import { HandleApiRequest, HandleError } from '@/utils/helper'
import { UserModule } from '@/store/user'
import { PlaceModule } from '@/store/place'
import { ICurrentCustomer } from '@/model'
import PeopleAvatar from '@/components/page/place/PeopleAvatar.vue'
import AppPasswordInput from '@/components/app/AppPasswordInput.vue'
@Component({
  components: {
    PeopleAvatar,
    AppPasswordInput
  }
})
export default class EditUserPassword extends Vue {
  // data
  loading = true;
  showConfirm = false;
  passwordData = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  }

  // getters
  get user(): Partial<ICurrentCustomer> {
    return UserModule.user
  }

  // actions
  async setPassword(): Promise<void> {
    try {
      const data = this.user.hasPassword ? this.passwordData : { password: this.passwordData.newPassword, passwordConfirmation: this.passwordData.newPasswordConfirmation }
      const _url = this.user.hasPassword ? '/current-customer/change-password' : '/current-customer/set-password'

      await $post(_url, data)
      PageModule.SEND_NOTIFICATION({ type: 'success', message: 'Пароль успешно установлен' })
      this.passwordData = {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: ''
      }
      this.$router.push('/info/personal')
    } catch (e) {
      HandleError(e)
    }
  }

  handleForgotPassword(): void {
    UserModule.logout()
    localStorage.setItem('phone', this.user.phoneNumber!)
    localStorage.removeItem('token')
    this.$router.push({ name: 'login' })
  }

  async mounted() {
    this.loading = true
    await UserModule.getUserProfile()
    this.loading = false
  }
}
