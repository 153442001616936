import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import { $get, $patch, $post, $put, $remove } from '@/plugins/axios'
import store from '@/store'
import { PageModule } from '@/store/page'
import uniqBy from 'lodash/uniqBy'

import {
  ApiItemsLayout,
  ApiItemsPlaceholderLayout,
  IDocument,
  IItemsLayout
} from '@/model/index'

import {
  getOrdersLayout,
  IHuman,
  IOrder,
  IOrderActive,
  IOrderCancelFeedbackCategory,
  IOrderFull,
  IPeopleCreate,
  IPeopleGroup,
  IPeopleLayout,
  IPeopleRole,
  IPlaceInformation,
  IPlacesLayout
} from '@/model/page/counter'

export interface PlaceState {
  places: IPlacesLayout
  orders: Array<IOrder>
  orderFeedbackCategories: Array<IOrderCancelFeedbackCategory>
  people: Array<IPeopleGroup>
  activeOrders: Array<IOrderActive>
  info: Array<IPlaceInformation>
  documents: Array<IDocument>
}
@Module({ dynamic: true, store, name: 'place' })
class Place extends VuexModule implements PlaceState {
  places: IPlacesLayout = {} as IPlacesLayout
  order: IOrderFull = {} as IOrderFull
  orderFeedbackCategories: Array<IOrderCancelFeedbackCategory> = []
  orders: Array<IOrder> = []
  people: Array<IPeopleGroup> = []
  activeOrders: Array<IOrderActive> = []
  info: Array<IPlaceInformation> = []
  documents: Array<IDocument> = []

  @Mutation
  SET_STATE_PLACE<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Mutation
  ADD_ORDERS(items: Array<IOrder>) {
    this.orders = uniqBy([...this.orders, ...items], 'id')
  }

  @Action
  async getOrders({ fromRow } = { fromRow: 0 }) {
    const { data } = await $get<getOrdersLayout<IOrder>>('/orders', { params: { fromRow } })

    this.ADD_ORDERS(data.items)

    return data
  }

  @Action
  async getOrder({ id, scopeTypeId }: Record<'id' | 'scopeTypeId', string>) {
    const { data } = await $get<IOrderFull>(`/orders/${id}`, { params: { scopeTypeId } })
    this.SET_STATE_PLACE({ key: 'order', value: data })
  }

  @Action
  async setOrderViewedStatus({ id, scopeTypeId }: Record<'id' | 'scopeTypeId', string>): Promise<void> {
    return await $put(`/orders/${id}/setviewedstatus?scopeTypeId=${scopeTypeId}`)
  }

  @Action
  async getFeedbackCategories({ id, scopeTypeId }: Record<'id' | 'scopeTypeId', string>) {
    const { data } = await $get<{ feedbackCategories: IOrderCancelFeedbackCategory[] }>(`/orders/${id}/feedback-category`, { params: { scopeTypeId } })
    this.SET_STATE_PLACE({ key: 'orderFeedbackCategories', value: data.feedbackCategories })
  }

  @Action
  async cancelOrder({ id, scopeTypeId }: Record<'id' | 'scopeTypeId', string>) {
    await $get(`/orders/${id}/cancel`, { params: { scopeTypeId } })
  }

  @Action
  async sendCancelOrderFeedback(
    { id, scopeTypeId, serviceFeedbackCategories, comment }
      : Record<'id' | 'scopeTypeId' | 'serviceFeedbackCategories' | 'comment', string | number | IOrderCancelFeedbackCategory[]>) {
    const serviceFeedbackCategoryIds = (serviceFeedbackCategories as IOrderCancelFeedbackCategory[]).filter(Boolean).map((category) => category.id)
    const data = await $post(`/orders/${id}/feedback`, { serviceFeedbackCategoryIds, comment }, { params: { scopeTypeId } })
    return data
  }

  @Action
  async refundOrder(
    { id, scopeTypeId, refundType, refundComment, refundAmount }
    : Record<'id' | 'scopeTypeId' | 'refundType' | 'refundComment' | 'refundAmount', string | number>) {
    const data = await $post(`/orders/${id}/refund`, { refundType, refundComment, refundAmount }, { params: { scopeTypeId } })
    return data
  }

  @Action
  async getActiveOrders() {
    const { data } = await $get<getOrdersLayout<IOrderActive>>('/orders/active')

    this.SET_STATE_PLACE({ key: 'activeOrders', value: data.items })
  }

  @Action
  async getPeople() {
    const { data } = await $get<IPeopleLayout>('/currentplace/customers/')

    this.SET_STATE_PLACE({ key: 'people', value: data.items })
  }

  @Action
  async getHuman(id: number | string) {
    const { data } = await $get<IHuman>(`/currentplace/customers/${id}`)

    return data
  }

  @Action
  async deleteHuman(id: number | string) {
    const data = await $remove(`/currentplace/customers/${id}`)

    return data
  }

  @Action
  async updateHuman(human: IHuman) {
    const { success } = await $patch(`/currentplace/customers/${human.id}`, {
      placeToCustomerStatusId: human.placeToCustomerStatusId,
      paymentDay: human.paymentDay
    })
    if (success) {
      PageModule.SEND_NOTIFICATION({ type: 'success', message: 'Данные успешно обновлены' })
    }
  }

  @Action
  async getPlaceInfo() {
    const { data } = await $get<IItemsLayout<IPlaceInformation>>('/currentplace/info/')

    this.SET_STATE_PLACE({ key: 'info', value: data.items })
  }

  @Action
  async getFiles() {
    const { data } = await $get<ApiItemsPlaceholderLayout>('/currentplace/files/')

    this.SET_STATE_PLACE({ key: 'documents', value: data.items })
  }

  @Action
  async getPlaces() {
    const { data } = await $get<IPlacesLayout>('/sections/places/')

    this.SET_STATE_PLACE({ key: 'places', value: data })
  }

  @Action
  async createPeople(people: IPeopleCreate) {
    const { data } = await $post<IPlacesLayout>('/currentplace/customers', people)

    return data
  }

  @Action
  async getPeopleRoles() {
    const { data } = await $get<ApiItemsLayout<IPeopleRole>>('/placetocustomerstatuses')

    return data.items
  }

  @Action
  async rateOrder(
    { id, rating, ratingComment, scopeTypeId }
    : Record<'id' | 'rating' | 'ratingComment' | 'scopeTypeId', string | number>
  ) {
    const { data, success } = await $put(`/orders/${id}/rate`, { rating, ratingComment }, { params: { scopeTypeId } })
    if (success) {
      PageModule.SEND_NOTIFICATION({ type: 'success', message: 'Оценка успешно изменена' })
    }
    return data
  }

  @Action
  async UpdateUserFio({ firstName, lastName, middleName, customerTypeId, shortName, fullName }: {[key: string]: string | number}) {
    const { data, success } = await $post('/current-customer', { firstName, lastName, middleName, customerTypeId, shortName, fullName })
    if (success) {
      PageModule.SEND_NOTIFICATION({ type: 'success', message: 'ФИО успешно изменен' })
    } else {
      PageModule.SEND_NOTIFICATION({ type: 'error', message: 'Произошла ошибка' })
    }
    return data
  }

  @Action
  async GetCustomerTypes() {
    const { data, success } = await $get('/customers/types/')
    if (!success) { PageModule.SEND_NOTIFICATION({ type: 'error', message: 'Произошла ошибка при получении типов пользователя' }) }

    return data
  }

  @Action
  async reopenOrder({ id, text, scopeTypeId }: { id: string, text: string, scopeTypeId: string }) {
    await $post(`/orders/${id}/reopen`, { text }, { params: { scopeTypeId } })
  }
}

export const PlaceModule = getModule(Place)
