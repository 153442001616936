
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PassModule } from '@/store/pass'
import { HandleApiRequest } from '@/utils/helper'

import PassBlock from '@/components/page/place/PassBlock.vue'

@Component({
  components: {
    PassBlock
  }
})
export default class PassesPage extends Vue {
  isLoading = true
  tab = ''
  nextPassRow = PassModule.passes.length
  isMorePassLoading = false
  nextPermanentRow = PassModule.permanentPasses.length
  isMorePermanentLoading = false
  isRenderedLayout = false

  @Prop({ type: Boolean }) isLayout!: boolean

  get allowViewAll() {
    return this.passToView.length === 2
  }

  get passToView() {
    const pass: Record<string, any> = []
    if (this.passesPermission?.pass?.isAllowedView) {
      pass.push({
        type: 'pass',
        permit: this.passesPermission.pass,
        items: this.passes
      })
    }

    if (this.passesPermission?.permanentPass?.isAllowedView) {
      pass.push({
        type: 'permanent',
        permit: this.passesPermission.permanentPass,
        items: this.permanentPasses
      })
    }

    return pass
  }

  get passes() {
    return PassModule.passes
  }

  get permanentPasses() {
    return PassModule.permanentPasses
  }

  get passesPermission() {
    return PassModule.passesPermission
  }

  get mayShowButton() {
    if (!this.isLayout) {
      return false
    }
    const createPass = (this.tab === 'pass' && this.passesPermission.pass.isAllowedCreate)
    const createPermanentPass = (this.tab === 'permanent' && this.passesPermission.permanentPass.isAllowedCreate)
    const createOnlyPass = !this.allowViewAll && this.passToView[0].permit.isAllowedCreate

    return createPass || createPermanentPass || createOnlyPass
  }

  get generateFormButtonLink() {
    const type = this.allowViewAll ? this.tab : this.passToView[0].type

    return `/places/passes/${type === 'pass' ? 'form' : 'permanent-form'}`
  }

  @HandleApiRequest({ prop: 'isMorePassLoading' })
  async getMorePass(entries: Array<IntersectionObserverEntry>) {
    if (entries[0].isIntersecting) {
      this.nextPassRow = await PassModule.getPasses({ fromRow: this.nextPassRow })
    }
  }

  @HandleApiRequest({ prop: 'isMorePermanentLoading' })
  async getMorePermanent(entries: Array<IntersectionObserverEntry>) {
    if (entries[0].isIntersecting) {
      this.nextPermanentRow = await PassModule.getPassesPermanent({ fromRow: this.nextPermanentRow })
    }
  }

  @HandleApiRequest()
  async mounted() {
    localStorage.setItem('placesActiveMenu', 'PassesPage')

    const { type } = this.$route.query

    if (type) {
      this.tab = type as string
      const query = Object.assign({}, this.$route.query)
      delete query.type
      this.$router.replace({ query })
    }

    PassModule.clearPasses()
    this.nextPassRow = await PassModule.getPasses()
    this.nextPermanentRow = await PassModule.getPassesPermanent()

    await PassModule.getPassesPermission()

    setTimeout(() => {
      this.isRenderedLayout = true
    }, 1000)
  }
}
