
import { Vue, Component, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { ICounter } from '@/model/page/counter'

@Component
export default class CounterBlock extends Vue {
  @Prop({ required: true }) counter!: ICounter
  @Prop({ type: String, default: '0' }) isGeneral!: string
}
