
import { Vue, Component, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IAdvertFull } from '@/model/page/adverts'

@Component
export default class AdvertBlock extends Vue {
  @Prop({ required: true }) info!: IAdvertFull
  @Prop() showStatus?: boolean

  get link() {
    if (this.$route.name === 'advertsMy') {
      return `/info/adverts-my/${this.info.id}`
    }

    return `/info/adverts/${this.info.id}`
  }
}
