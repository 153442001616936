
import { Component, Vue, Prop } from 'vue-property-decorator'
import { InfoModule } from '@/store/info'

@Component
export default class InfoDocuments extends Vue {
  @Prop({ type: String }) route!: string

  isLoading = false

  get documents() {
    return InfoModule.documents
  }

  async mounted() {
    try {
      this.isLoading = true
      await InfoModule.getDocuments(this.route)
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error)
    }
  }
}
