
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import dayjs from 'dayjs'

@Component
export default class AppDesktop extends Vue {
  @Prop({ type: String, default: null }) value!: string;

  activePicker: string = '';
  date = this.value;
  menu = false;

  get formattedDate(): string {
    return dayjs(this.date).format('DD.MM.YYYY')
  }

  save(date: string) {
    (this.$refs.menu as any).save(date)
    this.$emit('save', date)
  }

  @Watch('menu')
  onMenuChange(val: boolean) {
    val && setTimeout(() => (this.activePicker = 'YEAR'))
  }
}
