
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { VHover, VListItem } from 'vuetify/lib'

// eslint-disable-next-line no-unused-vars
import { CreateElement, VNode } from 'vue/types'

import { PageModule } from '@/store/page'
import { UserModule } from '@/store/user'

// eslint-disable-next-line no-unused-vars
import { EventLayout } from '@/model/index'

import FlashlightButton from '@/components/general/FlashlightButton.vue'

interface ProfileMenu {
  title: string,
  callbackClick: Function
}

const htmlElement = document.querySelector('html')

@Component({
  components: {
    FlashlightButton,
    AppBarItem: {
      render(h: CreateElement): VNode {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }: any) => {
              return h(VListItem, {
                attrs: this.$attrs,
                class: {
                  'black--text': !hover,
                  'white--text primary elevation-12': hover
                },
                props: {
                  activeClass: '',
                  dark: hover,
                  link: true,
                  ...this.$attrs
                }
              }, this.$slots.default)
            }
          }
        })
      }
    }
  }
})
export default class AppBar extends Vue {
  @Ref('profileMenuList') profileMenuList: any | null

  profile: Array<ProfileMenu | { divider: boolean }> = []
  image = ''
  highlightedIndex = 0
  needFocusForProfileMenu = true

  get user() {
    return UserModule.user
  }

  get isInited() {
    return PageModule.isInited
  }

  get breadCrumbsTitle() {
    return PageModule.breadCrumbsTitle
  }

  get isSimpleRoute() {
    const url = this.isInited ? this.$route.fullPath : window.location.pathname

    return url.split('/').filter(Boolean).length === 1
  }

  get showReturnButton() {
    return !this.isSimpleRoute && this.$route?.meta?.hasReturnButton !== false
  }

  get showFlashLight() {
    return this.$vuetify.breakpoint.smAndDown && this.$route.name === 'placesCountersId'
  }

  @Prop({ type: Boolean, default: false }) readonly value!: boolean

  mounted() {
    this.profile = [
      {
        title: 'Профиль',
        callbackClick: () => this.$router.push({ name: 'personal' })
      },
      {
        title: 'Безопасность',
        callbackClick: () => this.$router.push({ name: 'password-change' })
      },
      {
        title: 'Изменить фотографию',
        callbackClick: () => (this.$refs.uploadAvatar as HTMLElement).click()
      },
      { divider: true },
      {
        title: 'Выйти',
        callbackClick: async() => {
          try {
            await UserModule.logout()
            localStorage.removeItem('token')
            localStorage.removeItem('pushToken')
            localStorage.removeItem('authorization')

            this.$router.push('/login')
          } catch (error) {
            if (error.isAxiosError) {
              PageModule.SEND_NOTIFICATION({ type: 'error', message: error?.response?.data?.userMessages[0] })
            }
          }
        }
      }
    ]

    document.body.addEventListener('click', () => {
      if (!this.needFocusForProfileMenu) {
        this.profileMenuList.$el.onkeydown = removeEventListener('keydown', this.highlightFocusedTemplate)
        // eslint-disable-next-line no-unused-expressions
        htmlElement?.classList.remove('overflow-hidden')
      }
    })
  }

  choicePlace() {
    PageModule.SET_STATE_PAGE({ key: 'viewPageChoice', value: true })
  }

  goBack() {
    if (this.$route.fullPath.match(/\/services\/orders\/[0-9]+\/chat/) ||
      this.$route.fullPath.match(/\/places\/orders\/[0-9]+\/chat/)) {
      this.$router.back()
    } else {
      const url = '/' + this.$route.fullPath
        .split('/')
        .filter(Boolean)
        .slice(0, -1)
        .join('/')

      if (PageModule.routeReturnFunciton) {
        PageModule.routeReturnFunciton(url)
        return
      }

      this.$router.push(url)
    }
  }

  handleClick() {
    this.$emit('input', !this.value)
  }

  async handleUploadAvatar(event: EventLayout<HTMLInputElement>) {
    const file = (event.target.files as FileList)[0]
    if (!file) {
      return
    }
    const { type } = file

    const extentions = ['image/jpeg', 'image/png']

    if (!extentions.includes(type)) {
      return PageModule.SEND_NOTIFICATION({ type: 'error', message: this.$t('error.wrongImageType') as string })
    }

    await UserModule.uploadAvatar(file)
  }

  highlightFocusedTemplate(e: KeyboardEvent): void {
    if (e.keyCode === 9 /** tab */) e.preventDefault()
    else if (e.keyCode === 40 /** up */) {
      this.highlightedIndex = this.highlightedIndex + 1
    } else if (e.keyCode === 38 /** down */) {
      this.highlightedIndex = this.highlightedIndex - 1
    } else if (e.keyCode === 13 /** enter */) {
      (this.profile[this.highlightedIndex] as ProfileMenu).callbackClick()
    }

    if (this.highlightedIndex < 0) {
      this.highlightedIndex = this.profile.length - 1
    }

    if (this.highlightedIndex > this.profile.length - 1) {
      this.highlightedIndex = 0
    }

    this.scrollToHighlighted()
  }

  scrollToHighlighted(): void {
    if (this.profileMenuList.$children[this.highlightedIndex].$el != null) {
      this.profileMenuList.$children[this.highlightedIndex].$el.focus().scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }

  async toggleMenu(value: boolean): Promise<void> {
    await this.$nextTick()
    this.profileMenuList.$children[this.highlightedIndex].$el.focus()
    if (!value) {
      this.profileMenuList.$el.onkeydown = addEventListener('keydown', this.highlightFocusedTemplate)
      this.needFocusForProfileMenu = value
      // eslint-disable-next-line no-unused-expressions
      htmlElement?.classList.add('overflow-hidden')
    } else {
      this.profileMenuList.$el.onkeydown = removeEventListener('keydown', this.highlightFocusedTemplate)
      // eslint-disable-next-line no-unused-expressions
      htmlElement?.classList.remove('overflow-hidden')
    }
  }
}
