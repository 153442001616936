
import { Component, Vue } from 'vue-property-decorator'
import { HandleApiRequest, countable } from '@/utils/helper'
import { PageModule } from '@/store/page'
import { LoyaltyModule } from '@/store/loyalty'
import LoyaltyPartnerCard from '@/components/page/loyalty/LoyaltyPartnerCard.vue'

@Component({
  components: {
    LoyaltyPartnerCard
  }
})
export default class LoyaltyCategoryIdPage extends Vue {
  id = ''
  isLoading = true
  isMoreLoading = false
  nextRow = 0

  swiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20
  }

  get category() {
    return LoyaltyModule.category
  }

  get categoryItems() {
    return LoyaltyModule.categoryItems
  }

  get categoryTags() {
    return LoyaltyModule.categoryTags
  }

  get selectedTagId() {
    return LoyaltyModule.selectedTagId
  }

  @HandleApiRequest()
  async mounted() {
    PageModule.SET_STATE_PAGE({
      key: 'routeReturnFunciton',
      value: (url: string) => {
        this.$router.push({ name: 'loyalty' })
      }
    })

    this.id = this.$route.params.id
    LoyaltyModule.SET_STATE_LOYALTY({ key: 'category', value: { title: '', itemsCount: 0, programTitle: '' } })
    LoyaltyModule.SET_STATE_LOYALTY({ key: 'categoryItems', value: [] })
    LoyaltyModule.SET_STATE_LOYALTY({ key: 'categoryTags', value: [] })
    LoyaltyModule.SET_STATE_LOYALTY({ key: 'selectedTagId', value: 0 })
    await LoyaltyModule.getLoyaltyTags(this.id)
    this.nextRow = await LoyaltyModule.getLoyaltyCategories({ id: this.id, fromRow: this.nextRow })

    PageModule.SET_STATE_PAGE({ key: 'breadCrumbsTitle', value: this.category.programTitle })
  }

  @HandleApiRequest({ prop: 'isMoreLoading' })
  async getMoreCategories() {
    this.nextRow = await LoyaltyModule.getLoyaltyCategories({ id: this.id, fromRow: this.nextRow })
  }

  @HandleApiRequest({ prop: 'isMoreLoading' })
  async setSelectedTag(tagId: number) {
    this.nextRow = 0
    LoyaltyModule.SET_STATE_LOYALTY({ key: 'categoryItems', value: [] })
    LoyaltyModule.SET_STATE_LOYALTY({ key: 'selectedTagId', value: tagId })
    this.nextRow = await LoyaltyModule.getLoyaltyCategories({ id: this.id, fromRow: this.nextRow })
  }

  countable = countable
}
