
import { Component, Vue, Prop } from 'vue-property-decorator'

import { PageModule } from '@/store/page'
import { ChatModule } from '@/store/chat'

// eslint-disable-next-line no-unused-vars
import { IMessage } from '@/model/page/chat'
import AudioController from '@/components/page/chat/AudioController.vue'

type IMessageOptions = Array<{ title: string; callback: Function }>

@Component({
  components: {
    AudioController
  }
})
export default class MessageBlock extends Vue {
  @Prop({ type: Object, required: true }) message!: IMessage
  @Prop({ type: Boolean, default: false }) showCorner!: boolean;
  @Prop({ type: Boolean, default: false }) showAvatar!: boolean;
  @Prop({ type: Boolean, default: true }) showReplyOptions!: boolean;
  messageOptions: IMessageOptions = []

  click = {
    x: 0,
    y: 0
  }

  isMessageOptionsLoading = false

  get isDark() { return PageModule.isDark }

  get downloadLink() {
    return `${process.env.VUE_APP_FILE_UPLOAD}/${this.message.fileName}?download`
  }

  get ext() {
    const ext = (this.message.fileUrl || '').split('.').pop() || ''
    return ext.toLowerCase()
  }

  get isImage() {
    return ['jpg', 'jpeg', 'png', 'svg'].includes(this.ext)
  }

  get isAudio() {
    return ['mp3', 'ogg'].includes(this.ext)
  }

  get isVideo() {
    return ['mp4'].includes(this.ext)
  }

  get optionsMenuStyle() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return {
        top: `${this.click.y}px`,
        left: '-4px',
        position: 'absolute'
      }
    }

    return ''
  }

  mounted() {
    // фикс бага, что хром не может определить продолжительность аудио
    const audio = this.$refs.audio as any
    if (audio) {
      audio.onloadedmetadata = function(e: any) {
        if (audio.duration === Infinity) {
          // set it to bigger than the actual duration
          audio.currentTime = 1e101
          audio.ontimeupdate = function() {
            this.ontimeupdate = () => {}
            audio.currentTime = 1
            setTimeout(() => { audio.currentTime = 0 })
          }
        }
      }
    }
  }

  closeMessageOptionsMenu() {
    if (this.messageOptions.length) {
      this.messageOptions = []
    }
  }

  handleMessageClick(event: MouseEvent) {
    if (!this.messageOptions.length) {
      const body = this.$refs.body as HTMLElement
      const rect = body.getBoundingClientRect()

      this.click = {
        x: event.clientX - rect.x,
        y: event.clientY - rect.y
      }
      this.handleMessageRightClick(event)
    }
  }

  handleMessageRightClick(event: MouseEvent) {
    const openMenu = () => {
      const items:Record<string, any> = []

      if (this.message.isDeleteAllowed) {
        items.push({
          title: 'Удалить',
          callback: async() => {
            try {
              this.$emit('delete', this.message)
              this.isMessageOptionsLoading = true
              const orderId = this.$route.params.id
              const scopeTypeId = this.$route.query.scopeTypeId as string

              await ChatModule.deleteMessage({ orderId, scopeTypeId, messageId: this.message.id })
            } catch (error) {
              this.$handleApiError(error)
            } finally {
              this.isMessageOptionsLoading = false
            }
          }
        })
      }

      if (this.message.isEditAllowed) {
        items.push(
          {
            title: 'Изменить',
            callback: () => {
              this.$emit('change', this.message)
              this.messageOptions = []
            }
          }
        )
      }

      if (this.message.fileUrl) {
        items.push(
          {
            title: 'Скачать',
            callback: () => {
              const link = document.createElement('a')
              link.href = this.message.fileUrl + '?download'
              link.download = this.message.fileOriginalName || ''

              link.click()
              this.messageOptions = []
            }
          }
        )
      }

      this.messageOptions = items as {title: string, callback: Function}[]
    }

    if (this.messageOptions.length) {
      this.messageOptions = []
      setTimeout(openMenu, 200)
    } else {
      openMenu()
    }

    event.stopPropagation()
  }

  onReplyClick(btn: Record<string, string |number>):void {
    this.$emit('onReplyClick', btn)
  }
}
