import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'

import store from '@/store/index'
import { $get } from '@/plugins/axios'

import { IItemsLayout, ApiItemsPlaceholderLayout, ApiItemsLayout } from '@/model/index'
import {
  IAbout,
  ICompany,
  IInfoPage,
  IBuilding,
  ICameraItem,
  IDocumentPass,
  IRequisiteItem,
  IUsefullContacts
} from '@/model/page/info'

export interface InfoState {
  info: IInfoPage
}

@Module({ dynamic: true, store, name: 'info' })
class Info extends VuexModule implements InfoState {
  info: IInfoPage = {} as IInfoPage
  cameras: Array<ICameraItem> = []
  about: IAbout = {} as IAbout
  faq: any = []
  forum: any = []
  offices: any = []
  building: IBuilding = {} as IBuilding
  company: ICompany = {} as ICompany
  requisites: Array<IRequisiteItem> = []
  contacts: any = {}
  usefullContacts = [] as IUsefullContacts[]
  documents: Array<IDocumentPass> = []

  @Mutation
  SET_STATE_INFO<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Action
  async getInfo() {
    const { data } = await $get<IInfoPage>('/sections/info')

    this.SET_STATE_INFO({ key: 'info', value: data })
  }

  @Action
  async getFaq() {
    const { data } = await $get('/faq')

    this.SET_STATE_INFO({ key: 'faq', value: data.items })
  }

  @Action
  async getOffices() {
    const { data } = await $get('/currentbuilding/offices')

    this.SET_STATE_INFO({ key: 'offices', value: data.items })
  }

  @Action
  async getForum() {
    const { data } = await $get<IInfoPage>('/sections/info')

    this.SET_STATE_INFO({ key: 'forum', value: data })
  }

  @Action
  async getAbout() {
    const { data } = await $get<IAbout>('/about')

    this.SET_STATE_INFO({ key: 'about', value: data })
  }

  @Action
  async getCameras() {
    const { data } = await $get<IItemsLayout<ICameraItem>>('/currentbuilding/cameras')

    this.SET_STATE_INFO({ key: 'cameras', value: data.items })
  }

  @Action
  async getBuilding() {
    const { data } = await $get<IBuilding>('/currentbuilding')

    this.SET_STATE_INFO({ key: 'building', value: data })
  }

  @Action
  async getCompany() {
    const { data } = await $get<ICompany>('/currentcompany')

    this.SET_STATE_INFO({ key: 'company', value: data })
  }

  @Action
  async getCompanyRequisites() {
    const { data } = await $get<IItemsLayout<IRequisiteItem>>('/currentcompany/requisites')

    this.SET_STATE_INFO({ key: 'requisites', value: data.items })
  }

  @Action
  async getContacts(route: string) {
    const { data } = await $get(`/${route}/contacts`)

    this.SET_STATE_INFO({ key: 'contacts', value: data })
  }

  @Action
  async getUsefullContacts() {
    const { data } = await $get<ApiItemsLayout<IUsefullContacts>>('/contacts')

    this.SET_STATE_INFO({ key: 'usefullContacts', value: data.items })
  }

  @Action
  async getDocuments(route: string) {
    const { data } = await $get<ApiItemsPlaceholderLayout<IDocumentPass>>(`/${route}/documents`) // currentbuilding

    this.SET_STATE_INFO({ key: 'documents', value: data.items })
  }
}

export const InfoModule = getModule(Info)
