
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormParser from '@/components/general/FormParser.vue'
import { UserModule } from '@/store/user'
// eslint-disable-next-line no-unused-vars
import { ComputedServiceForm, IFormElement } from '@/model/page/service'
import { PageModule } from '@/store/page'

@Component({
  components: {
    FormParser
  }
})
export default class RegisterCard extends Vue {
  @Prop({ type: String, default: '' }) btnColor: string | undefined;

  view = 'choice'
  form = [
    {
      id: 'lastName',
      placeholder: 'Фамилия',
      required: true,
      title: 'Фамилия',
      type: 'string',
      value: '' || this.userInfo.lastName
    },
    {
      id: 'firstName',
      placeholder: 'Имя',
      required: true,
      title: 'Имя',
      type: 'string',
      value: '' || this.userInfo.name
    },
    {
      id: 'middleName',
      placeholder: 'Отчество',
      required: false,
      title: 'Отчество',
      type: 'string',
      value: '' || this.user.middleName
    },
    {
      id: 'address',
      title: 'Адрес',
      type: 'autocomplete',
      callbackInput: async(element: IFormElement, event: any) => {
        const value = event.target.value
        element.searchQuery = value

        if (value.length >= 3) {
          element.isLoading = true
          const { items, nextRow } = await UserModule.getBuildings({ searchQuery: value, fromRow: 0 })
          const finalItems = items.map((item) => {
            return { text: item.address, value: item }
          })

          if (finalItems.length === 0) {
            element.items = [{
              text: value,
              value: 'empty'
            }]
            element.isLoading = false
            return
          }

          element.items = finalItems
          element.nextRow = nextRow
          element.isLoading = false
        } else {
          element.items = []
          element.nextRow = 0
        }
      },
      callbackLoadMore: async(entries: Array<IntersectionObserverEntry>) => {
        if (entries[0].isIntersecting) {
          const element: any = this.form.find(el => el.id === 'address')
          if (!element) return
          try {
            element.isLoading = true
            const { items, nextRow } = await UserModule.getBuildings({
              searchQuery: element.searchQuery!,
              fromRow: element.nextRow!
            })

            const finalItems = items.map((item) => {
              return { text: item.address, value: item }
            })

            if (finalItems.length !== 0) {
              element.items.push(...finalItems)
            }
            element.nextRow = nextRow
          } finally {
            element.isLoading = false
          }
        }
      },
      value: '',
      items: [],
      nextRow: 0,
      isLoading: false,
      searchQuery: ''
    },
    {
      id: 'managingOrganization',
      required: true,
      title: 'Управляющая организация',
      showIfElementId: 'address',
      showIfElementValue: 'empty',
      type: 'autocomplete',
      callbackInput: async(element: IFormElement, event: any) => {
        const value = event.target.value

        if (value.length >= 3) {
          const items = await UserModule.getCompanies(value)
          const finalItems = items.map((item) => {
            return { text: item.title, value: item.id }
          })

          element.items = finalItems
        } else {
          element.items = []
        }
      },
      value: '',
      items: []
    },
    {
      id: 'accountNumber',
      placeholder: 'Номер лицевого счета',
      required: false,
      title: 'Номер лицевого счета',
      type: 'string',
      value: ''
    },
    {
      id: 'placeNumber',
      placeholder: 'Номер квартиры / владения',
      required: true,
      title: 'Номер квартиры / владения',
      type: 'string',
      value: ''
    }
  ]

  get user() {
    return UserModule.user
  }

  get userInfo(): {name: string; lastName: string} {
    if (!this.user.fullName) return { name: '', lastName: '' }
    const FIO = this.user.fullName.split(' ')
    if (!FIO.length) return { name: '', lastName: '' }
    return {
      name: FIO[0],
      lastName: FIO[1]
    }
  }

  async handleForm(form: ComputedServiceForm) {
    let textAddress = ''

    const formatForm: any = form.reduce((object: any, input) => {
      object[input.id] = input.value
      if (input.id === 'address') {
        textAddress = input.text || ''
      }
      return object
    }, {})

    let [companyId, buildingId] = [null, null]
    if (formatForm.managingOrganization) {
      companyId = formatForm.managingOrganization
    } else {
      companyId = formatForm.address.companyId
      buildingId = formatForm.address.id
    }

    if (formatForm.address === 'empty') {
      formatForm.address = textAddress
    } else {
      formatForm.address = formatForm.address.address
    }

    formatForm.companyId = companyId
    formatForm.buildingId = buildingId

    await UserModule.createUser(formatForm)
    await UserModule.getUser()

    if (this.user.places!.length > 0) {
      await PageModule.initPage()
      this.$router.push('/services')
    }

    this.$emit('change-step', 'auth-user-view')
  }
}
