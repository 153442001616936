
import { Component, Vue } from 'vue-property-decorator'
import UAParser from 'ua-parser-js'
import cookies from 'js-cookie'
import SmartBannerPanel from '@/components/app/AppSmartBanner.vue'
// eslint-disable-next-line no-unused-vars
import { getAppLink } from '@/model'
import { PageModule } from '@/store/page'

const agent = new UAParser()
@Component({
  components: {
    SmartBannerPanel
  }
})
export default class AppSmartBannerContainer extends Vue {
  // applications
  show = false;
  transition = 'slideY';
  appearDelay = 1000;
  daysHidden = 30;
  daysReminder = 30;
  // getters
  get applications(): getAppLink {
    return PageModule.applications
  }

  get isMobilePhone() {
    return this.isPhone && (this.isIOS || this.isAndroid)
  }

  get isPhone() {
    return agent.getDevice().type === 'mobile'
  }

  get isIOS() {
    return agent.getOS().name === 'iOS'
  }

  get isAndroid() {
    return agent.getOS().name === 'Android'
  }

  get hasClosedBanner() {
    return cookies.get('hasClosedMobileAppBanner') === 'true'
  }

  get hasOpenedStorePage() {
    return cookies.get('hasOpenedMobileAppStore') === 'true'
  }

  get showPanel() {
    const backRef = new URLSearchParams(window.location.search).get('miniAppBackRef')

    return this.show &&
      !this.hasClosedBanner &&
      !this.hasOpenedStorePage &&
      this.isMobilePhone &&
      !backRef
  }

  get appUrl() {
    return this.isAndroid
      ? this.applications.downloadUrls.android
      : this.applications.downloadUrls.ios
  }

  get appType() {
    return this.isAndroid ? 'Play Market' : 'App Store'
  }

  // actions
  daysInSeconds(days: number): number {
    return days * 24 * 60 * 60
  }

  close() {
    cookies.set(
      'hasClosedMobileAppBanner',
      'true',
      { expires: this.daysInSeconds(this.daysHidden) }
    )
    this.show = false
  }

  open() {
    cookies.set(
      'hasOpenedMobileAppStore',
      'true',
      { expires: this.daysInSeconds(this.daysReminder) }
    )
    this.show = false
  }

  mounted(): void {
    setTimeout(() => { this.show = true }, this.appearDelay)
  }
}
