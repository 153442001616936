
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PlaceModule } from '@/store/place'
import { HandleApiRequest } from '@/utils/helper'

@Component
export default class PeoplePage extends Vue {
  isLoading = true
  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    mousewheel: true
  }

  @Prop({ type: Boolean }) isLayout!: boolean

  get owner() {
    return PlaceModule.people.find(({ placeToCustomerStatusId }) => placeToCustomerStatusId === 1)
  }

  get people() {
    return PlaceModule.people.filter(({ placeToCustomerStatusId }) => placeToCustomerStatusId !== 1)
  }

  @HandleApiRequest({ id: 'GETCurrentplaceCustomers' })
  async mounted() {
    localStorage.setItem('placesActiveMenu', 'PeoplePage')
    await PlaceModule.getPeople()
  }
}
