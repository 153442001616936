import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'

import store from '@/store/index'
import { $get, $put, $post, $remove } from '@/plugins/axios'

import { ICurrentCustomer, ApiUploadLayout, IBuildItem, ICompanyItem, ApiItemsLayout, ApiUploadFile } from '@/model/index'
import { PageModule } from './page'

export interface UserState {
  user: Partial<ICurrentCustomer>
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements UserState {
  user = {} as Partial<ICurrentCustomer>;

  @Mutation
  SET_STATE_USER<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Mutation
  COMBINE_USER(user: Partial<ICurrentCustomer>) {
    Object.assign(this.user, user)
  }

  @Action
  async getBuildings({ searchQuery, fromRow }: Record<'searchQuery' | 'fromRow', string | number>) {
    const { data } = await $get<ApiItemsLayout<IBuildItem>>('/customerregistrationrequest/buildings', { params: { searchQuery, fromRow } })

    return data
  }

  @Action
  async getCompanies(input: string) {
    const { data } = await $get<ApiItemsLayout<ICompanyItem>>('/customerregistrationrequest/companies', { params: { searchQuery: input, fromRow: 0 } })

    return data.items
  }

  @Action
  async createUser(form: any) {
    const { data } = await $post('/customerregistrationrequest', form)

    return data
  }

  @Action
  async getUser() {
    const { data } = await $get<Record<string, keyof ICurrentCustomer | string >>('/current-customer/places', {
      headers: {
        placeid: null,
        buildingid: null
      }
    })
    this.SET_STATE_USER({
      key: 'user',
      value: {
        ...data,
        image: data.customerImage,
        shortName: data.customerShortName
      }
    } as {key: 'user', value: any})

    return data
  }

  @Action
  async getUserProfile() {
    const { data } = await $get<Record<string, keyof ICurrentCustomer | string>>('current-customer')
    this.COMBINE_USER(data)
  }

  @Action
  async renewToken(token: string) {
    const { data } = await $post<{ token: string }>('/auth/renew', { token })

    PageModule.SET_STATE_PAGE({ key: 'isInited', value: true })

    localStorage.removeItem('authorization')
    localStorage.setItem('token', data.token)

    window.location.reload(false)
  }

  @Action
  async updateUser(user: Partial<ICurrentCustomer>) {
    const { data } = await $put<ICurrentCustomer>('/currentcustomer', user)

    this.SET_STATE_USER({ key: 'user', value: data })
  }

  @Action
  async uploadAvatar(file: File) {
    const { items } = await this.uploadFile(file)
    const avatar = items[0]

    const { data } = await $post<Record<string, keyof ICurrentCustomer | string>>('current-customer/image', { image: avatar.name })
    this.COMBINE_USER(data)
  }

  @Action
  async deleteAvatar() {
    const { data } = await $post<Record<string, keyof ICurrentCustomer | string>>('current-customer/image', { image: null })
    this.COMBINE_USER(data)
  }

  @Action
  async logout() {
    await $remove('/auth')
  }

  @Action
  async uploadFile(file: File) {
    const formData = new FormData()
    formData.append('file[]', file)

    const response = await $post<any, ApiUploadLayout>('', formData, { baseURL: process.env.VUE_APP_FILE_UPLOAD || 'https://uploads.domyland.com' })
    return response
  }
}

export const UserModule = getModule(User)
