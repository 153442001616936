
import { Vue, Component, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IAutoPayPlace, IAutoPayPlaceType } from '@/model/page/autopay'

@Component
export default class AutopayBlock extends Vue {
  @Prop({ required: true }) block!: IAutoPayPlace

  isInnerEnable = this.block.enabled

  get isEnable() {
    return this.isInnerEnable
  }

  set isEnable(value: boolean) {
    console.log({ value })
    this.isInnerEnable = value
    this.block.enabled = value
  }

  get getZhkuInput() {
    const input = this.block.types.find(item => item.name === 'zhku')

    if (!input?.enabled) {
      const input = this.block.types.find(item => item.name === 'insurance')
      if (input) {
        input.enabled = false
        // this.handleChangeType()
      }
    }
    return input
  }

  handleChangeType() {
    const isAllDisabled = this.block.types.every(type => !type.enabled)

    if (isAllDisabled) {
      this.isEnable = false
    }

    return isAllDisabled
  }

  isDisableType(type: IAutoPayPlaceType) {
    if (!this.isEnable) return true

    return type.name === 'insurance' ? !this.getZhkuInput?.enabled : false
  }

  updateBlockEnabled(value: boolean) {
    this.isEnable = value
    this.block.types = this.block.types.map(type => Object.assign(type, { enabled: value }))
  }

  mouted() {
    this.handleChangeType()

    if (!this.isEnable) {
      this.updateBlockEnabled(false)
    }
  }
}
