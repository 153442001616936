import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'
import store from '@/store'
import { $get } from '@/plugins/axios'
import {
  ApiPlaceholder
} from '@/model/index'

import {
  ICosts,
  IInvoice,
  getCosts,
  IPayment,
  IInvoices,
  IRequisites,
  getPayments,
  IPaymentDates,
  getRequisites,
  InvocesPageId
} from '@/model/page/invoices'

export interface InvoicesState {
  invoices: IInvoices
  requisites: Array<IRequisites>
  costs: Array<ICosts>
  invoiceDetail: IInvoice | null
  isPaymentsAvailable: boolean
  payments: Array<IPayment>
  paymentsNextRow: number
  paymentsPlaceholder: ApiPlaceholder
  paymentsDates: IPaymentDates
}

type InnerInvoiceParams = { id: number | string, scopeId: string, scopeTypeId: string }

@Module({ dynamic: true, store, name: 'invoices' })
class Invoices extends VuexModule implements InvoicesState {
  invoices = {} as IInvoices
  invoice = {} as InvocesPageId
  requisites = [] as Array<IRequisites>
  costs = [] as Array<ICosts>
  invoiceDetail: IInvoice | null = null
  isPaymentsAvailable: boolean = false
  payments = [] as Array<IPayment>
  paymentsNextRow: number = 0
  paymentsPlaceholder = {} as ApiPlaceholder
  paymentsDates: IPaymentDates = { start: 0, end: 0 }

  @Mutation
  SET_STATE_INVOICES<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Mutation
  PUSH_PAYMENTS(newPayments: Array<IPayment>) {
    this.payments = this.payments.concat(newPayments)
  }

  get isMorePaymentsExist() {
    return this.paymentsNextRow > 0
  }

  @Action
  async getInvoices() {
    const { data } = await $get<IInvoices>('/sections/invoices')

    this.SET_STATE_INVOICES({ key: 'invoices', value: data })
  }

  @Action
  async getInvoicesId({ id, scopeId, scopeTypeId }: InnerInvoiceParams) {
    const { data } = await $get<InvocesPageId>(`/invoices/${id}`, { params: { scopeId, scopeTypeId } })

    this.SET_STATE_INVOICES({ key: 'invoice', value: data })
  }

  @Action
  async getInvoicesCalendarId({ id, scopeId, scopeTypeId }: InnerInvoiceParams) {
    const { data } = await $get<InvocesPageId>(`/invoices/${id}/calendar`, { params: { scopeId, scopeTypeId } })

    this.SET_STATE_INVOICES({ key: 'invoice', value: data })
  }

  @Action
  async getInvoiceDetail({ id, scopeId, scopeTypeId }: InnerInvoiceParams) {
    this.SET_STATE_INVOICES({ key: 'invoiceDetail', value: {} as IInvoice })

    const { data } = await $get<IInvoice>(`/invoices/${id}`, { params: { scopeId, scopeTypeId } })

    this.SET_STATE_INVOICES({ key: 'invoiceDetail', value: data })
  }

  @Action
  async getRequisites({ id, scopeId, scopeTypeId }: InnerInvoiceParams) {
    this.SET_STATE_INVOICES({ key: 'requisites', value: [] })

    const { data } = await $get<getRequisites>(`/invoices/${id}/requisites`, { params: { scopeId, scopeTypeId } })

    this.SET_STATE_INVOICES({ key: 'requisites', value: data?.items })
  }

  @Action
  async getCosts({ id, scopeId, scopeTypeId }: InnerInvoiceParams) {
    this.SET_STATE_INVOICES({ key: 'costs', value: [] })

    const { data } = await $get<getCosts>(`/invoices/${id}/costs`, { params: { scopeId, scopeTypeId } })

    this.SET_STATE_INVOICES({ key: 'costs', value: data?.items })
  }

  @Action
  async getPayments() {
    const { data } = await $get<getPayments>('/payments', {
      params: {
        fromRow: this.paymentsNextRow,
        start: this.paymentsDates.start,
        end: this.paymentsDates.end
      }
    })

    this.SET_STATE_INVOICES({ key: 'isPaymentsAvailable', value: data?.isAvailable })
    this.SET_STATE_INVOICES({ key: 'paymentsPlaceholder', value: data?.placeholder })
    this.PUSH_PAYMENTS(data?.items)
  }
}

export const InvoicesModule = getModule(Invoices)
