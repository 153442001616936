
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IInvoiceCalendar } from '@/model/page/invoices'
import TabAccounts from '@/components/page/invoices/tabs/TabAccounts.vue'

import { InvoicesModule } from '@/store/invoices'

import InvoicesCalendar from '@/components/page/invoices/InvoicesCalendar.vue'
import InvoicesDetail from '@/components/page/invoices/tabs/TabInvoicesDetail.vue'

@Component({
  components: {
    TabAccounts,
    InvoicesDetail,
    InvoicesCalendar
  }
})
export default class InvoiceMobileLayout extends Vue {
  detailLoading = false
  isDetailModalShow = false

  @Prop({ type: String }) type!: string

  get hasCalendar() {
    return this.type !== 'invoice'
  }

  get scopeTypeId() {
    return this.$route.query.scopeTypeId as string || '-1'
  }

  get scopeId() {
    return this.$route.query.scopeId as string || '-1'
  }

  get invoice() {
    return InvoicesModule.invoice
  }

  get invoiceDetail() {
    return InvoicesModule.invoiceDetail
  }

  async openDetailHandler(invoiceId: number) {
    this.isDetailModalShow = true
    this.detailLoading = true
    await InvoicesModule.getInvoiceDetail({ id: invoiceId, scopeTypeId: this.scopeTypeId, scopeId: this.scopeId })
    this.detailLoading = false
  }
}

