import { PageModule } from '@/store/page'
import { AxiosError } from 'axios'

import {
  imageTypes,
  videoTypes,
  IImageObject,
  accessTypes
} from '@/model/index'

const fileType = require('file-type/browser')

export function HandleApiRequest(options: { prop?: string, id?: string } = {}): MethodDecorator {
  let { prop, id } = options

  prop = prop || 'isLoading'

  return function(_target, _propertyKey, descriptor: PropertyDescriptor) {
    const method = descriptor.value as (...args: any[]) => any | Promise<any>

    descriptor.value = async function(...args: any[]) {
      const needRequest = id ? isNeedRequest({ id }) : true
      const vm = this as any
      const setProp = (value: boolean) => {
        if (Object.prototype.hasOwnProperty.call(vm, prop || '')) {
          vm.$set(this, prop, value)
        }
      }

      setProp(true)

      try {
        if (!needRequest) return

        return await method.apply(this, args)
      } catch (error) {
        HandleError(error)
      } finally {
        setProp(false)
      }
    }
  }
}

export function Catch(): MethodDecorator {
  return function(_target, _propertyKey, descriptor: PropertyDescriptor) {
    const method = descriptor.value as (...args: any[]) => any | Promise<any>

    descriptor.value = async function(...args: any[]) {
      try {
        await method.apply(this, args)
      } catch (error) {
        HandleError(error)
      }
    }
  }
}

export function HandleError(error: Error | AxiosError) {
  if ((error as AxiosError).isAxiosError) {
    PageModule.SEND_NOTIFICATION({ type: 'error', message: (error as AxiosError)?.response?.data?.userMessages[0] || 'Ошибка в запросе' })
  } else {
    PageModule.SEND_NOTIFICATION({ type: 'error', message: error.message })
  }
}

export interface IsNeedRequestOptions {
  id: string
}

export function isNeedRequest({ id }: IsNeedRequestOptions, callback?: Function) {
  const settings = JSON.parse(localStorage.getItem('apiRequests') || '{}')
  const apiOffset = 1000 * 60 * 5
  const currentTime = new Date().getTime()

  if (!settings[id] || currentTime > settings[id].timestamp) {
    if (callback) {
      callback()
    }

    settings[id] = {
      timestamp: currentTime + apiOffset
    }

    localStorage.setItem('apiRequests', JSON.stringify(settings))
    return true
  }
  return false
}

export async function parseFiles(files: FileList) {
  const innerFiles = Array.from(files as ArrayLike<File>).filter(file => accessTypes.includes(file.type))

  return await Promise.all<IImageObject>(
    innerFiles
      .map((file: File, index): Promise<IImageObject> => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async(resolve) => {
          let { type, name } = file

          /**
           * Если браузер не определил тип файла,
           * А определяет он его по расширению файла
           * То использовать библиотеку для проверки типов
           */
          if (!type) {
            const ext = await fileType.fromBlob(file)

            if (ext) {
              type = ext.mime
            }
          }

          const reader = new FileReader()
          const defaultFile = {
            type,
            name,
            loading: false,
            file: files[index],
            timeOfRead: new Date().getTime()
          }

          if (imageTypes.includes(type)) {
            reader.readAsDataURL(file)
            reader.onload = (e) => {
              return resolve({
                ...defaultFile,
                url: reader.result as string || ''
              })
            }
          } else if (videoTypes.includes(type)) {
            return resolve({
              ...defaultFile,
              url: URL.createObjectURL(file)
            })
          } else {
            reader.readAsArrayBuffer(file)
            reader.onload = (e) => {
              return resolve({
                ...defaultFile,
                buffer: reader.result as ArrayBuffer || []
              })
            }
          }
        })
      })
  )
}

/** функция для склонения существительных */
const countables: Record<string, string[]> = {
  партнер: ['партнер', 'партнера', 'партнеров'],
  предложение: ['предложение', 'предложения', 'предложений']
}

export const countable = (type: string, count: string | number) => {
  const cases = [2, 0, 1, 1, 1, 2]
  const i = parseInt(count as string)
  const titles = countables[type]
  return titles[(i % 100 > 4 && i % 100 < 20) ? 2 : cases[(i % 10 < 5) ? i % 10 : 5]]
}
