
import { Component, Vue } from 'vue-property-decorator'
import { InfoModule } from '@/store/info'

import InfoContacts from '@/components/page/info/InfoContacts.vue'
import InfoDocuments from '@/components/page/info/InfoDocuments.vue'

import { HandleApiRequest } from '@/utils/helper'

@Component({
  components: {
    InfoContacts,
    InfoDocuments
  }
})
export default class OfficesPage extends Vue {
  isLoading = false

  get building() {
    return InfoModule.building
  }

  @HandleApiRequest()
  async mounted() {
    await InfoModule.getBuilding()
  }
}
