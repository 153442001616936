
/* eslint-disable no-unused-vars */
import { Component, Vue, Watch } from 'vue-property-decorator'

import MessageBlock from '@/components/page/chat/MessageBlock.vue'
import AudioController from '@/components/page/chat/AudioController.vue'
import RecordController from '@/components/page/chat/RecordController.vue'

import { PageModule } from '@/store/page'
import { ChatModule } from '@/store/chat'
import { UserModule } from '@/store/user'

import dayjs from 'dayjs'

import { IMessage, IChat } from '@/model/page/chat'

import {
  imageTypes,
  videoTypes,
  audioTypes,
  accessTypes,
  EventLayout,
  IImageObject,
  ApiUploadFile,
  IDocumentChat,
  IntersectionObserverEntry
} from '@/model/index'

import { HandleApiRequest, parseFiles } from '@/utils/helper'

const fileType = require('file-type/browser')

type messagesGroup = Array<{ date: string, items: IMessage[][], isActive: boolean}>

let beforeEditText = ''
const fileRecordName = 'audiorecord.mp3'

// Couter for inner child on drag events
let dropAmout = 0
let timeToUnfocusDrop: any = null

@Component({
  components: {
    MessageBlock,
    AudioController,
    RecordController
  }
})
export default class ChatPage extends Vue {
  message = ''
  filesToUpload: Array<IImageObject | IDocumentChat> = []
  isInited = false
  MAX_MESSAGE_SYMBOLS_COUNT = 600

  isLoading = !Object.keys(this.chat).length
  isLoadingMessage = false
  isLoadingMessages = false
  isScrollAnimationEnded = false
  isNeedScrollToBottom = true

  imageTypes = imageTypes
  videoTypes = videoTypes
  audioTypes = audioTypes

  isDropFocus = false
  hideDropWrapper = true

  isRecordView = false
  recordVolume = 1
  recordTime = 0

  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 15,
    mousewheel: true
  }

  imageModal = {
    view: false,
    src: ''
  }

  editMessage: IMessage | null = null

  get isDark() { return PageModule.isDark }

  get audioRecordFile() {
    return this.filesToUpload.find(({ name }) => name === fileRecordName)
  }

  get showRecordButton() {
    return !this.message && !this.filesToUpload.length && !this.editMessage
  }

  get isMoreExist() {
    return this.nextRow > 0
  }

  get chat() {
    return ChatModule.chat
  }

  get messagesGroupPeople(): messagesGroup {
    if (!this.chat.items) return []

    return this.chat.items.reduce((final: messagesGroup, message: IMessage) => {
      const format = (date: number) => dayjs(date * 1000).format('YYYY-MM-DD')
      const existDateIndex = final.findIndex((item: any) => item.date === format(message.createdAt))
      const lastDateItems = final[final.length - 1]?.items || []
      const peopleGroup = lastDateItems[lastDateItems.length - 1] || []

      if (existDateIndex === -1) {
        final.push({
          isActive: true,
          date: format(message.createdAt),
          items: [[message]]
        })
      } else {
        if (peopleGroup[0].createdByUserId !== message.createdByUserId) {
          final[final.length - 1].items.push([message])
        } else {
          peopleGroup.push(message)
        }
      }

      return final
    }, [])
  }

  get nextRow() {
    return this.chat.nextRow
  }

  get isUnfocusWrapper() {
    return !this.isDropFocus && this.hideDropWrapper
  }

  get formattedType() {
    return (type: string) => {
      return type.replace(/^[\w\W]{1,}\/([a-z]{1,})([\w\S]{0,})/, '$1')
    }
  }

  get isShowLoadMoreBlock() {
    return this.isInited && this.isMoreExist && this.isScrollAnimationEnded && !this.isLoadingMessages
  }

  @Watch('chat.items')
  __handleMessagesAmountUpdate(value: undefined | IMessage[], oldValue: undefined | IMessage[]) {
    const oldMessagesAmount = oldValue ? oldValue.length : 0
    const messagesAmount = value ? value.length : 0

    if (this.isNeedScrollToBottom) {
      this.scrollToBottom()
    }
  }

  @HandleApiRequest()
  async mounted() {
    PageModule.SET_STATE_PAGE({
      key: 'routeReturnFunciton',
      value: (url: string) => {
        const query = this.$route.fullPath.split('?')[1]

        if (query) {
          url = url + '?' + query
        }
        this.$router.push(url)
      }
    })

    this.isLoadingMessages = true

    const orderId = this.$route.params.id
    const scopeTypeId = this.$route.query.scopeTypeId as string

    await ChatModule.getChat({ orderId, scopeTypeId, fromRow: 0 })
    await ChatModule.viewChat({ orderId, scopeTypeId })

    document.documentElement.addEventListener('keydown', this.handleKeydown)
    window.addEventListener('resize', this.handleResize)

    this.isLoadingMessages = false

    setTimeout(() => {
      this.scrollToBottom(0)

      const dropArea = this.$refs.chat as HTMLElement

      if (dropArea) {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
          dropArea.addEventListener(eventName, this.preventDefaults)
        })

        dropArea.addEventListener('dragenter', this.handlerDragEnter)
        dropArea.addEventListener('dragleave', this.handlerDragLeave)
        dropArea.addEventListener('drop', this.handlerDrop)
      }

      this.adaptiveChatHeight()
      this.$nextTick().then(() => { this.isInited = true })
    }, 0)
  }

  beforeDestroy() {
    document.documentElement.removeEventListener('keydown', this.handleKeydown)
    window.removeEventListener('resize', this.handleResize)

    const dropArea = this.$refs.chat as HTMLElement

    if (dropArea) {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.removeEventListener(eventName, this.preventDefaults)
      })

      dropArea.removeEventListener('dragenter', this.handlerDragEnter)
      dropArea.removeEventListener('dragleave', this.handlerDragLeave)
      dropArea.removeEventListener('drop', this.handlerDrop)
    }

    ChatModule.SET_STATE_CHAT({ key: 'chat', value: {} as IChat })
  }

  preventDefaults(event: any) {
    event.preventDefault()
    event.stopPropagation()
  }

  handleResize() {
    this.adaptiveChatHeight()
  }

  adaptiveChatHeight() {
    const height = window.innerHeight
    const chat = this.$refs.chat as HTMLElement

    if (chat) {
      const offsetHeight = this.$vuetify.breakpoint.mdAndUp ? 220 : 170
      chat.style.height = (height - offsetHeight) + 'px'
    }
  }

  handlerDragEnter(event: DragEvent) {
    const info = event.dataTransfer?.items

    if (info) {
      const hasFiles = Array.from(info).some(item => item.kind === 'file')
      const hasAccessFile = Array.from(info).some(item => accessTypes.includes(item.type))

      if (!hasFiles || !hasAccessFile) {
        return
      }
    }

    this.isDropFocus = true

    this.hideDropWrapper = false
    // Удалить возможные изменения в timeToUnfocusDrop интервале
    clearTimeout(timeToUnfocusDrop)

    this.$nextTick(() => {
      dropAmout++

      this.isDropFocus = true
    })
  }

  handlerDragLeave() {
    dropAmout--

    if (dropAmout === 0) {
      this.isDropFocus = false

      timeToUnfocusDrop = setTimeout(() => {
        this.hideDropWrapper = true
      }, 1000)
    }
  }

  async handlerDrop(event: DragEvent) {
    this.isDropFocus = false
    dropAmout = 0

    timeToUnfocusDrop = setTimeout(() => {
      this.hideDropWrapper = true
    }, 1000)

    const { dataTransfer } = event
    const dropFiles = dataTransfer?.files

    if (dropFiles) {
      if (this.isRecordView) {
        this.cancelRecord()
      }

      const files = await parseFiles(dropFiles)

      this.filesToUpload = this.filesToUpload.concat(files)
    }
  }

  formatDate(date: string) {
    return dayjs(date, 'YYYY-MM-DD').format('MMM DD')
  }

  openModalImage(image: string) {
    this.imageModal.src = image
    this.imageModal.view = true
  }

  scrollToBottom(time = 500) {
    const element = this.$refs.messages as Element

    if (element) {
      const scrollTop = element.scrollTop

      const circ = (timeFraction: number) => {
        return 1 - Math.sin(Math.acos(timeFraction))
      }

      this.$animate({
        duration: time,
        timing: circ,
        draw: function(progress) {
          element.scrollTop = (element.scrollHeight - element.clientHeight) * progress + scrollTop
        }
      })
      setTimeout(() => { this.isScrollAnimationEnded = true }, time)
    }
  }

  handleKeydown(event: KeyboardEvent) {
    const isFocus = document.querySelector('#chat-input:focus')
    const isEnter = event.which === 13 || event.key === 'Enter'
    const isEscape = event.which === 27 || event.key === 'Escape'
    const textarea = this.$refs.textarea as HTMLElement

    if (isEnter && !isFocus) {
      textarea && textarea.focus()
      event.preventDefault()
    } else if (isEscape) {
      textarea && textarea.blur()

      if (this.imageModal.view) {
        this.imageModal.view = false
      } else {
        this.cancelRecord()
      }

      if (this.editMessage) {
        this.editMessage = null
        this.message = beforeEditText
      }
    }
  }

  async handleInputKeydown(event: KeyboardEvent) {
    if (this.isLoadingMessage) {
      return
    }

    try {
      const isEnter = event.which === 13 || event.key === 'Enter'

      if (event.ctrlKey && isEnter) {
        event.stopPropagation()
        event.preventDefault()

        await this.sendMessage()
      }
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async loadMessages(entries: Array<IntersectionObserverEntry>) {
    if (entries[0].isIntersecting && this.isShowLoadMoreBlock) {
      const wrapper = this.$refs.messages as Element
      this.isLoadingMessages = true
      this.isNeedScrollToBottom = false

      const orderId = this.$route.params.id
      const scopeTypeId = this.$route.query.scopeTypeId as string

      const lastMessage = (this.$refs[`message-${this.chat.items[0].id}`] as any)?.[0]?.$el

      await ChatModule.getChat({ orderId, scopeTypeId, fromRow: this.nextRow })
      await this.$nextTick()

      wrapper.scrollTop = lastMessage.offsetTop - 60

      this.isLoadingMessages = false
      this.isNeedScrollToBottom = true
    }
  }

  async sendMessage() {
    try {
      this.isLoadingMessage = true

      if (this.message.length || this.filesToUpload[0]) {
        const orderId = this.$route.params.id
        const scopeTypeId = this.$route.query.scopeTypeId as string

        let fileName = ''

        if (this.filesToUpload[0]) {
          const { file } = this.filesToUpload[0]
          this.filesToUpload[0].loading = true
          try {
            const info = await UserModule.uploadFile(file)
            if (!info?.items?.[0]?.success) {
              this.filesToUpload.shift()
              this.$handleApiError({ message: info?.items?.[0]?.message } as Error, this)
              return
            }
            fileName = info.items[0].name || info.items[0].originalName
          } catch (error) {
            if (error.message === 'ignoreError') {
              this.filesToUpload.shift()
              this.$handleApiError({ message: 'Не удалось загрузить файл. Превышен лимит ожидания запроса' } as Error, this)
              return
            }
          }
        }

        const message = {
          fileName,
          text: this.message || ''
        }

        if (this.editMessage) {
          message.fileName = this.editMessage.fileName || ''
          message.text = this.message || ''

          await ChatModule.editMessage({ scopeTypeId, message, orderId, messageId: this.editMessage.id })

          this.editMessage = null
        } else {
          await ChatModule.sendMessage({ scopeTypeId, message, orderId })
          if (this.filesToUpload[0]) {
            this.filesToUpload.shift()
            if (this.filesToUpload[0]) {
              this.message = ''
              this.filesToUpload[0].loading = true
              setTimeout(() => {
                this.sendMessage()
              }, 1000)
            }
          }
        }

        this.message = ''
        this.scrollToBottom()
      }
    } catch (error) {
      this.$handleApiError(error, this)
      this.filesToUpload = this.filesToUpload.map((file) => {
        file.loading = false
        return file
      })
    } finally {
      this.isLoadingMessage = false
      this.cancelRecord()
    }
  }

  cancelRecord() {
    if (this.$refs.recordController) {
      (this.$refs.recordController as any).cancelRecord()
    }
    this.filesToUpload = this.filesToUpload.filter(({ name }) => name !== fileRecordName)
    this.isRecordView = false
  }

  async uploadFiles(event: EventLayout<HTMLInputElement>) {
    try {
      this.isLoadingMessage = true

      if (event.target.files) {
        const files = await parseFiles(event.target.files)

        this.filesToUpload = this.filesToUpload.concat(files)
      }

      event.target.value = ''
    } catch (error) {
      this.$handleApiError(error)
    } finally {
      this.isLoadingMessage = false
    }
  }

  handleUpdateRecord({ volume, time }: { volume: number, time: number }) {
    this.recordVolume = volume
    this.recordTime = time
  }

  async addAudioFile(blob: Blob) {
    const name = fileRecordName
    const buffer = await new Response(blob).arrayBuffer() || []

    this.filesToUpload.push({
      buffer,
      timeOfRead: new Date().getTime(),
      file: new File([blob], name),
      type: blob.type,
      name,
      loading: false
    })
  }

  handleEditMessage(message: IMessage) {
    beforeEditText = this.message
    this.message = message?.text || ''
    this.editMessage = message;

    (this.$refs.textarea as HTMLElement).focus()
  }

  handleDeleteMessage(message: IMessage) {
    if (this.editMessage?.id === message?.id) {
      this.editMessage = null
    }
  }
}
