
import { Vue, Component } from 'vue-property-decorator'
import { PageModule } from '@/store/page'
import { HandleApiRequest } from '@/utils/helper'

import { NewsFeedModule } from '@/store/newsFeed'

// eslint-disable-next-line no-unused-vars
import { ComputedServiceForm } from '@/model/page/service'

import FormParser from '@/components/general/FormParser.vue'

@Component({
  components: {
    FormParser
  }
})
export default class NewsIdPage extends Vue {
  isLoading: boolean = false

  get poll() {
    return NewsFeedModule.pollsId
  }

  @HandleApiRequest()
  async mounted() {
    PageModule.SET_STATE_PAGE({
      key: 'routeReturnFunciton',
      value: (url: string) => {
        this.$router.push({ name: 'newsFeed' })
      }
    })

    const id = parseInt(this.$route.params.id)
    await this.getPollsDetail(id)
    if (!this.poll.isViewed) {
      await NewsFeedModule.viewPoll(id)
    }
  }

  async getPollsDetail(id: number) {
    this.isLoading = true
    await NewsFeedModule.getPollsDetail(id)
    this.isLoading = false
  }

  async handleForm(formData: ComputedServiceForm) {
    try {
      const data = await NewsFeedModule.submitPoll({ id: this.poll.id, formData })
      if (data && data.success) {
        this.$router.push('/newsfeed/')
      }
    } catch (error) {
      this.$handleApiError(error)
    }
  }
}

