/* eslint-disable camelcase */
import { ComputedServiceForm } from '@/model/page/service'

export const AdvertMenu = [
  {
    id: 0,
    icon: 'menu-adverts',
    name: 'adverts',
    title: 'Объявления',
    badge: 0,
    isAvailable: true,
    to: {
      name: 'adboard'
    }
  },
  {
    id: 1,
    icon: 'menu-my-adverts',
    name: 'adverts-my',
    title: 'Мои объявления',
    badge: 0,
    isAvailable: true,
    to: {
      name: 'advertsMy'
    }
  },
  {
    id: 2,
    icon: 'mdi-plus-circle',
    name: 'adverts-my',
    title: 'Создать',
    badge: 0,
    isAvailable: true,
    to: {
      name: 'advertsAdd'
    }
  }
]

export const MENU_ICONS: Record<string, string> = {
  services: 'menu-services',
  invoices: 'menu-invoices',
  places: 'menu-places',
  newsFeed: 'menu-news',
  info: 'menu-info'
}

export interface IDialogs {
  type: 'image' | 'text' | 'buttons'
  title: string
  text: string
  buttons?: Array<IDialogButton>
}

export interface IDialogButton {
  text: string
  callback: Function
}

export type TAlertType = 'success' | 'error' | 'warning' | 'info';

export interface IAlert {
  type: TAlertType;
  message: string;
  show: boolean
}

export interface IDocument {
  date?: number
  description: string
  fileName: string
  fileOriginalName: string
  fileURL: string
  ordering: number
  title: string
}

export interface ApiItemsPlaceholderLayout<T = IDocument> {
  isAvailable: boolean
  items: Array<T>
  placeholder: ApiPlaceholder
}

export interface IItemsLayout<T> {
  items: Array<T>
}

export interface ApiUploadLayout {
  status: string
  items: Array<ApiUploadFile>
  statusCode: number
}

export interface ApiUploadFile {
  fileTypeId: number
  hash: string
  message: string
  mime: string
  name?: string
  originalName: string
  size: number
  success: boolean
  url: string
  uuid:string
}

export interface IBuildItem {
  id: number
  title: string
  address: string
  companyId: number
}

export interface ICompanyItem {
  id: number
  title: string
  legalName: string
}

export interface PostAuthCode {
  message: string
}

export interface GetAgreement {
  content: string
}

export interface ISection {
  id: number
  name: string
  title: string
  badge: number
  isAvailable: boolean
}

export interface getSections {
  items: ISection[]
}

export interface getAppLink {
  author: string;
  title: string;
  icon?: string;
  downloadUrls: {
    ios: string
    android: string
  }
}

export interface IMenuItem extends ISection {
  to: { name: string }
  icon: string
}

export interface IPlace {
  address: string
  buildingId: number
  buildingImage: string
  buildingTitle: string
  companyId: number
  companyLogo: string
  companyThemeColor: string
  companyThemeColorHex: string
  companyTitle: string
  companyTypeIcon: string
  companyTypeId: number
  companyWebSite: string
  debtTitle: string | null
  id: number
  isInConstruction: boolean
  placeToCustomerStatusId: number
  placeToCustomerStatusTitle: string
  placeTypeId: number
  placeTypeImage: string
  placeTypeTitle: string
  tenantId: number
  // eslint-disable-next-line camelcase
  tenant_placeId: number
  totalSum: string
}
export interface ICurrentCustomer {
  actions: ICurrentCustomerActions
  birthDate: string
  createdAt: number
  createdByCustomerId: number
  createdByUserId: number
  customerTypeId: number
  email: string
  firstActivity: null
  firstName: string
  fullName: string
  id: number
  isOwner?: boolean | null | undefined
  image: string | null
  isDefaultImage: boolean
  isDemo: boolean
  isRegistered: boolean
  isSignupRequired: boolean
  lastActivity: null
  lastName: string
  middleName: string
  notOwnerRegistrationInfo?: {
    buttons: INotOwnerRegistrationButton[]
    description: string
    title: string
  }
  phoneNumber: string
  places: IPlace[]
  registrationStatus: string
  shortName: string
  updatedAt: number
  updatedByCustomerId: number
  updatedByUserId: number
  isRegistrationAllowed: boolean
  registrationStatusTitle: string
  registrationStatusDescription: string
  isPaymentAutomatically: boolean
  hasPassword?: boolean;
  registrations: {id: number; statusId: number; statusTitle: string; statusDescription: string}[]
}

export interface ICurrentCustomerActions {
  agreementAccept: ICurrentCustomerActionItem
  applicationRate: ICurrentCustomerActionItem
  applicationUpdate: ICurrentCustomerActionItem
}

export interface ICurrentCustomerActionItem {
  required: boolean
  message: string
}

export interface ApiPlaceholder {
  description: string
  emoji: string
  title: string
}

export interface ApiItemsLayout<T> {
  isAvailable: boolean
  items: Array<T>
  nextRow: number
  placeholder: ApiPlaceholder
  totals?: Record<string, number>;
}

export interface EventLayout<T = any> {
  target: T
}

export interface SubmitFormOptions {
  id: number | string
  formData: ComputedServiceForm
  force?: number
}

export interface IntersectionObserverEntry {
  boundingClientRect: DOMRectReadOnly
  intersectionRatio: number
  intersectionRect: DOMRectReadOnly
  isIntersecting: boolean
  isVisible: boolean
  rootBounds: DOMRectReadOnly
  target: Element
  time: number
}

export enum LoginStatus {
  USER_EXIST = 1,
  USER_DONT_EXIST = 2
}

export interface IFile {
  id: number
  name: string
  originalName: string
  url: string
}

export interface IDocumentChat {
  timeOfRead: number
  name: string
  type: string
  file: File
  loading: Boolean
  buffer?: ArrayBuffer
}

export interface IImageObject extends IDocumentChat {
  url?: string
}

export const imageTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif']
export const videoTypes = ['video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/quicktime']
export const audioTypes = ['audio/ogg', 'audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/mpeg']
export const documentTypes = [
  '',
  'text/xml',
  'text/rtf',
  'text/plain',
  'application/zip',
  'application/pdf',
  'application/msword',
  // 'application/vnd.rar',
  'application/vnd.ms-excel',
  'application/vnd.ms-office',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.documentapplication/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

export const ignoreTypes = ['image/svg+xml', 'audio/ogg']

export const accessTypes = imageTypes.concat(documentTypes, videoTypes, audioTypes).filter(type => {
  return !ignoreTypes.includes(type)
})

export interface PostCustomerRegistrationRequest {
  firstName: string
  lastname: string
  middleName?: string
  address?: string
  buildingId?: number
  companyId: number
  placeNumber: string
  accountNumber: string
}

export interface DadataDTO {
  suggestions: DadataSuggestion[]
}

export interface DadataSuggestion {
  data: DadataSuggestionData
  unrestricted_value: string
  value: string
}

export interface DadataSuggestionData {
  capital_marker: string
  city: string
  city_fias_id: string
  city_kladr_id: string
  city_type: string
  city_type_full: string
  city_with_type: string
  country: string
  country_iso_code: string
  fias_actuality_state: string
  fias_code: string
  fias_id: string
  fias_level: string
  geo_lat: string
  geo_lon: string
  geoname_id: string
  kladr_id: string
  okato: string
  oktmo: string
  qc_geo: string
  region: string
  region_fias_id: string
  region_iso_code: string
  region_kladr_id: string
  region_type: string
  region_type_full: string
  region_with_type: string
  tax_office: string
  tax_office_legal: string
  area?: string
  area_fias_id?: string
  area_kladr_id?: string
  area_type?: string
  area_type_full?: string
  area_with_type?: string
  beltway_distance?: string
  beltway_hit?: string
  block?: string
  block_type?: string
  block_type_full?: string
  city_area?: string
  city_district?: string
  city_district_fias_id?: string
  city_district_kladr_id?: string
  city_district_type?: string
  city_district_type_full?: string
  city_district_with_type?: string
  federal_district?: string
  flat?: string
  flat_area?: string
  flat_price?: string
  flat_type?: string
  flat_type_full?: string
  history_values?: string
  house?: string
  house_fias_id?: string
  house_kladr_id?: string
  house_type?: string
  house_type_full?: string
  metro?: string
  postal_box?: string
  postal_code?: string
  qc?: string
  qc_complete?: string
  qc_house?: string
  settlement?: string
  settlement_fias_id?: string
  settlement_kladr_id?: string
  settlement_type?: string
  settlement_type_full?: string
  settlement_with_type?: string
  source?: string
  square_meter_price?: string
  street?: string
  street_fias_id?: string
  street_kladr_id?: string
  street_type?: string
  street_type_full?: string
  street_with_type?: string
  timezone?: string
  unparsed_parts?: string
}

export type NotificationSubscribeType = 'news' | 'poll' | 'bill' | 'chat' | 'event' | 'metric' | 'customerNotification' | 'orders' | 'pass' | 'permanentpass'

export interface NotificationSubscribeItems {
  name: NotificationSubscribeType
  title: String
  enabled: boolean
}

export interface NotificationSubscribe {
  subscribe?: Record<NotificationSubscribeType, boolean>
  token: string
}

export interface GetNotificationSubscribe {
  subscribe: NotificationSubscribeItems[]
  token: string
}

export interface IButtonPay {
  buttons: IButtonPayButton[]
  company: IButtonPayCompany[]
  enabled: boolean
  filters: IButtonPayFilter[]
  paymentContext?: string
  paymentLink: string
  totalSum: number
}

export interface IButtonPayButton {
  name: 'pay'
  title: string
}

export interface IButtonPayCompany {
  id: number
  active: boolean
  title: string
}

export interface IButtonPayFilter {
  active: boolean
  count: number
  icon: string
  id: number
  title: string
  totalSum: number
}

export interface INotOwnerRegistrationButton {
  active: boolean,
  icon: string | null,
  name: string,
  title: string
}
