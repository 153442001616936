
import { Vue, Component } from 'vue-property-decorator'

import { PageModule } from '@/store/page'
// eslint-disable-next-line no-unused-vars
import { getAppLink } from '@/model'

@Component
export default class AppFooter extends Vue {
  get isDark() { return PageModule.isDark }

  get links(): {href: string; text: string; icon:string}[] {
    const applications: getAppLink = PageModule.applications
    return [{
      href: applications.downloadUrls?.ios,
      text: 'App Store',
      icon: 'apple'
    },
    {
      href: applications.downloadUrls?.android,
      text: 'Google Play',
      icon: 'google-play'
    }
    ]
  }
}
