
import { Vue, Component } from 'vue-property-decorator'
import { PageModule } from '@/store/page'
import { NewsFeedModule } from '@/store/newsFeed'

// eslint-disable-next-line no-unused-vars
import { ComputedServiceForm } from '@/model/page/service'

// eslint-disable-next-line no-unused-vars
import { IVote } from '@/model/page/newsFeed'

import FormParser from '@/components/general/FormParser.vue'
import { HandleApiRequest } from '@/utils/helper'

@Component({
  components: {
    FormParser
  }
})
export default class VotesIdPage extends Vue {
  isLoading: boolean = false
  vote = {} as IVote

  get disabled() {
    return this.vote.isClosed || this.vote.isFinished
  }

  @HandleApiRequest()
  async created() {
    PageModule.SET_STATE_PAGE({
      key: 'routeReturnFunciton',
      value: (url: string) => {
        this.$router.push({ name: 'newsFeed' })
      }
    })

    const { id } = this.$route.params

    if (this.$route.name === 'generalMeetingId') {
      this.vote = await NewsFeedModule.getMeeting(id)
      await NewsFeedModule.viewMeeting(id)
    } else {
      this.vote = await NewsFeedModule.getVote(id)
      await NewsFeedModule.viewVote(id)
    }
  }

  async handleForm(formData: ComputedServiceForm) {
    const { type } = this.$route.params
    if (type === 'generalMeeting') {
      try {
        await NewsFeedModule.submitMeeting({ id: this.vote.id, formData })

        this.$router.push('/newsfeed/')
      } catch (error) {
        this.$handleApiError(error)
      }
    } else {
      try {
        await NewsFeedModule.submitVote({ id: this.vote.id, formData })

        this.$router.push('/newsfeed/')
      } catch (error) {
        this.$handleApiError(error)
      }
    }
  }
}

