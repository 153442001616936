
import { Component, Vue } from 'vue-property-decorator'
import PaymentInfo from '@/components/payment/PaymentInfo.vue'
import { $post } from '@/plugins/axios'
import { PayForm, PayFormMethod, CalculateData } from '@/model/page/pay'

@Component({ components: { PaymentInfo } })
export default class PaymentPage extends Vue {
  // data

  paymentData: PayForm | null = null;
  // PaymentContext если переход на страницу оплаты был не через смс
  splitUrl = window.location.href.split('c=')
  // PaymentContext если переход на страницу оплаты был через смс
  splitMobileUrl = window.location.href.split('pay/')
  // Состояние загрузки формы оплаты
  loading = false

  noDataText = ''
  errorText = ''

  // getters

  // Проверка авторизован ли пользователь
  get isAuthorized(): string | null {
    return localStorage.getItem('token')
  }

  // PaymentContext для оплаты
  get paymentCode(): { paymentContext: string } | { paymentCode: string } | {} {
    if (this.splitUrl[1]) return { paymentContext: decodeURIComponent(this.splitUrl[1]) }
    if (this.splitMobileUrl[1]) return { paymentCode: this.splitMobileUrl[1] }
    return {}
  }

  get isA101(): boolean {
    const hosts = ['app.a101.ru', 'lk-a101.domyland.dev']
    return hosts.includes(window.location.host)
  }

  get isSmlt(): boolean {
    const hosts = ['lk-uk.samolet.ru', 'lk-samolet.domyland.dev']
    return hosts.includes(window.location.host)
  }

  get isBrusnika(): boolean {
    const hosts = ['lk.brusnika.ru']
    return hosts.includes(window.location.host)
  }

  get color():string {
    if (this.isA101) return 'a101'
    else if (this.isSmlt) return 'smlt'
    else if (this.isBrusnika) return 'brusnika'
    return 'primary'
  }

  // actions

  // Пересчитать итоговую суммы оплаты
  async calculate(calculateData: CalculateData): Promise<void> {
    this.loading = true
    try {
      const data = await $post('pay/calculate', calculateData)
      this.paymentData = data.data
    } catch (e) {
      this.errorText = e.response.data.userMessages[0]
      // eslint-disable-next-line no-return-assign
      setTimeout(() => this.errorText = '', 5000)
    } finally {
      this.loading = false
    }
  }

  // Удалить существующую карту оплаты
  async deleteCard(item: PayFormMethod): Promise<void> {
    this.loading = true
    try {
      if (this.paymentData && this.paymentData.paymentContext) {
        const data = await $post('pay/card-delete', { paymentContext: this.paymentData.paymentContext, scopeId: item.scopeId })
        this.paymentData = data.data
      }
    } catch (e) {
      this.errorText = e.response.data.userMessages[0]
      // eslint-disable-next-line no-return-assign
      setTimeout(() => this.errorText = '', 5000)
    } finally {
      this.loading = false
    }
  }

  onBackButtonClick(): void {
    const backRef = new URLSearchParams(window.location.search).get('miniAppBackRef')

    if (this.isAuthorized && !backRef) this.$router.push('/invoices')
    else if (backRef) window.location.href = backRef
  }

  // hooks
  async mounted(): Promise<void> {
    this.loading = true
    try {
      const data = await $post('pay/form', this.paymentCode)
      this.paymentData = data.data
    } catch (e) {
      this.noDataText = e.response.data.userMessages[0]
    } finally {
      this.loading = false
    }
  }
}
