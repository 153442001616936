
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PageModule } from '@/store/page'

@Component
export default class AppPageHeader extends Vue {
  @Prop({ type: String, default: 'page-header--dual' }) className!: string
  @Prop({ type: String }) image!: string
  @Prop({ type: String }) title!: string
  @Prop({ type: String }) statusTitle!: string
  @Prop({ type: [String, Number] }) statusColor!: string
  @Prop({ type: String }) logo!: string
  @Prop({ type: Boolean }) logoActive!: string
  @Prop({ type: String }) logoLink!: string

  get selectedPlace() {
    return PageModule.selectedPlace
  }
}
