
import { Component, Vue, Watch } from 'vue-property-decorator'
// @ts-ignore
import socket from '@/plugins/socket'
import firebase, { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'

import { PageModule } from '@/store/page'
import AppBar from '@/components/general/AppBar.vue'
import AppReload from '@/components/general/AppReload.vue'
import AppDrawer from '@/components/general/AppDrawer.vue'
import AppFooter from '@/components/general/AppFooter.vue'
import AppSnackbar from '@/components/general/AppSnackbar.vue'
import AppPhoneDrawer from '@/components/general/AppPhoneDrawer.vue'
import AppPlaceLayout from '@/components/general/AppPlaceLayout.vue'
import NotFoundPage from '@/views/404.vue'
import ServerErrorPage from '@/views/error.vue'
import OfflineErrorPage from '@/views/offlineError.vue'

// eslint-disable-next-line no-unused-vars
import { NotificationSubscribeItems } from '@/model'

@Component({
  components: {
    AppBar,
    AppDrawer,
    AppFooter,
    AppReload,
    AppSnackbar,
    AppPlaceLayout,
    AppPhoneDrawer,
    NotFoundPage,
    ServerErrorPage,
    OfflineErrorPage
  },
  mixins: [socket]
})
export default class LayoutDefault extends Vue {
  expandOnHover = false
  onLine = navigator.onLine

  @Watch('$route')
  middleware() {
    const { buildingId, placeId } = this.$route.query
    if (buildingId && placeId) {
      const place = this.places.find(item => item.buildingId === Number(buildingId) && item.id === Number(placeId))

      if (place) {
        PageModule.SET_SELECTED_PLACE(place)
        PageModule.getSections()
        PageModule.getAppLinks()
      }

      const query = Object.assign({}, this.$route.query)
      delete query.buildingId
      delete query.placeId

      this.$router.replace({ query })
    }

    setTimeout(() => {
      const element = document.documentElement
      const scrollTop = element.scrollTop

      const circ = (timeFraction: number) => {
        return 1 - Math.sin(Math.acos(timeFraction))
      }

      this.$animate({
        duration: 200,
        timing: circ,
        draw: (progress) => {
          element.scrollTop = scrollTop - (scrollTop * progress)
        }
      })
    }, 400)
  }

  get isDark() { return PageModule.isDark }

  get pageCode() {
    return PageModule.pageCode
  }

  get places() {
    return PageModule.places
  }

  get placeId() {
    return PageModule.placeId
  }

  get buildingId() {
    return PageModule.buildingId
  }

  get viewPageChoice() {
    return PageModule.viewPageChoice
  }

  get primaryColor() {
    return PageModule.primaryColor
  }

  get selectedPlace() {
    return PageModule.selectedPlace
  }

  async mounted() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)

    this.initColor()
    this.initFirebase()
  }

  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus);
    (this as any).removeSocketListeners()
  }

  updateOnlineStatus(event: any) {
    this.onLine = event.type === 'online'
  }

  initColor() {
    const changeVar = (name: string, value: string) => {
      document.documentElement.style.setProperty(name, value)
    }

    this.$vuetify.theme.themes.dark.primary = PageModule.primaryColor
    this.$vuetify.theme.themes.light.primary = PageModule.primaryColor

    changeVar('--color-active', PageModule.primaryColor)
    changeVar('--background-image', `url(${PageModule.selectedPlace?.buildingImage})`)
  }

  async initFirebase() {
    const firebaseConfig = {
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.FIREBASE_STORAGE_BUCKET || '',
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
    }

    const app = initializeApp(firebaseConfig)

    if (!isSupported()) { return }

    const messaging = getMessaging(app)

    const pushToken = localStorage.getItem('pushToken')
    const supportNotification = Object.prototype.hasOwnProperty.call(window, 'Notification')

    const formattedSubscribe = (subscribe: NotificationSubscribeItems[]) => {
      return subscribe.reduce((acc: any, item) => {
        acc[item.name] = item.enabled
        return acc
      }, {})
    }

    if (!supportNotification || Notification.permission === 'denied') return

    try {
      if (!pushToken || Notification.permission === 'default') {
        await Notification.requestPermission()
      }

      const token = await getToken(messaging) || ''
      let subscribe: NotificationSubscribeItems[] = []

      if (pushToken) {
        let options = { subscribe }
        try {
          options = await PageModule.getNotifications()
        } catch (error) {
          await PageModule.updatePushToken({ token })
          localStorage.setItem('pushToken', token || '')
          return
        }

        subscribe = options.subscribe
      }

      if (!token) {
        console.warn('Получен пустой токен')
        return
      }

      await PageModule.updatePushToken({ token, subscribe: formattedSubscribe(subscribe) })
      localStorage.setItem('pushToken', token || '')
    } catch (error) {
      // alert to user here
      console.log('Не удалось получить токен', error)
    }
  }

  closePlace() {
    PageModule.SET_STATE_PAGE({ key: 'viewPageChoice', value: false })
  }

  @Watch('selectedPlace')
  changeColorPage() {
    this.initColor()
  }
}
