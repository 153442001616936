
import { Vue, Component, Prop } from 'vue-property-decorator'

import TabRequisites from '@/components/page/invoices/tabs/TabRequisites.vue'
import TabAccounts from '@/components/page/invoices/tabs/TabAccounts.vue'
import TabCosts from '@/components/page/invoices/tabs/TabCosts.vue'

// eslint-disable-next-line no-unused-vars
import { IInvoicesItem } from '@/model/page/invoices'

@Component({
  components: {
    TabCosts,
    TabAccounts,
    TabRequisites
  }
})
export default class InvoicesDetail extends Vue {
  @Prop({ default: () => {} }) readonly invoice!: IInvoicesItem

  tabsModel = 0

  get tabs(): Array<{ title: string, component: string }> {
    const tabs: Array<{ title: string, component: string }> = []

    if (this.invoice.sections?.length) {
      tabs.push({ title: 'Счета', component: 'TabAccounts' })
    }

    tabs.push({ title: 'Реквизиты', component: 'TabRequisites' })
    tabs.push({ title: 'Тарифы', component: 'TabCosts' })

    return tabs
  }

  tabProps(component: string): Object {
    if (['TabInvoices', 'TabAccounts'].includes(component)) {
      return { invoice: this.invoice }
    }
    return { invoiceId: this.invoice.id }
  }
}
