import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'

import store from '@/store'
import { $get, $post, $put, $remove } from '@/plugins/axios'

import {
  IChat,
  ChatLoad,
  IMessage,
  ChatLayout,
  IPostMessage,
  EditMessageLayout,
  DeleteMessageLayout
} from '@/model/page/chat'

export interface ChatState {
  chat: IChat
  chatUpdateCounter: number;
}

@Module({ dynamic: true, store, name: 'chat' })
class Chat extends VuexModule implements ChatState {
  chat: IChat = {} as IChat
  activeMessage: null | IMessage = null
  chatUpdateCounter = 0;

  @Mutation
  SET_STATE_CHAT<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Mutation
  ADD_CHAT_MESSAGE<S extends this, K extends keyof this>(message: IMessage) {
    this.chat.items.push(message)
  }

  @Mutation
  UPDATE_CHAT_MESSAGE({ chat, forse }: { chat: IChat, forse?: boolean }) {
    if (this.chat.title) {
      const ids = chat.items.map(({ id }) => id)
      if (!forse) {
        chat.items = chat.items.concat(this.chat.items.filter(({ id }) => !ids.includes(id)))
      }
    }
    this.chat = chat
  }

  @Mutation
  DELETE_MESSAGE(messageId: number) {
    this.chat.items = this.chat.items.filter(({ id }) => id !== messageId)
  }

  @Mutation
  UPDATE_MESSAGE(message: IMessage) {
    this.chat.items = this.chat.items.map((item) => item.id === message.id ? message : item)
  }

  @Action
  async getChat({ orderId, scopeTypeId, fromRow, forse }: ChatLoad) {
    const { data } = await $get<IChat>('chatmessages/', { params: { orderId, scopeTypeId, fromRow } })

    this.UPDATE_CHAT_MESSAGE({ chat: data, forse })
  }

  @Action
  async viewChat({ orderId, scopeTypeId }: ChatLayout) {
    await $put('chatmessages/setviewedall', null, { params: { orderId, scopeTypeId } })
  }

  @Action
  async editMessage({ orderId, scopeTypeId, message, messageId }: EditMessageLayout) {
    const { data } = await $put<IMessage>(`chatmessages/${messageId}`, message, { params: { orderId, scopeTypeId } })

    this.UPDATE_MESSAGE(data)
  }

  @Action
  async deleteMessage({ orderId, scopeTypeId, messageId }: DeleteMessageLayout) {
    const { data } = await $remove(`chatmessages/${messageId}`, { params: { orderId, scopeTypeId } })

    this.DELETE_MESSAGE(messageId)
  }

  @Action
  async sendMessage({ scopeTypeId, orderId, message }: IPostMessage) {
    const placeId = localStorage.getItem('placeId')
    const buildingId = localStorage.getItem('buildingId')

    const { data } = await $post<IMessage>('chatmessages/', { orderId, placeId, buildingId, ...message }, { params: { scopeTypeId, orderId } })

    this.ADD_CHAT_MESSAGE(data)
  }

  @Action
  UpdateChat() {
    this.SET_STATE_CHAT({ key: 'chatUpdateCounter', value: this.chatUpdateCounter++ })
  }
}

export const ChatModule = getModule(Chat)
