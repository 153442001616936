
import noneLayout from '@/layout/none.vue'
import defaultLayout from '@/layout/default.vue'
import PaymentLayout from './layout/payment.vue'

import { PageModule } from '@/store/page'
import { Component, Vue } from 'vue-property-decorator'
import { InvoicesModule } from './store/invoices'

// eslint-disable-next-line no-unused-vars
import { InvocesPageId } from './model/page/invoices'
import AppSmartBannerContainer from '@/components/app/AppSmartBannerContainer.vue'

@Component({
  components: {
    AppSmartBannerContainer,
    noneLayout,
    defaultLayout,
    PaymentLayout
  }
})
export default class App extends Vue {
  loading = false;

  get isA101(): boolean {
    const hosts = ['app.a101.ru', 'lk-a101.domyland.dev']
    return hosts.includes(window.location.host)
  }

  get isSmlt(): boolean {
    const hosts = ['lk-uk.samolet.ru', 'lk-samolet.domyland.dev']
    return hosts.includes(window.location.host)
  }

  get isBrusnika(): boolean {
    const hosts = ['lk.brusnika.ru']
    return hosts.includes(window.location.host)
  }

  get layout() {
    if (this.$route.name === 'pay') { return 'payment' }
    if (this.$route.name === null || this.$route.name === 'login') { return 'none' }
    return 'default'
  }

  get routerKey() {
    if (this.$route.path.split('/').filter(Boolean).length !== 1 || this.$route.name === 'login') {
      return 'page-key'
    }
    return `${this.buildingId}-${this.placeId}`
  }

  get placeId() {
    localStorage.setItem('apiRequests', '{}')
    InvoicesModule.SET_STATE_INVOICES({ key: 'invoice', value: {} as InvocesPageId })
    InvoicesModule.SET_STATE_INVOICES({ key: 'invoiceDetail', value: null })

    return PageModule.placeId
  }

  get buildingId() {
    return PageModule.buildingId
  }

  async mounted() {
    this.loading = true
    const theme = localStorage.getItem('theme') || 'light'
    await PageModule.changeTheme({ theme, self: this })
    await PageModule.getAppLinks()
    if (this.isA101) {
      const themeColorMeta = document.querySelector('meta[name="theme-color"]') as HTMLMetaElement
      themeColorMeta.setAttribute('content', '#E8363D')
      setTimeout(() => document.documentElement?.classList.add('a101'), 0)
    }
    if (this.isSmlt) {
      const themeColorMeta = document.querySelector('meta[name="theme-color"]') as HTMLMetaElement
      themeColorMeta.setAttribute('content', '#007BFB')
      setTimeout(() => document.documentElement?.classList.add('smlt'), 0)
    }
    if (this.isBrusnika) {
      const themeColorMeta = document.querySelector('meta[name="theme-color"]') as HTMLMetaElement
      themeColorMeta.setAttribute('content', '#EF3B24')
      setTimeout(() => document.documentElement?.classList.add('brusnika'), 0)
    }
    this.loading = false
  }
}
