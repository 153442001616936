
import { Component, Vue, Watch } from 'vue-property-decorator'

import { PlaceModule } from '@/store/place'
import { PageModule } from '@/store/page'

import FormParser from '@/components/general/FormParser.vue'

// @ts-ignore
import { mask } from 'vue-the-mask'

// eslint-disable-next-line no-unused-vars
import { ComputedServiceForm, IFormMinimalElement } from '@/model/page/service'

// eslint-disable-next-line no-unused-vars
import { IPlacesLayout, IPlaceCard } from '@/model/page/counter'

@Component({
  components: {
    FormParser
  },
  directives: {
    mask
  }
})
export default class PeopleCreatePage extends Vue {
  isLoading = false
  human = {}

  form: IFormMinimalElement[] = []

  @Watch('form', { deep: true })
  onFormChange(form: IFormMinimalElement[]) {
    const paymentDayIndex = form.findIndex((el) => el.id === 'paymentDay')
    this.form[paymentDayIndex].isShow = (form.find((el) => el.id === 'placeToCustomerStatusId')?.value === 4)

    const elLastName = (form.find((el) => el.id === 'lastName'))
    const middleName = (form.find((el) => el.id === 'middleName'))

    if (this.isGuestCustomerId) {
      if (elLastName) elLastName.required = false
      if (middleName) middleName.required = false
    } else {
      if (elLastName) elLastName.required = true
      if (middleName) middleName.required = true
    }
  }

  get isGuestCustomerId(): boolean {
    return this.form.find((el) => el.id === 'placeToCustomerStatusId')?.value === 100
  }

  async handleForm(form: ComputedServiceForm) {
    try {
      const finalForm = form.reduce((people: any, { id, value }) => {
        people[id] = value
        return people
      }, {})

      // фикс отправки 0, когда выбрана дата 1970-01-01
      const date = `${finalForm.dateBirth} 04:00`
      finalForm.dateBirth = Math.round(new Date(date).getTime() / 1000)

      await PlaceModule.createPeople(finalForm)

      if (PageModule.routePast) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'placesPeople' })
      }
    } catch (error) {
      this.$handleApiError(error)
    }
  }

  async mounted() {
    try {
      if (PageModule.routePast) {
        PageModule.SET_STATE_PAGE({
          key: 'routeReturnFunciton',
          value: () => this.$router.go(-1)
        })
      }

      this.isLoading = true

      let roleValues = await PlaceModule.getPeopleRoles()
      roleValues = roleValues.map(({ id, title }) => {
        return {
          id, title, value: id, text: title
        }
      })

      const form: IFormMinimalElement[] = [
        {
          id: 'phoneNumber',
          placeholder: 'Телефон',
          showTitle: false,
          required: true,
          title: 'Телефон',
          type: 'phone',
          value: ''
        },
        {
          id: 'dateBirth',
          placeholder: 'Дата рождения',
          showTitle: false,
          required: true,
          title: '',
          type: 'date',
          value: ''
        },
        {
          id: 'lastName',
          placeholder: 'Фамилия',
          showTitle: false,
          required: true,
          title: 'Фамилия',
          type: 'string',
          value: ''
        },
        {
          id: 'firstName',
          placeholder: 'Имя',
          showTitle: false,
          required: true,
          title: 'Имя',
          type: 'string',
          value: ''
        },
        {
          id: 'middleName',
          placeholder: 'Отчество',
          showTitle: false,
          required: true,
          title: 'Отчество',
          type: 'string',
          value: ''
        },
        {
          id: 'sex',
          required: true,
          title: 'Пол',
          type: 'select',
          value: '',
          values: [
            {
              id: 1,
              title: 'Мужской',
              value: 'M'
            },
            {
              id: 2,
              title: 'Женский',
              value: 'F'
            }
          ]
        },
        {
          id: 'placeToCustomerStatusId',
          required: true,
          title: 'Роль',
          type: 'select',
          value: '',
          values: roleValues
        },
        {
          id: 'paymentDay',
          required: true,
          title: 'Внесение арендной платы',
          subtitle: 'За 3 дня до указанной даты Арендатор будет получать уведомление о необходимости внесения платы. Вы также получите уведомление в указанный день внесения арендной платы.',
          tooltip: 'Убедитесь, что у Арендатора установлено мобильное приложение',
          type: 'paymentday',
          value: '',
          isShow: false
        }
      ]

      this.form = form

      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}
