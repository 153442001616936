
import { Vue, Component, Prop } from 'vue-property-decorator'
import { UserModule } from '@/store/user'

// eslint-disable-next-line no-unused-vars
import { IHuman } from '@/model/page/counter'

@Component
export default class PeopleAvatar extends Vue {
  @Prop({ required: true, type: Object }) human!: IHuman
  @Prop({ type: Boolean, default: false }) isPersonal!: Boolean

  get generateImageMenu() {
    if (!this.human.isDefaultImage) {
      return [
        {
          title: 'Изменить фотографию',
          callback: this.updateImage
        },
        {
          title: 'Удалить фотографию',
          callback: this.deleteImage
        }
      ]
    } else {
      return [{
        title: 'Добавить фотографию',
        callback: this.updateImage
      }]
    }
  }

  deleteImage() {
    UserModule.deleteAvatar()
  }

  updateImage() {
    const uploader = document.querySelector('#uploadAvatar')

    if (uploader) {
      (uploader as HTMLElement).click()
    }
  }
}
