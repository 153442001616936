
import { $post } from '@/plugins/axios'
import { Debounce } from '@/utils/decorators'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PageModule } from '@/store/page'
import { PayForm, PayFormMethod, CalculateData } from '@/model/page/pay'

@Component
export default class PaymentInfo extends Vue {
  @Prop({ type: Object, required: true }) value!: PayForm;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: String, required: true }) isAuth!: string | null;
  @Prop({ type: String, default: '' }) errorText!: string;
  @Prop({ type: String, default: 'primary' }) color!: string;

  // data

  // Ссылка для перехода в платёжную систему
  paymentLink = ''
  // Флаг отвечающий за привзку новой карты для оплаты
  saveNewCard = false

  valid = true

  emailRules = [
    (v: string) => !!v || 'Обязательно для заполнения',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail должен быть действующим'
  ]

  cardMethodErrorText = ''

  localSum: number | string | undefined = 0

  totalSumLoading = false

  // getters

  // Данные для подсчёта итоговой суммы
  get calculateData(): CalculateData {
    const data: CalculateData = {
      sum: 0,
      method: 'new',
      applyInsurance: false,
      paymentContext: this.value.paymentContext,
      email: '',
      saveNewCard: this.saveNewCard
    }
    if (this.value && this.value.form && this.value.methods) {
      this.value.form.forEach(item => {
        if (item.type === 'float' && this.localSum) {
          data.sum = +this.localSum
        }
        if (item.type === 'checkbox') {
          data.applyInsurance = item.value as boolean
        }
        if (item.type === 'string') {
          data.email = item.value as string
        }
      })
      data.method = this.value.methods[0].type
    }
    return data
  }

  get isDark(): boolean { return PageModule.isDark }

  // actions

  // Пересчитать итоговую сумму
  @Debounce(300)
  async calculateTotalSum(value: CalculateData): Promise<void> {
    this.totalSumLoading = true
    this.$emit('calculate', value)
    await this.$nextTick()
    await this.updatePayment(this.value.methods[0])
  }

  // Обновить способ оплаты
  @Debounce(300)
  async updatePayment(item: PayFormMethod, method?: string): Promise<void> {
    const updateMethod = method || this.calculateData.method
    const calculatedScopeId = updateMethod === 'new' ? null : item.scopeId
    const calculatedScopeTypeId = updateMethod === 'new' ? null : item.scopeTypeId

    this.calculateData.method = item.type
    if (item.type === 'save') this.saveNewCard = false
    if (!this.calculateData.email) return
    try {
      const data = await $post(updateMethod === 'save' ? 'pay/save' : 'pay/new', { ...this.calculateData, scopeId: calculatedScopeId, scopeTypeId: calculatedScopeTypeId, method: updateMethod })
      this.paymentLink = data.data.paymentLink
    } catch (e) {
      this.cardMethodErrorText = e.response.data.userMessages[0]
      // eslint-disable-next-line no-return-assign
      setTimeout(() => this.cardMethodErrorText = '', 5000)
    } finally {
      this.totalSumLoading = false
    }
  }

  // Удалить привязаную карту оплаты
  async deleteCard(item: PayFormMethod): Promise<void | boolean> {
    if (item) {
      this.$emit('delete-card', item)
      await this.updatePayment(this.value.methods[0], 'new')
      return false
    }
    await this.$nextTick()
    // Обновляем способ оплаты, так как удалили карту
    await this.updatePayment(this.value.methods[0])
  }

  // Обработчик привязки новой карты
  handleSaveCard(): void {
    const item: PayFormMethod | undefined = this.value.methods.find(item => item.type === 'new')
    this.updatePayment(item as PayFormMethod)
  }

  // Добавить или убрать страхование
  async onChangeInsurance(): Promise<void> {
    await this.updatePayment(this.value.methods[0])
    await this.$nextTick()
    this.$emit('calculate', this.calculateData)
  }

  async mounted(): Promise<void> {
    // Проставить способ оплаты в зависимости от текущей карты пользователя при инициализации
    await this.updatePayment(this.value.methods[0])
    if (this.value && this.value.form) {
      this.localSum = this.value.form.find(item => item.type === 'float')?.value as string | number
    }
  }
}
