
import { Component, Vue, Prop } from 'vue-property-decorator'
import { $post } from '@/plugins/axios'
import { HandleError } from '@/utils/helper'
import { PageModule } from '@/store/page'
import AppPasswordInput from '@/components/app/AppPasswordInput.vue'
@Component({
  components: {
    AppPasswordInput
  }
})
export default class SetPassword extends Vue {
  @Prop({ type: String, default: 'primary' }) color!: string;
  // data
  loading = false;

  data = {
    password: '',
    passwordConfirmation: ''
  }

  // actions
  async setPassword(): Promise<void> {
    try {
      this.loading = true
      await $post('/current-customer/set-password', this.data)
      PageModule.SEND_NOTIFICATION({ type: 'success', message: 'Пароль успешно установлен' })
      this.$emit('on-password-set')
    } catch (e) {
      HandleError(e)
    }
  }
}
