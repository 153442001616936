import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'

import store from '@/store/index'
import { PageModule } from '@/store/page'
import { $post, $get } from '@/plugins/axios'

import { PostAuthCode, GetAgreement } from '@/model/index'

export interface AuthState {
  token: string
  agreement: string
}

@Module({ dynamic: true, store, name: 'auth' })
class Auth extends VuexModule implements AuthState {
  token: string = localStorage.getItem('token') || ''
  agreement: string = ''

  @Mutation
  SET_STATE_AUTH<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Action
  async getCode(body: { phoneNumber: string }) {
    const { data, success } = await $post<PostAuthCode>('/auth/code', body)
    const message = data?.message || ''
    const code = message?.match(/\d{4}/)

    if (code && code[0]) {
      return { code: code[0], success }
    }

    if (success && message) {
      PageModule.SEND_NOTIFICATION({ type: 'success', message })
    }

    return { code: '', success }
  }

  @Action
  async auth(body: { phoneNumber: string, code: string }) {
    try {
      const { data, success } = await $post('/auth', body)
      if (success && data?.token) {
        localStorage.setItem('token', data.token)
      }
      return success
    } catch (err) {
      localStorage.removeItem('token')
      localStorage.removeItem('pushToken')
      localStorage.removeItem('authorization')
      PageModule.SEND_NOTIFICATION({ type: 'error', message: err?.response?.data?.userMessages[0] })
      return false
    }
  }

  @Action
  async getAgreement() {
    try {
      const { data } = await $get<GetAgreement>('/index/agreement')
      this.SET_STATE_AUTH({ key: 'agreement', value: data?.content })
    } catch (err) {
      console.error('get agreement err', err)
    }
  }
}

export const AuthModule = getModule(Auth)
