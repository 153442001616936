
import { Component, Vue } from 'vue-property-decorator'
import { HandleApiRequest } from '@/utils/helper'
import { PlaceModule } from '@/store/place'

@Component
export default class ActiveOrders extends Vue {
  isLoading = true

  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    mousewheel: true
  }

  get orders() {
    return PlaceModule.activeOrders
  }

  @HandleApiRequest()
  async mounted() {
    await PlaceModule.getActiveOrders()
  }
}
