
import { Component, Vue } from 'vue-property-decorator'
import AppPlaceButtonPay from '@/components/general/AppPlaceButtonPay.vue'
import { PageModule } from '@/store/page'

// eslint-disable-next-line no-unused-vars
import { IPlace } from '@/model/index'

@Component({
  components: {
    AppPlaceButtonPay
  }
})
export default class AppPlaceLayout extends Vue {
  isFinallyView = false

  get isInfoPage() {
    return this.$route.name === 'InfoPlaces'
  }

  get places() {
    return PageModule.places
  }

  get buttonPay() {
    return PageModule.buttonPay
  }

  get buttonPayLoading() {
    return PageModule.buttonPayLoading
  }

  get selectedPlace() {
    return PageModule.selectedPlace
  }

  mounted() {
    setTimeout(() => {
      this.isFinallyView = true
    }, 200)
  }

  async changePlace(place: IPlace) {
    if (this.buttonPayLoading) return

    if (this.selectedPlace?.id === place.id || window.innerWidth < 900) {
      this.$emit('close')
    }

    PageModule.SET_SELECTED_PLACE(place)

    if (this.$route.name === 'InfoPlaces') {
      this.$router.push({ name: 'services' })
    }
  }
}
