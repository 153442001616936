
import { Component, Vue } from 'vue-property-decorator'

import { PageModule } from '@/store/page'

@Component
export default class AppPhoneDrawer extends Vue {
  get menuItems() {
    return PageModule.menuItems
  }
}
