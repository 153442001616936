
import { Vue, Component, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { ILoyaltyPartnerCard } from '@/model/page/loyalty'

@Component
export default class LoyaltyPartnerCard extends Vue {
  @Prop({ required: true }) partner!: ILoyaltyPartnerCard
}
