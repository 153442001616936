
import { Component, Ref, Vue } from 'vue-property-decorator'
import { HandleApiRequest } from '@/utils/helper'

import { PlaceModule } from '@/store/place'
import { PageModule } from '@/store/page'

import SummaryParser from '@/components/general/SummaryParser.vue'
import ButtonParser from '@/components/general/ButtonParser.vue'
import Rating from '@/components/general/Rating.vue'

// eslint-disable-next-line no-unused-vars
import { $post } from '@/plugins/axios'
import { IOrderCancelFeedbackCategory } from '@/model/page/counter'
import AppIcon from '@/components/app/AppIcon.vue'

@Component({
  components: {
    AppIcon,
    SummaryParser,
    ButtonParser,
    Rating
  }
})
export default class OrderIdPage extends Vue {
  @Ref('feedbackCommentComponent') feedbackCommentComponent: any | null;

  isLoading = true
  cancelModuleView = false
  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 10,
    mousewheel: true
  }

  reopenModal = {
    view: false,
    text: ''
  }

  refundTypes = [
    { value: 'full', title: 'Полный' },
    { value: 'partial', title: 'Частичный' }
  ]

  refundModal = {
    view: false,
    isValid: false,
    refundType: 'full',
    refundComment: '',
    refundAmount: ''
  }

  deleteModal = {
    view: false
  }

  rateLoading = false
  timer: null | number = null
  cancelFeedbackModal = false
  acceptedCancelModal = false

  feedback = {
    categories: [] as IOrderCancelFeedbackCategory[],
    comment: ''
  }

  get order() {
    return PlaceModule.order
  }

  get cancelFeedbackCategories(): IOrderCancelFeedbackCategory[] {
    return PlaceModule.orderFeedbackCategories
  }

  get feedbackCommentRules(): ((value: string) => string | boolean)[] {
    return [
      (value: string): string | boolean => !!value || 'Поле обязательно для заполнения',
      (value: string): string | boolean => (value || '').length <= 1022 || 'Длина текста не должна превышать 1022 символов'
    ]
  }

  get isAcceptFeedbackIncorrect(): boolean {
    return !this.feedback.categories.some((category) => category?.isRequiredComment && !this.feedback.comment)
  }

  get computedTimer() {
    const date = this.timer

    if (date === null) return ''

    if (date <= 0) this.$router.go(0)

    const dayEnding = {
      '-1': 'дней',
      1: 'день',
      '2,3,4': 'дня',
      between: 'дней'
    }

    const hourEnding = {
      '-1': 'часов',
      1: 'час',
      '2,3,4': 'часа',
      between: 'часов'
    }

    const minuteEnding = {
      '-1': 'минут',
      1: 'минута',
      '2,3,4': 'минуты',
      between: 'минут'
    }

    const secondEnding = {
      '-1': 'секунд',
      1: 'секунда',
      '2,3,4': 'секунды',
      between: 'секунд'
    }

    const generateEnd = (endingObject: any, day: number) => {
      const last = day % 10

      const endingArray = Object.entries(endingObject).find((key, day) => key.includes(last + ''))
      let ending = endingArray?.[1] || endingObject['-1']

      if (day > 10 && day < 20) {
        ending = endingObject.between
      }

      return day ? `${day} ${ending}` : ''
    }

    const day = Math.floor(date / 60 / 60 / 24)
    const hour = Math.floor(date / 60 / 60) % 24
    const minute = Math.floor(date / 60) % 60
    const second = Math.floor(date) % 60

    return `${generateEnd(dayEnding, day)} ${generateEnd(hourEnding, hour)} ${generateEnd(minuteEnding, minute)} ${generateEnd(secondEnding, second)}`
  }

  get isSendFeedbackButtonDisabled(): boolean {
    return this.feedback.categories.every((category) => !category)
  }

  @HandleApiRequest()
  async mounted() {
    PageModule.SET_STATE_PAGE({
      key: 'routeReturnFunciton',
      value: () => this.$router.push('/places')
    })

    const { id } = this.$route.params
    const scopeTypeId = this.$route.query.scopeTypeId as string

    await PlaceModule.getOrder({ scopeTypeId, id })
    await PlaceModule.getFeedbackCategories({ scopeTypeId, id })

    if (PlaceModule.order.hasStatusUpdate) { await PlaceModule.setOrderViewedStatus({ id, scopeTypeId }) }

    if (this.order.paymentEndTime) {
      this.timer = this.order.paymentEndTime - new Date().getTime() / 1000

      setInterval(() => {
        if (this.timer) --this.timer
      }, 1000)
    }
  }

  @HandleApiRequest()
  async callbackCancel() {
    this.cancelModuleView = false
    this.cancelFeedbackModal = false
    const { id } = this.$route.params
    const scopeTypeId = this.$route.query.scopeTypeId as string

    await PlaceModule.cancelOrder({ id, scopeTypeId })

    if (this.order.hasCancelFeedback) {
      await PlaceModule.sendCancelOrderFeedback({ id, scopeTypeId, serviceFeedbackCategories: this.feedback.categories, comment: this.feedback.comment })
    }

    await PlaceModule.getOrder({ scopeTypeId, id })

    this.acceptedCancelModal = true
  }

  async rateOrderHandle() {
    try {
      this.rateLoading = true
      const { id } = this.$route.params
      const scopeTypeId = this.$route.query.scopeTypeId as string
      await PlaceModule.rateOrder({
        id,
        scopeTypeId,
        rating: this.order.rating,
        ratingComment: this.order.ratingComment || ''
      })
    } catch (error) {
      this.$handleApiError(error)
    } finally {
      this.rateLoading = false
    }
  }

  async deletePreorder(): Promise<void> {
    try {
      await $post(`/orders/${this.order.id}/delete-pre-order?scopeTypeId=2`)
      await this.$router.push('/services')
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  @HandleApiRequest()
  async reopen() {
    const { id } = this.$route.params
    const scopeTypeId = this.$route.query.scopeTypeId as string

    this.reopenModal.view = false

    await PlaceModule.reopenOrder({ id, scopeTypeId, text: this.reopenModal.text })
    await PlaceModule.getOrder({ scopeTypeId, id })

    this.reopenModal.text = ''
  }

  @HandleApiRequest()
  async refund() {
    const { id } = this.$route.params
    const scopeTypeId = this.$route.query.scopeTypeId as string
    const { refundType, refundComment, refundAmount } = this.refundModal

    const data = await PlaceModule.refundOrder({
      id,
      scopeTypeId,
      refundType,
      refundComment,
      refundAmount: refundAmount ? parseFloat(refundAmount) : 0
    })

    if (data && data.success) {
      this.refundModal.view = false
      await PlaceModule.getOrder({ scopeTypeId, id })
    }
  }

  async onAcceptCancel(): Promise<void> {
    if (this.order.hasCancelFeedback) {
      this.cancelModuleView = false
      this.cancelFeedbackModal = true
    } else {
      await this.callbackCancel()
    }
  }

  async onAcceptCancelFeedback(): Promise<void> {
    if (!this.order.hasCancelFeedback || (this.order.hasCancelFeedback && this.isAcceptFeedbackIncorrect)) {
      await this.callbackCancel()
    } else {
      // todo Найти способ валидировать и выводить ошибку нормальным способом
      this.feedbackCommentComponent.focus()
      this.feedbackCommentComponent.blur()
    }
  }
}
