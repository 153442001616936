
import { Vue, Component, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IPayment } from '@/model/page/invoices'

@Component
export default class InvoicesPaymentsCard extends Vue {
  @Prop({ default: () => {} }) readonly payment!: IPayment
}
