
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PlaceModule } from '@/store/place'
import { HandleApiRequest } from '@/utils/helper'

@Component
export default class OrderPage extends Vue {
  isLoading = true
  nextRow = PlaceModule.orders.length
  isMoreLoading = false

  @Prop({ type: Boolean }) isLayout!: boolean

  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    mousewheel: true
  }

  get isMoreExist() {
    return this.nextRow > 0
  }

  get orders() {
    const info: any = {}

    if (!PlaceModule.orders.length) return info

    PlaceModule.orders.forEach(order => {
      if (!info[order.createdAtFormatted]) {
        info[order.createdAtFormatted] = []
      }
      info[order.createdAtFormatted].push(order)
    })

    return info
  }

  @HandleApiRequest()
  async mounted() {
    localStorage.setItem('placesActiveMenu', 'HistoryPage')

    PlaceModule.SET_STATE_PLACE({ key: 'orders', value: [] })

    const { nextRow } = await PlaceModule.getOrders()

    this.nextRow = nextRow
  }

  @HandleApiRequest({ prop: 'isMoreLoading' })
  async getMoreOrders(entries: Array<IntersectionObserverEntry>) {
    if (entries[0].isIntersecting) {
      const { nextRow } = await PlaceModule.getOrders({ fromRow: this.nextRow })

      this.nextRow = nextRow
    }
  }
}
