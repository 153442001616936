<template>
  <v-dialog
    v-model="display"
    :width="dialogWidth"
  >
    <template v-slot:activator="{on}">
      <div v-on="on">
        <v-text-field
          prepend-icon="mdi-calendar"
          placeholder="Дата"
          v-bind="textFieldProps"
          :disabled="disabled"
          :rules="rules"
          :loading="loading"
          :value="formattedDatetime"
          readonly
        >
          <template v-slot:progress>
            <slot name="progress">
              <v-progress-linear
                color="primary"
                indeterminate
                absolute
                height="2"
              />
            </slot>
          </template>
        </v-text-field>
      </div>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-date-picker
          v-model="date"
          :first-day-of-week="1"
          color="primary"
          v-bind="datePickerProps"
          :allowed-dates="allowedDates"
          full-width
          @input="showTimePicker"
        />

        <div class="datetime-block noselect">
          <div class="datetime-time-block">
            <div
              :class="`${maxHour <= hour ? 'disabled' : ''} datetime-time-arrow datetime-time-arrow--up`"
              @click="plusHour"
            />
            <h5 class="datetime-time-title">
              {{ ('0' + hour).slice(-2) }}
            </h5>
            <div
              :class="`${minHour >= hour ? 'disabled' : ''}  datetime-time-arrow`"
              @click="minusHour"
            />
          </div>

          <h5 class="datetime-time-title datetime-time-title--dots">
            :
          </h5>

          <div class="datetime-time-block">
            <div
              :class="`${maxMinute <= minute ? 'disabled' : ''} datetime-time-arrow datetime-time-arrow--up`"
              @click="plusMinute"
            />
            <h5 class="datetime-time-title">
              {{ ('0' + minute).slice(-2) }}
            </h5>
            <div
              :class="`${minMinute >= minute ? 'disabled' : ''}  datetime-time-arrow`"
              @click="minusMinute"
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <slot
          name="actions"
          :parent="this"
        >
          <v-btn
            color="grey lighten-1"
            text
            @click.native="clearHandler"
          >
            {{ clearText }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="okHandler"
          >
            {{ okText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parse, isBefore, isEqual, isAfter } from 'date-fns'

const DEFAULT_DATE = ''
const DEFAULT_TIME = '13:00:00'
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
const DEFAULT_DIALOG_WIDTH = 340
const DEFAULT_CLEAR_TEXT = 'отмена'
const DEFAULT_OK_TEXT = 'сохранить'
const ROUND_MINUTES = 1

export default {
  name: 'AppDateTimePicker',
  model: {
    prop: 'datetime',
    event: 'input'
  },
  props: {
    max: {
      type: [Date, String, Number],
      default: null
    },
    min: {
      type: [Date, String, Number],
      default: null
    },
    rules: {
      type: [Array],
      default: () => []
    },
    datetime: {
      type: [Date, String, Number],
      default: null
    },
    disabled: {
      type: Boolean
    },
    tooltip: {
      type: String,
      default: ''
    },
    depend: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT
    },
    timeFormat: {
      type: String,
      default: 'HH:mm'
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT
    },
    textFieldProps: {
      type: Object,
      default: () => ({})
    },
    datePickerProps: {
      type: Object,
      default: () => ({})
    },
    timePickerProps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      hour: 13,
      minute: 0,
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME
    }
  },
  computed: {
    isOnMaxEdge() {
      const max = this.setZeroTime(new Date(this.max))
      const date = this.setZeroTime(new Date(this.date))

      return isEqual(date, max)
    },
    getMaxTime() {
      if (this.isOnMaxEdge) {
        const date = new Date(this.max)

        return `${date.getHours()}:${date.getMinutes()}`
      }

      return ''
    },
    getMinTime() {
      if (this.isOnMinEdge) {
        const date = new Date(this.min)

        return `${date.getHours()}:${date.getMinutes()}`
      }

      return ''
    },
    isOnMinEdge() {
      const date = this.setZeroTime(new Date(this.date))
      const min = this.setZeroTime(new Date(this.min))

      return isEqual(date, min)
    },
    dateTimeFormat() {
      return this.dateFormat + ' ' + this.timeFormat
    },
    defaultDateTimeFormat() {
      return DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT
    },
    formattedDatetime() {
      return this.selectedDatetime ? format(this.selectedDatetime, 'dd.MM.yyyy HH:mm') : ''
    },
    selectedDatetime() {
      if (this.date && this.hour !== null && this.minute !== null) {
        const datetimeString = `${this.date} ${this.hour}:${('0' + this.minute).slice(-2)}:00`

        return parse(datetimeString, this.defaultDateTimeFormat, new Date())
      } else {
        return null
      }
    },
    dateSelected() {
      return !this.date
    },
    maxHour() {
      return parseInt(this.getMaxTime.split(':')[0] || 24)
    },
    minHour() {
      return parseInt(this.getMinTime.split(':')[0] || -1)
    },
    maxMinute() {
      if (this.hour >= this.maxHour) {
        return this.round(parseInt(this.getMaxTime.split(':')[1]))
      }

      return 60
    },
    minMinute() {
      if (this.hour <= this.minHour) {
        return this.round(parseInt(this.getMinTime.split(':')[1]))
      }

      return -1
    }
  },
  watch: {
    datetime() {
      this.init()
    },
    getMaxTime() {
      this.initTime()
    },
    getMinTime() {
      this.initTime()
    },
    minMinute() {
      this.initTime()
    },
    maxMinute() {
      this.initTime()
    },
    max(value) {
      if (this.datetime > value && this.depend) {
        this.$emit('input', value)
      }
    },
    min(value) {
      if (this.datetime < value && this.depend) {
        this.$emit('input', value)
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    plusHour() {
      if (this.maxHour > this.hour) {
        if (this.hour === 23) {
          this.hour = this.minHour === -1 ? 0 : this.minHour
          this.init()
        } else {
          this.hour++
        }
      }
    },
    minusHour() {
      if (this.minHour < this.hour) {
        if (this.hour === 0) {
          this.hour = this.maxHour === 24 ? 23 : this.maxHour
          this.init()
        } else {
          this.hour--
        }
      }
    },
    plusMinute() {
      if (this.maxMinute > this.minute) {
        if (this.minute === 59) {
          this.minute = this.minMinute === -1 ? 0 : this.minMinute
        } else {
          this.minute += ROUND_MINUTES
        }
      }
    },
    minusMinute() {
      if (this.minMinute < this.minute) {
        if (this.minute === 0) {
          this.minute = this.maxMinute === 60 ? 59 : this.maxMinute
          this.init()
        } else {
          this.minute -= ROUND_MINUTES
        }
      }
    },
    init() {
      if (!this.datetime) {
        return
      }

      let initDateTime
      if (this.datetime instanceof Date) {
        initDateTime = this.datetime
      } else if (typeof this.datetime === 'string' || this.datetime instanceof String) {
        initDateTime = parse(this.datetime, this.dateTimeFormat, new Date())
      } else if (typeof this.datetime === 'number' || this.datetime instanceof Number) {
        initDateTime = new Date(this.datetime)
      }

      this.date = format(initDateTime, DEFAULT_DATE_FORMAT)

      const [hour, minute] = format(initDateTime, DEFAULT_TIME_FORMAT).split(':')
      this.hour = hour
      this.minute = this.round(minute)

      this.initTime()
    },
    initTime() {
      const time = this.hour * 60 + this.minute

      const [maxHour, maxMinute] = this.getMaxTime.split(':').map(n => parseInt(n))
      const maxTime = maxHour * 60 + maxMinute

      const [minHour, minMinute] = this.getMinTime.split(':').map(n => parseInt(n))
      const minTime = minHour * 60 + minMinute

      if (minTime > time) {
        this.hour = minHour
        this.minute = this.round(minMinute)
      }

      if (maxTime < time) {
        this.hour = maxHour
        this.minute = this.round(maxMinute)
      }
    },
    allowedDates(date) {
      if (this.max || this.min) {
        date = this.setZeroTime(new Date(date))

        const max = this.setZeroTime(new Date(this.max))
        const maxAnswer = this.max ? isBefore(date, max) || isEqual(date, max) : true
        const min = this.setZeroTime(new Date(this.min))
        const minAnswer = this.min ? isAfter(date, min) || isEqual(date, min) : true

        return maxAnswer && minAnswer
      }

      return true
    },
    round(n) {
      return Math.floor(n / ROUND_MINUTES) * ROUND_MINUTES
    },
    setZeroTime(date) {
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)

      return date
    },
    okHandler() {
      this.resetPicker()
      this.$emit('input', this.selectedDatetime)
    },
    clearHandler() {
      this.resetPicker()
      this.date = DEFAULT_DATE

      this.hour = 13
      this.minute = 0

      this.$emit('input', null)
    },
    resetPicker() {
      this.display = false
      this.activeTab = 0
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }
    },
    showTimePicker() {
      this.activeTab = 1
      this.$emit('updateDate', this.date)
    }
  }
}
</script>

<style lang="sass">
.default-input
  padding: 2px 5px!important
  border: 1px solid #ced4da
  &.v-input input
    color: #555
    font-size: .98rem
  .v-input__slot
    margin: 0
  .v-text-field__details
    display: none!important
  &.v-text-field > .v-input__control > .v-input__slot
    &::before, &::after
      content: none

.datetime-block
  margin: 0 auto
  display: flex
  width: 50%
  justify-content: center

.datetime-time-block
  width: 30px
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center

.datetime-time-title
  font-size: 1.675rem
  margin: 4px 0

.datetime-time-title--dots
  line-height: 50px
  margin: 0 8px

.datetime-time-arrow--up
  transform: scaleY(-1)

.datetime-time-arrow
  width: 0
  height: 0
  border-left: 10px solid transparent
  border-right: 10px solid transparent
  border-top: 10px solid var(--color-active)
  cursor: pointer
  transition: .2s
  &.disabled
    cursor: inherit
    opacity: .5
</style>
