import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import store from '@/store'

// @ts-ignore
import convert from 'color-convert'

import { UserModule } from '@/store/user'

import { $get, $post } from '@/plugins/axios'

import {
  IAlert,
  IPlace,
  ISection,
  IDialogs,
  DadataDTO,
  IMenuItem,
  MENU_ICONS,
  getAppLink,
  TAlertType,
  AdvertMenu,
  IButtonPay,
  getSections,
  LoginStatus,
  NotificationSubscribe,
  GetNotificationSubscribe
} from '@/model'

let timerAnimationEnd: any = null
const routerAnimationTransition = 600

export interface PageState {
  route: Route | null
  routePast: Route | null
  dialogs: Array<IDialogs>
  alert: IAlert
  placeId: number | string
  buildingId: number | string
  viewPageChoice: boolean
  sections: ISection[]
  isInited: boolean
  theme: string
  pageCode: number
  buttonPay: IButtonPay
  buttonPayLoading: Boolean
  applications: getAppLink;
}

@Module({ dynamic: true, store, name: 'page' })
class Page extends VuexModule implements PageState {
  route: Route | null = null
  dialogs: Array<IDialogs> = []
  routePast: Route | null = null
  theme = 'light'
  viewPageChoice = false
  routeReturnFunciton: null | Function = null
  iosAppLink = ''
  androidAppLink = ''
  pageCode = 200
  buttonPay = {} as IButtonPay
  buttonPayLoading = false
  isRouteAnimating = false
  applications = {} as getAppLink;
  alert: IAlert = {
    type: 'success',
    message: '',
    show: false
  }

  placeId = parseInt(localStorage.getItem('placeId') || '')
  buildingId = parseInt(localStorage.getItem('buildingId') || '')

  breadCrumbsTitle = ''

  sections: ISection[] = [] as ISection[]
  isInited = false
  colors = [
    '#9c27b0',
    '#f44336',
    '#5bc0de',
    '#4caf50',
    '#ff9800',
    '#009688',
    '#d9534f'
  ]

  @Mutation
  SET_STATE_PAGE<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Mutation
  UPDATE_ROUTES<S extends this, K extends keyof this>({ from, to }: { from: Route, to: Route }) {
    this.route = to
    this.routePast = from
    this.isRouteAnimating = true
    this.pageCode = 200

    if (timerAnimationEnd) {
      clearTimeout(timerAnimationEnd)
    }

    timerAnimationEnd = setTimeout(() => {
      this.isRouteAnimating = false
    }, routerAnimationTransition)
  }

  @Mutation
  SEND_NOTIFICATION({ type, message }: { type: TAlertType, message: string }) {
    this.alert = { type, message, show: true }
  }

  @Mutation
  SET_SELECTED_PLACE(place: IPlace) {
    this.placeId = place.id
    this.buildingId = place.buildingId
    localStorage.setItem('placeId', place.id + '')
    localStorage.setItem('buildingId', place.buildingId + '')
  }

  @Mutation
  SET_APPLICATIONS(data: getAppLink) {
    this.applications = data
  }

  get layout() {
    return (this.route && this.route?.meta?.layout) || 'default'
  }

  get isDark() {
    return this.theme === 'dark'
  }

  get places() {
    return UserModule.user.places || []
  }

  get selectedPlace() {
    return this.places
      .find((item) => item.id === this.placeId && item.buildingId === this.buildingId)
  }

  get isAdvertsLayout() {
    return this.route?.fullPath.includes('/adverts')
  }

  get menuItems(): IMenuItem[] {
    if (this.isAdvertsLayout) {
      return AdvertMenu
    }
    return this.sections.map(item => {
      return { ...item, to: { name: item.name }, icon: MENU_ICONS[item.name] || '' }
    })
      .filter(item => item.isAvailable)
  }

  get unavailableRoutes() {
    return this.sections
      .filter(item => !item.isAvailable)
      .map(item => item.name)
  }

  get primaryColor() {
    let color = this.selectedPlace?.companyThemeColorHex || this.colors[0]
    const hsl = convert.hex.hsl(color)
    const light = hsl[2]

    if (light < 40 && this.isDark) {
      const [r, g, b] = convert.hsl.rgb(hsl[0], hsl[1], 40)
      const hex = convert.rgb.hex(r, g, b)

      color = `#${hex}`
    }

    return color
  }

  @Action
  async getSections() {
    const { data } = await $get<getSections>('/sections')
    this.SET_STATE_PAGE({ key: 'sections', value: data?.items })
  }

  @Action
  async getOnePaymentButton(query: { placeTypeIds?: string, companyId?: string }) {
    this.SET_STATE_PAGE({ key: 'buttonPayLoading', value: true })

    try {
      const { data } = await $get<IButtonPay>('/invoices/one-button-pay', { params: query })

      this.SET_STATE_PAGE({ key: 'buttonPay', value: data })

      return data
    } finally {
      this.SET_STATE_PAGE({ key: 'buttonPayLoading', value: false })
    }
  }

  @Action
  async getAppLinks() {
    const { data } = await $get<getAppLink>('/application?maxWidth=32&fit=1')
    if (data.icon) {
      const metaIcons = document.querySelectorAll('link[rel="icon"], link[rel="apple-touch-icon"],link[rel="apple-touch-icon-precomposed"], link[rel="apple-touch-startup-image"], link[rel="mask-icon"],meta[name="msapplication-TileImage"],meta[name="msapplication-TileImage"] ')

      metaIcons.forEach((meta) => {
        (meta as HTMLAnchorElement).href = data.icon!
        if ((meta as HTMLMetaElement).content) (meta as HTMLMetaElement).content = data.icon!
      })
    }
    this.SET_APPLICATIONS(data)
  }

  @Action
  changeColor({ color, self }: { color?: string, self: any }) {
    if (!color) {
      color = this.primaryColor
    }

    const changeVar = (name: string, value: string) => {
      document.documentElement.style.setProperty(name, value)
    }

    self.$vuetify.theme.themes.dark.primary = color
    self.$vuetify.theme.themes.light.primary = color

    changeVar('--color-active', color!)
  }

  @Action
  async changeTheme({ theme, self }: { theme: string, self: any}) {
    this.SET_STATE_PAGE({ key: 'theme', value: theme })

    self.$vuetify.theme.dark = this.isDark
    this.changeColor({ self })

    document.documentElement.setAttribute('class', `theme--${theme}`)

    localStorage.setItem('theme', theme)
  }

  @Action
  async getNotifications() {
    const { data } = await $get<GetNotificationSubscribe>('/pushToken')

    return data
  }

  @Action
  async updatePushToken({ token, subscribe }: NotificationSubscribe) {
    return await $post<NotificationSubscribe>('/pushToken', {
      token,
      subscribe: subscribe || {}
    })
  }

  @Action
  async getPosition(address: string, count = 5) {
    const response = await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
      method: 'POST',
      headers: {
        authorization: `Token ${process.env.VUE_APP_DADATA_API_KEY}`,
        'content-type': 'application/json',
        accept: 'application/json, text/javascript, */*; q=0.01',
        'accept-encoding': 'gzip, deflate, br',
        'accept-language': 'en-US,en;q=0.9',
        appname: 'web',
        timezone: 'Europe/Moscow',
        'x-timezone': 'Europe/Moscow',
        'x-version': '18.6.0'
      },
      body: JSON.stringify({
        query: address,
        count
      })
    })
    const data = await response.json()

    return data as DadataDTO
  }

  @Action
  async initPage() {
    localStorage.removeItem('newsfeedTab')
    localStorage.setItem('apiRequests', '{}')

    const user = await UserModule.getUser()

    if (!user.places.length) {
      return LoginStatus.USER_DONT_EXIST
    }

    const place = this.places.find((item) => item.id === this.placeId && item.buildingId === this.buildingId)
    this.SET_SELECTED_PLACE(place || this.places[0])

    await this.getSections()

    this.SET_STATE_PAGE({ key: 'isInited', value: true })
  }
}

export const PageModule = getModule(Page)
