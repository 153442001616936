
/* eslint-disable no-unused-vars */
import { Component, Vue, Watch } from 'vue-property-decorator'
import { IChat, IMessage, IPostMessageQuery } from '@/model/page/chat'
/* ts ignore */
import dayjs from 'dayjs'
import MessageBlock from '@/components/page/chat/MessageBlock.vue'
import AudioController from '@/components/page/chat/AudioController.vue'
import RecordController from '@/components/page/chat/RecordController.vue'
import {
  imageTypes,
  videoTypes,
  audioTypes,
  accessTypes,
  EventLayout,
  IImageObject,
  IDocumentChat,
  IntersectionObserverEntry
} from '@/model'
import { HandleApiRequest, parseFiles } from '@/utils/helper'
import { UserModule } from '@/store/user'
import { $get, $post, $put, $remove } from '@/plugins/axios'
import { ChatModule } from '@/store/chat'
type messagesGroup = Array<{ date: string, items: IMessage[][], isActive: boolean}>
@Component({
  components: {
    MessageBlock,
    AudioController,
    RecordController
  }
})
export default class InfoChatPage extends Vue {
  // data
  isLoading = false;
  isLoadingMessage = false;
  isLoadingMessages = false;
  isNeedScrollToBottom = false;

  imageModal = {
    src: '',
    view: false
  }

  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 15,
    mousewheel: true
  }

  chat = {
    nextRow: 0
  } as IChat;

  filesToUpload: Array<IImageObject | IDocumentChat> = []
  editMessage: IMessage | null = null;
  message = '';
  beforeEditText = '';

  imageTypes = imageTypes
  videoTypes = videoTypes
  audioTypes = audioTypes

  isRecordView = false;
  recordVolume = 1;
  recordTime = 0;
  fileRecordName = 'audiorecord.mp3';

  isDropFocus = false;
  hideDropWrapper = true;
  isInited = false;
  isScrollAnimationEnded = false;

  timeToUnfocusDrop: any = null;
  dropAmout = 0;

  // getters
  get isUnfocusWrapper():boolean {
    return !this.isDropFocus && this.hideDropWrapper
  }

  get isMoreExist() {
    return this.chat.nextRow > 0
  }

  get isShowLoadMoreBlock() {
    return this.isInited && this.isMoreExist && this.isScrollAnimationEnded && !this.isLoadingMessages
  }

  get showRecordButton() {
    return !this.message && !this.filesToUpload.length && !this.editMessage
  }

  get audioRecordFile() {
    return this.filesToUpload.find(({ name }) => name === this.fileRecordName)
  }

  get formattedType() {
    return (type: string) => {
      return type.replace(/^[\w\W]{1,}\/([a-z]{1,})([\w\S]{0,})/, '$1')
    }
  }

  get _chatCounter(): number {
    return ChatModule.chatUpdateCounter
  }

  get messagesGroupPeople(): messagesGroup {
    if (!this.chat.items) return []

    return this.chat.items.reduce((final: messagesGroup, message: IMessage) => {
      const format = (date: number) => dayjs(date * 1000).format('YYYY-MM-DD')
      const existDateIndex = final.findIndex((item: any) => item.date === format(message.createdAt))
      const lastDateItems = final[final.length - 1]?.items || []
      const peopleGroup = lastDateItems[lastDateItems.length - 1] || []

      if (existDateIndex === -1) {
        final.push({
          isActive: true,
          date: format(message.createdAt),
          items: [[message]]
        })
      } else {
        if (peopleGroup[0].createdByUserId !== message.createdByUserId) {
          final[final.length - 1].items.push([message])
        } else {
          peopleGroup.push(message)
        }
      }

      return final
    }, [])
  }

  // actions
  formatDate(date: string): string {
    return dayjs(date, 'YYYY-MM-DD').format('MMM DD')
  }

  openModalImage(image: string): void {
    this.imageModal.src = image
    this.imageModal.view = true
  }

  handleDeleteMessage(message: IMessage) {
    if (this.editMessage?.id === message?.id) {
      this.editMessage = null
    }
    this.deleteChatMessage(message.id)
  }

  handleEditMessage(message: IMessage) {
    this.beforeEditText = this.message
    this.message = message?.text || ''
    this.editMessage = message;

    (this.$refs.textarea as HTMLElement).focus()
  }

  cancelRecord() {
    if (this.$refs.recordController) {
      (this.$refs.recordController as any).cancelRecord()
    }
    this.filesToUpload = this.filesToUpload.filter(({ name }) => name !== this.fileRecordName)
    this.isRecordView = false
  }

  scrollToBottom(time = 500) {
    const element = this.$refs.messages as Element

    if (element) {
      const scrollTop = element.scrollTop

      const circ = (timeFraction: number) => {
        return 1 - Math.sin(Math.acos(timeFraction))
      }

      this.$animate({
        duration: time,
        timing: circ,
        draw: function(progress) {
          element.scrollTop = (element.scrollHeight - element.clientHeight) * progress + scrollTop
        }
      })
      setTimeout(() => { this.isScrollAnimationEnded = true }, time)
    }
  }

  handleUpdateRecord({ volume, time }: { volume: number, time: number }) {
    this.recordVolume = volume
    this.recordTime = time
  }

  handleKeydown(event: KeyboardEvent) {
    const isFocus = document.querySelector('#chat-input:focus')
    const isEnter = event.which === 13 || event.key === 'Enter'
    const isEscape = event.which === 27 || event.key === 'Escape'
    const textarea = this.$refs.textarea as HTMLElement

    if (isEnter && !isFocus) {
      textarea && textarea.focus()
      event.preventDefault()
    } else if (isEscape) {
      textarea && textarea.blur()

      if (this.imageModal.view) {
        this.imageModal.view = false
      } else {
        this.cancelRecord()
      }

      if (this.editMessage) {
        this.editMessage = null
        this.message = this.beforeEditText
      }
    }
  }

  preventDefaults(event: any) {
    event.preventDefault()
    event.stopPropagation()
  }

  handleResize() {
    this.adaptiveChatHeight()
  }

  adaptiveChatHeight() {
    const height = window.innerHeight
    const chat = this.$refs.chat as HTMLElement

    if (chat) {
      const offsetHeight = this.$vuetify.breakpoint.mdAndUp ? 220 : 170
      chat.style.height = (height - offsetHeight) + 'px'
    }
  }

  handlerDragEnter(event: DragEvent) {
    const info = event.dataTransfer?.items

    if (info) {
      const hasFiles = Array.from(info).some(item => item.kind === 'file')
      const hasAccessFile = Array.from(info).some(item => accessTypes.includes(item.type))

      if (!hasFiles || !hasAccessFile) {
        return
      }
    }

    this.isDropFocus = true

    this.hideDropWrapper = false
    clearTimeout(this.timeToUnfocusDrop)

    this.$nextTick(() => {
      this.dropAmout++

      this.isDropFocus = true
    })
  }

  handlerDragLeave() {
    this.dropAmout--

    if (this.dropAmout === 0) {
      this.isDropFocus = false

      this.timeToUnfocusDrop = setTimeout(() => {
        this.hideDropWrapper = true
      }, 1000)
    }
  }

  onReplyClick(message: {id: number; text: string;}): void {
    this.sendChatMessage({ text: message.text, replyOptionId: message.id })
  }

  async handlerDrop(event: DragEvent) {
    this.isDropFocus = false
    this.dropAmout = 0

    this.timeToUnfocusDrop = setTimeout(() => {
      this.hideDropWrapper = true
    }, 1000)

    const { dataTransfer } = event
    const dropFiles = dataTransfer?.files

    if (dropFiles) {
      if (this.isRecordView) {
        this.cancelRecord()
      }

      const files = await parseFiles(dropFiles)

      this.filesToUpload = this.filesToUpload.concat(files)
    }
  }

  async addAudioFile(blob: Blob) {
    const name = this.fileRecordName
    const buffer = await new Response(blob).arrayBuffer() || []

    this.filesToUpload.push({
      buffer,
      timeOfRead: new Date().getTime(),
      file: new File([blob], name),
      type: blob.type,
      name,
      loading: false
    })
  }

  async uploadFiles(event: EventLayout<HTMLInputElement>): Promise<void> {
    try {
      this.isLoadingMessage = true

      if (event.target.files) {
        const files = await parseFiles(event.target.files)

        this.filesToUpload = this.filesToUpload.concat(files)
      }

      event.target.value = ''
    } catch (error) {
      this.$handleApiError(error)
    } finally {
      this.isLoadingMessage = false
    }
  }

  async handleInputKeydown(event: KeyboardEvent): Promise<void> {
    if (this.isLoadingMessage) {
      return
    }

    try {
      const isEnter = event.which === 13 || event.key === 'Enter'

      if (event.ctrlKey && isEnter) {
        event.stopPropagation()
        event.preventDefault()

        await this.sendMessage()
      }
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async sendMessage(): Promise<void> {
    try {
      this.isLoadingMessage = true

      if (this.message.length || this.filesToUpload[0]) {
        let fileName = ''

        if (this.filesToUpload[0]) {
          const { file } = this.filesToUpload[0]
          this.filesToUpload[0].loading = true
          try {
            const info = await UserModule.uploadFile(file)
            if (!info?.items?.[0]?.success) {
              this.filesToUpload.shift()
              this.$handleApiError({ message: info?.items?.[0]?.message } as Error, this)
              return
            }
            fileName = info.items[0].name || info.items[0].originalName
          } catch (error) {
            if (error.message === 'ignoreError') {
              this.filesToUpload.shift()
              this.$handleApiError({ message: 'Не удалось загрузить файл. Превышен лимит ожидания запроса' } as Error, this)
              return
            }
          }
        }

        const message = {
          fileName,
          text: this.message || ''
        }

        if (this.editMessage) {
          message.fileName = this.editMessage.fileName || ''
          message.text = this.message || ''

          this.sendEditedMessage(this.editMessage.id, message)
          this.editMessage = null
        } else {
          this.sendChatMessage(message)
          if (this.filesToUpload[0]) {
            this.filesToUpload.shift()
            if (this.filesToUpload[0]) {
              this.message = ''
              this.filesToUpload[0].loading = true
              setTimeout(() => {
                this.sendMessage()
              }, 1000)
            }
          }
        }

        this.message = ''
        this.scrollToBottom()
      }
    } catch (error) {
      this.$handleApiError(error, this)
      this.filesToUpload = this.filesToUpload.map((file) => {
        file.loading = false
        return file
      })
    } finally {
      this.isLoadingMessage = false
      this.cancelRecord()
    }
  }

  async loadMessages(entries: Array<IntersectionObserverEntry>): Promise<void> {
    if (entries[0].isIntersecting && this.isShowLoadMoreBlock) {
      const wrapper = this.$refs.messages as Element
      const lastMessage = (this.$refs[`message-${this.chat.items[0].id}`] as any)?.[0]?.$el

      this.isLoadingMessages = true
      this.isNeedScrollToBottom = false

      await this.getChat(this.chat.nextRow, true)
      await this.$nextTick()

      wrapper.scrollTop = lastMessage.offsetTop - 60

      this.isLoadingMessages = false
      this.isNeedScrollToBottom = true
    }
  }

  async setChatMessagesViewed(): Promise<void> {
    await $put('/exploitation/chat-messages/set-viewed-all')
  }

  async getChat(fromRow: number, loadMore:boolean = false): Promise<void> {
    const { data } = await $get<IChat>('/exploitation/chat-messages', { params: { fromRow } })
    if (!loadMore) { this.chat = data } else {
      this.chat.items.unshift(...data.items)
      this.chat.nextRow = data.nextRow
    }
  }

  async sendEditedMessage(id: number, message: IPostMessageQuery): Promise<void> {
    const { data } = await $put(`/exploitation/chat-messages/${id}`, { ...message })

    this.chat.items = data.items
  }

  async sendChatMessage(message: Record<string, string | number>):Promise<void> {
    const { data } = await $post<IMessage>('/exploitation/chat-messages', { ...message })
    this.chat.items.push(data)
  }

  async deleteChatMessage(id: number): Promise<void> {
    await $remove<IMessage>(`/exploitation/chat-messages/${id}`)
    this.chat.items = this.chat.items.filter(item => item.id !== id)
  }

  @Watch('_chatCounter')
  onChatCounterChange():void {
    this.getChat(0)
  }

  @HandleApiRequest()
  async mounted() {
    this.isLoadingMessages = true
    await this.getChat(0)
    await this.setChatMessagesViewed()

    document.documentElement.addEventListener('keydown', this.handleKeydown)
    window.addEventListener('resize', this.handleResize)

    this.isLoadingMessages = false

    setTimeout(() => {
      this.scrollToBottom(0)

      const dropArea = this.$refs.chat as HTMLElement

      if (dropArea) {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
          dropArea.addEventListener(eventName, this.preventDefaults)
        })

        dropArea.addEventListener('dragenter', this.handlerDragEnter)
        dropArea.addEventListener('dragleave', this.handlerDragLeave)
        dropArea.addEventListener('drop', this.handlerDrop)
      }

      this.adaptiveChatHeight()
      this.$nextTick().then(() => { this.isInited = true })
    }, 0)
  }

  unmounted() {
    document.documentElement.removeEventListener('keydown', this.handleKeydown)
    window.removeEventListener('resize', this.handleResize)

    const dropArea = this.$refs.chat as HTMLElement

    if (dropArea) {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.removeEventListener(eventName, this.preventDefaults)
      })

      dropArea.removeEventListener('dragenter', this.handlerDragEnter)
      dropArea.removeEventListener('dragleave', this.handlerDragLeave)
      dropArea.removeEventListener('drop', this.handlerDrop)
    }
  }
}
