
import { Component, Vue } from 'vue-property-decorator'

import AdvertPageLayout from '@/components/page/adverts/AdvertPageLayout.vue'

// eslint-disable-next-line no-unused-vars
import { IAdvertLayout } from '@/model/page/adverts'

@Component({
  components: {
    AdvertPageLayout
  }
})
export default class AdvertsPage extends Vue {}
