import { Vue } from 'vue-property-decorator'
import { debounce } from 'lodash'

/**
 * @description Debounce для метода класса
 * @param time
 * @param options
 * @constructor
 */
export function Debounce(
  time = 500,
  options?: {
    leading?: boolean;
    maxWait?: number;
    trailing?: boolean;
  }
): MethodDecorator {
  // eslint-disable-next-line
  const map = new Map<number, (...args: any[]) => any>()

  return function(_target, _propertyKey, descriptor: PropertyDescriptor) {
    const method = descriptor.value as (...args: any[]) => any

    descriptor.value = function(this: Vue, ...args: any[]) {
      const _uid = (this as any)._uid

      if (map.has(_uid)) {
        return map.get(_uid)!(...args)
      }

      const deb = debounce(method.bind(this), time, options)
      map.set(_uid, deb)
      return deb(...args)
    }
  }
}
