
import { Component, Vue } from 'vue-property-decorator'
import AppPlaceLayout from '@/components/general/AppPlaceLayout.vue'
import AppPlaceButtonPay from '@/components/general/AppPlaceButtonPay.vue'

@Component({
  components: {
    AppPlaceLayout,
    AppPlaceButtonPay
  }
})
export default class InfoPlacesPage extends Vue {}
