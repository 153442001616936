
import { Component, Vue } from 'vue-property-decorator'
import { CounterModule } from '@/store/counter'

import SummaryParser from '@/components/general/SummaryParser.vue'
import ButtonParser from '@/components/general/ButtonParser.vue'

@Component({
  components: {
    SummaryParser,
    ButtonParser
  }
})
export default class HistoryPage extends Vue {
  isLoading = false
  info = {}

  async cancelEventid() {
    try {
      const id = this.$route.params.id
      await CounterModule.cancelEvent(id)
      this.$router.go(0)
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async mounted() {
    try {
      this.isLoading = false
      const { id } = this.$route.params
      this.info = await CounterModule.getEvent(id)
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}
