
import { Component, Vue } from 'vue-property-decorator'

import { UserModule } from '@/store/user'

import PeopleIdLayout from '@/components/general/PeopleIdLayout.vue'
import ThemeButton from '@/components/general/ThemeButton.vue'
import { HandleApiRequest } from '@/utils/helper'

@Component({
  components: {
    PeopleIdLayout,
    ThemeButton
  }
})
export default class PersonalPage extends Vue {
  isLoading = true

  get user() {
    return UserModule.user
  }

  @HandleApiRequest()
  async mounted() {
    await UserModule.getUserProfile()
  }
}
