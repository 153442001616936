
import { Vue, Component, Prop } from 'vue-property-decorator'
import ResizeObserver from 'resize-observer-polyfill'
import { Debounce } from '@/utils/decorators'
// eslint-disable-next-line no-unused-vars
import { ILoyaltyPartnerOffer } from '@/model/page/loyalty'

@Component
export default class LoyaltyPartnerOfferCard extends Vue {
  @Prop({ required: true }) offer!: ILoyaltyPartnerOffer

  offerDescription = null as unknown as Element
  isOfferDescriptionExpanded = false
  offerDescriptionDefaultHeight: string | number = 'auto'
  offerDescriptionHeight = 0
  resizeObserver: ResizeObserver | null = null;

  mounted() {
    this.resizeObserver = new ResizeObserver((entries, observer) => {
      this.calcOfferTextScale()
    })

    setTimeout(() => {
      this.offerDescription = this.$refs.offerDescription as Element
      this.calcOfferTextScale()
      this.resizeObserver!.observe(this.offerDescription)
    })
  }

  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }

  @Debounce(350)
  async calcOfferTextScale() {
    this.offerDescriptionHeight = 0
    await this.$nextTick()
    this.offerDescriptionHeight = this.offerDescription?.getBoundingClientRect().height
    this.offerDescriptionDefaultHeight = this.offerDescriptionHeight < 80 ? this.offerDescriptionHeight : 80
  }
}
