
import { Component, Vue } from 'vue-property-decorator'
import { HandleApiRequest } from '@/utils/helper'

import { InfoModule } from '@/store/info'
import { PageModule } from '@/store/page'

@Component
export default class InfoPage extends Vue {
  isLoading = true

  routes = {
    1: '/info/building',
    2: '/info/cameras',
    5: '/info/offices',
    10000: 'https://forum-level.ru/',
    9: 'https://levelgroup.idacloud.ru/client/',
    4: '/info/faq',
    3: '/info/about',
    6: '/info/adverts',
    7: '/info/contacts',
    10: '/info/chat'
  }

  get home() {
    return this.info?.cards?.filter(({ screenId }) => screenId === 1)[0]
  }

  get adverts() {
    return this.info?.cards?.find(({ screenId }) => screenId === 6)
  }

  get cards() {
    return this.info?.cards?.filter(({ screenId }) => ![3, 6].includes(screenId))
  }

  get info() {
    return InfoModule.info
  }

  get selectedPlace() {
    return PageModule.selectedPlace
  }

  @HandleApiRequest()
  async mounted() {
    await InfoModule.getInfo()
  }
}
