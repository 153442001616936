
import { Component, Vue } from 'vue-property-decorator'
import { AdvertModule } from '@/store/advert'

import AdvertLayout from '@/components/page/adverts/AdvertLayout.vue'

@Component({
  components: {
    AdvertLayout
  }
})
export default class AdvertsIdPage extends Vue {
  isLoading = false

  get advert() {
    return AdvertModule.advert
  }

  async mounted() {
    try {
      this.isLoading = true
      const { id } = this.$route.params
      await AdvertModule.getAdvert(id)
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}
