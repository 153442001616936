
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { AdvertModule } from '@/store/advert'
import { PageModule } from '@/store/page'

import FormParser from '@/components/general/FormParser.vue'

// eslint-disable-next-line no-unused-vars
import { ComputedServiceForm, IFormMinimalElement } from '@/model/page/service'
// eslint-disable-next-line no-unused-vars
import { ICost, IAdvertFull } from '@/model/page/adverts'

@Component({
  components: {
    FormParser
  }
})
export default class AdvertModifyLayout extends Vue {
  @Prop({ default: false, type: Boolean }) edit!: boolean

  modalShow = false
  acceptRules = false
  isLoading = false
  cost: ICost | null = null
  form: IFormMinimalElement[] = []

  get advert() {
    return this.edit ? AdvertModule.advert : null
  }

  get categoryId() {
    return (this.edit ? this.advert?.advertCategoryId : parseInt(this.$route.params.id)) || 1
  }

  get currentCategory() {
    return this.allCategories.find((category: any) => category.id === this.categoryId)
  }

  get titleHint() {
    return this.currentCategory?.titleHint
  }

  get smartHint() {
    return AdvertModule.smartHint
  }

  get hasPrice() {
    return this.currentCategory?.hasPrice
  }

  get requiredPhoto() {
    return this.currentCategory?.requiredPhoto
  }

  get requiredPrice() {
    return this.currentCategory?.requiredPrice
  }

  get places() {
    return PageModule.places
  }

  get selectedPlace() {
    return PageModule.selectedPlace
  }

  get category() {
    return AdvertModule.category
  }

  get allCategories() {
    return AdvertModule.allCategories
  }

  get reachType() {
    return AdvertModule.reachType
  }

  get agreement() {
    return AdvertModule.agreement
  }

  get advertReachTypeId() {
    const reachType = this.form.find(({ id }) => id === 'advertReachTypeId')
    const place = this.form.find(({ id }) => id === 'place')

    return `${reachType?.value} - ${place?.value?.placeId} - ${place?.value?.buildingId}`
  }

  @Watch('advertReachTypeId')
  handleAdvertReachTypeId() {
    let options = this.advertReachTypeId.split('-')
    // @ts-ignore
    options[0] = this.reachType.find((type) => type.title === options[0].trim()).id
    // @ts-ignore
    options = options.map(n => parseInt(n) || undefined)

    // @ts-ignore
    this.updateCost(...options)
  }

  async mounted() {
    try {
      this.isLoading = true
      if (this.edit) {
        const { id } = this.$route.params
        await AdvertModule.getAdvert(id)
      } else {
        if (!this.reachType.length) {
          await AdvertModule.getReachType()
        }
        if (!this.agreement) {
          await AdvertModule.getPlacement()
        }

        await AdvertModule.getSmartHint(this.categoryId || '')

        AdvertModule.SET_STATE_ADVERT({ key: 'advert', value: null })
      }

      if (!this.category.length) {
        await AdvertModule.getCategory()
      }
    } catch (error) {
      this.$handleApiError(error, this)
    } finally {
      this.isLoading = false
    }

    const imagesValues = this.advert ? this.advert.images.map(({ url: src, name: value }) => {
      return {
        loading: false,
        src,
        value
      }
    }) : []

    const form: IFormMinimalElement[] = [
      {
        id: 'title',
        placeholder: 'Название объявления',
        required: true,
        title: 'Название объявления',
        type: 'smarthint',
        length: 30,
        value: this.advert?.title || '',
        titleHint: this.titleHint,
        hints: this.smartHint
      },
      {
        id: 'description',
        placeholder: 'Описание',
        required: true,
        title: 'Описание объявления',
        type: 'text',
        length: 5000,
        value: this.advert?.description || ''
      },
      {
        id: 'images',
        required: this.requiredPhoto,
        title: 'Изображения',
        type: 'image',
        value: '',
        values: imagesValues
      }
    ]

    const placesItems = this.places.map(({ address, buildingId, id: placeId }) => {
      return {
        title: address,
        id: placeId,
        value: { buildingId, placeId }
      }
    })

    if (this.hasPrice) {
      form.splice(2, 0, {
        id: 'price',
        placeholder: 'Цена (₽)',
        required: this.requiredPrice,
        title: 'Цена',
        type: 'string',
        typeInput: 'number',
        value: this.advert?.price || ''
      })
    }

    if (!this.edit) {
      const reachType = this.reachType.map(({ title, id }, i) => Object({ name: title, value: id, id: 'ReachType' + i }))

      form.push(
        {
          id: 'place',
          placeholder: 'Помещение',
          required: false,
          title: 'Помещение',
          type: 'select',
          value: placesItems.find(item => item.id === this.selectedPlace?.id)?.value,
          values: placesItems
        },
        {
          id: 'advertReachTypeId',
          required: true,
          title: 'Охват',
          type: 'radio',
          value: reachType[0]?.value || 0,
          values: reachType
        }
      )
    }

    this.form = form
  }

  async updateCost(reachType?: number, placeId?: number, buildingId?: number) {
    if (!buildingId) {
      buildingId = this.places[0].buildingId
    }
    if (!reachType) {
      reachType = this.reachType[0].id
    }
    if (!placeId) {
      placeId = this.places[0].id
    }

    const options = {
      reachType,
      category: this.categoryId,
      placeId,
      buildingId
    } as Record<'category' | 'reachType' | 'placeId' | 'buildingId', number>

    this.cost = await AdvertModule.getCost(options)
  }

  async handleForm(form: ComputedServiceForm) {
    try {
      let formatForm: any = form.reduce((object: any, input) => {
        object[input.id] = input.value
        return object
      }, {})

      if (this.edit) {
        const name = this.$route.name === 'advertsMyIdEdit' ? 'advertsMyId' : 'advertsId'

        formatForm.price = parseInt(formatForm.price)
        const { id } = this.$route.params

        await AdvertModule.updateAdvert({ form: formatForm, id })

        this.$router.push({ name, params: { id } })
      } else {
        formatForm.advertCategoryId = this.$route.params.id

        formatForm = { ...formatForm, ...formatForm.place }

        delete formatForm.place
        formatForm.price = parseInt(formatForm.price)

        await AdvertModule.submitAdvert(formatForm)
        const id = AdvertModule.advert?.id + ''

        if (id) {
          this.$router.push({ name: 'advertsMyId', params: { id } })
        } else {
          this.$router.push({ name: 'advertsMy' })
        }
      }
    } catch (error) {
      this.$handleApiError(error)
    }
  }
}
