import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'
import store from '@/store'
import { PageModule } from '@/store/page'
import { $get, $post, $patch } from '@/plugins/axios'

import {
  ApiItemsLayout,
  ApiPlaceholder,
  SubmitFormOptions
} from '@/model/index'

import {
  INews,
  IPolls,
  INewsFeed,
  ICommunicationsItem,
  ICommunicationsFull,
  ICustomerNotification, INewsFeedType
} from '@/model/page/newsFeed'

export interface NewsFeedState {
  newsFeed: Array<INewsFeed>
  nextRow: number
  isAvailable: boolean
  placeholder: ApiPlaceholder
  newsFeedTypes: Array<{ id: number, title: string }>
  selectedNewsFeedTypeId: number
  newsId: INews
  pollsId: IPolls
}

@Module({ dynamic: true, store, name: 'newsFeed' })
class NewsFeed extends VuexModule implements NewsFeedState {
  newsFeed = [] as Array<INewsFeed>
  nextRow = 0
  isAvailable = false
  placeholder = {} as ApiPlaceholder
  communications = [] as Array<ICommunicationsItem>
  newsFeedTypes = [] as INewsFeedType[];

  selectedNewsFeedTypeId = 0
  newsId = {} as INews
  pollsId = {} as IPolls

  @Mutation
  SET_STATE_NEWSFEED<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Mutation
  PUSH_NEWSFEED(items: Array<INewsFeed>) {
    this.newsFeed = this.newsFeed.concat(items)
  }

  @Mutation
  PUSH_TO_COMMUNICATIONS(items: Array<ICommunicationsItem>) {
    this.communications = this.communications.concat(items)
  }

  get isMoreExist() {
    return this.nextRow > 0
  }

  get newsFeedBadge() {
    return this.newsFeed.some(item => !item.isViewed)
  }

  get communicationsBadge() {
    return this.communications.some(item => !item.isViewed)
  }

  @Action
  async getNewsFeed() {
    const { data } = await $get<ApiItemsLayout<INewsFeed>>('/newsfeed',
      { params: { fromRow: this.nextRow } }
    )
    this.PUSH_NEWSFEED(data.items)
    this.SET_STATE_NEWSFEED({ key: 'nextRow', value: data.nextRow })
  }

  @Action
  async getNewsFeedTypes() {
    const { data } = await $get('/sections/newsfeed')
    this.SET_STATE_NEWSFEED({ key: 'newsFeedTypes', value: data.newsFeedTypes })
  }

  @Action
  async getCommunications(fromRow: number) {
    const { data } = await $get<ApiItemsLayout<ICommunicationsItem>>('/communications',
      { params: { fromRow } }
    )

    this.PUSH_TO_COMMUNICATIONS(data.items)

    return data.nextRow
  }

  @Action
  async getCommunication(id: string | number) {
    const { data } = await $get<ICommunicationsFull>(`/communications/${id}`)

    return data
  }

  @Action
  async viewCommunication(id: string | number) {
    await $patch(`/communications/${id}`, { isViewed: true })
  }

  @Action
  async getNewsDetail(id: string | number) {
    this.SET_STATE_NEWSFEED({ key: 'newsId', value: {} as INews })
    const { data } = await $get<INews>(`news/${id}`)
    this.SET_STATE_NEWSFEED({ key: 'newsId', value: data })
  }

  @Action
  async getPollsDetail(id: number | string) {
    this.SET_STATE_NEWSFEED({ key: 'pollsId', value: {} as IPolls })
    const { data } = await $get<IPolls>(`polls/${id}`)
    this.SET_STATE_NEWSFEED({ key: 'pollsId', value: data })
  }

  @Action
  async viewPoll(pollId: number | string) {
    await $patch(`/polls/${pollId}`, { isViewed: true })
  }

  @Action
  async submitPoll({ id, formData }: SubmitFormOptions) {
    const data = await $post(`/polls/${id}/data`, { formData })
    return data
  }

  @Action
  async getNotification(id: number | string) {
    const { data } = await $get<ICustomerNotification>(`/customerNotifications/${id}`)

    return data
  }

  @Action
  async viewNotification(id: number | string) {
    await $patch(`/customerNotifications/${id}`, { isViewed: true })
  }

  @Action
  async getVote(id: number | string) {
    const { data } = await $get(`/votes/${id}`)

    return data
  }

  @Action
  async getMeeting(id: number | string) {
    const { data } = await $get(`/general-meeting/${id}`)

    return data
  }

  @Action
  async viewVote(id: number | string) {
    await $patch(`/votes/${id}`, { isViewed: true })
  }

  @Action
  async viewMeeting(id: number | string) {
    await $patch(`/general-meeting/${id}`, { isViewed: true })
  }

  @Action
  async submitVote({ id, formData }: SubmitFormOptions) {
    const { data, success } = await $post(`/votes/${id}/data`, { formData })
    if (data?.message) {
      PageModule.SEND_NOTIFICATION({ type: success ? 'success' : 'warning', message: data.message })
    }
  }

  @Action
  async submitMeeting({ id, formData }: SubmitFormOptions) {
    const { data, success } = await $post(`/general-meeting/${id}/data`, { formData })
    if (data?.message) {
      PageModule.SEND_NOTIFICATION({ type: success ? 'success' : 'warning', message: data.message })
    }
  }
}

export const NewsFeedModule = getModule(NewsFeed)
