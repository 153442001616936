
import { Vue, Component, Watch } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { CreateElement, VNode } from 'vue/types'

let start = 0
const timeEndAnimation = 1500
const rotatePerFrame = 6

function circ(timeFraction: number) {
  return 1 - Math.pow(timeFraction, 2)
}

@Component
export default class AppReload extends Vue {
  height = 90
  isLoading = false
  preLoading = ''
  rotate = 0
  clickInfo: number[] = []
  maxOffset = 250
  progress = 0

  @Watch('progress')
  change(value: number) {
    if (value === 100) {
      this.final()
    } else {
      this.rotate = value * value / 50
    }
  }

  final() {
    this.isLoading = true
    this.rotateFunciton()

    setTimeout(() => {
      this.preLoading = 'finish'
      start = performance.now()
    }, 2500)

    setTimeout(() => {
      this.isLoading = false
      this.preLoading = ''
      this.$emit('close')
      this.progress = 0
    }, 2500 + timeEndAnimation)
  }

  rotateFunciton() {
    const self = this
    requestAnimationFrame(function animate(time) {
      if (self.preLoading === 'finish') {
        let timeFraction = (time - start) / timeEndAnimation

        if (timeFraction > 1) timeFraction = 1

        const progress = circ(timeFraction)

        self.rotate += rotatePerFrame * progress
      } else {
        self.rotate += rotatePerFrame
      }

      if (self.isLoading) {
        requestAnimationFrame(animate)
      }
    })
  }

  initCustomReload() {
    const body = this.$refs.body as HTMLElement

    if (body) {
      body.addEventListener('mousedown', this.mouseStart)
      body.addEventListener('mousemove', this.mouseMove)
      body.addEventListener('mouseup', this.mouseEnd)

      body.addEventListener('touchstart', this.touchStart, { passive: true })
      body.addEventListener('touchmove', this.touchMove, { passive: true })
      body.addEventListener('touchend', this.touchEnd, { passive: true })
    }
  }

  touchStart(event: TouchEvent) {
    event.stopPropagation()
    event.preventDefault()

    if (this.progress !== 100) {
      this.progress = 0
    }

    this.clickInfo = [event.changedTouches[0].clientX, event.changedTouches[0].clientY]
  }

  touchMove(event: TouchEvent) {
    if (!this.clickInfo.length) return

    event.stopPropagation()
    event.preventDefault()

    let percent = 0

    const shiftY = this.clickInfo[1] - event.changedTouches[0].clientY

    if (shiftY < 0) {
      percent = Math.abs(shiftY) / this.maxOffset
      percent = percent > 1 ? 100 : percent * 100
      this.progress = percent
    }
  }

  touchEnd(event: TouchEvent) {
    event.stopPropagation()
    event.preventDefault()

    if (this.progress !== 100) {
      this.progress = 0
    }

    this.clickInfo = []
  }

  mouseStart(event: MouseEvent) {
    event.stopPropagation()
    if (this.progress !== 100) {
      this.progress = 0
    }

    this.clickInfo = [event.clientX, event.clientY]
  }

  mouseMove(event: MouseEvent) {
    if (!this.clickInfo.length) return

    event.stopPropagation()
    let percent = 0

    const shiftY = this.clickInfo[1] - event.clientY

    if (shiftY < 0) {
      percent = Math.abs(shiftY) / this.maxOffset
      percent = percent > 1 ? 100 : percent * 100
      this.progress = percent
    }
  }

  mouseEnd(event: MouseEvent) {
    event.stopPropagation()

    if (this.progress !== 100) {
      this.progress = 0
    }

    this.clickInfo = []
  }
}
