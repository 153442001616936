
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ButtonParser extends Vue {
  @Prop({ type: Object }) info!: any
  @Prop({ type: String }) type!: string
  @Prop({ type: Function }) callbackCancel!: Function
  @Prop({ type: Function }) callbackReopen!: Function
  @Prop({ type: Function }) callbackRefund!: Function
  @Prop({ type: Function }) callbackDelete!: Function
  @Prop({ type: Number }) cols?: Number

  get buttons() {
    const buttons: Record<string, any> = []

    if (this.info.hasCancelButton) {
      buttons.push({
        title: 'Отменить',
        color: 'normal',
        callback: this.callbackCancel
      })
    }

    if (this.info.hasPaymentButton) {
      buttons.push({
        title: 'Оплатить ' + this.info.paymentTitle,
        href: this.info.paymentContext ? `/pay/init?c=${this.info.paymentContext}` : this.info.paymentButtonLink,
        target: '_blank',
        callback: this.emptyFunction
      })
    }

    if (this.info.hasReopenButton) {
      buttons.push({
        title: 'Переоткрыть',
        color: 'normal',
        callback: this.callbackReopen
      })
    }

    if (this.info.isRefundAllowed && this.info.hasRefundButton) {
      buttons.push({
        title: 'Вернуть оплату',
        color: 'normal',
        callback: this.callbackRefund
      })
    }

    const { id } = this.$route.params
    const scopeTypeId = this.$route.query.scopeTypeId as string

    if (this.info.hasChatButton) {
      buttons.push({
        title: 'Сообщения',
        to: `/places/orders/${id}/chat?scopeTypeId=${parseInt(scopeTypeId)}`,
        badge: this.info.chatButtonBadge,
        callback: this.emptyFunction
      })
    }

    if (this.info.orderStatusId === 8) {
      buttons.push(
        {
          title: 'Вернуться к оформлению заявки',
          to: `/services/${this.info.serviceId}?scopeTypeId=2&orderId=${this.info.id}`
        },
        {
          title: 'Удалить черновик',
          callback: this.callbackDelete
        }
      )
    }

    return buttons
  }

  emptyFunction() {}
}
