
import { Component, Vue } from 'vue-property-decorator'

import PlaceMobileLayout from '@/components/page/place/PlaceMobileLayout.vue'
import PlaceDesktopLayout from '@/components/page/place/PlaceDesktopLayout.vue'

@Component({
  components: {
    PlaceMobileLayout,
    PlaceDesktopLayout
  }
})
export default class PlacesPage extends Vue {
  created() {
    const { type } = this.$route.query
    if (type) {
      localStorage.setItem('placesActiveMenu', 'PassesPage')
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$router.push(`/places/passes?type=${type}`)
      }
    }
  }
}
