
import { Component, Vue } from 'vue-property-decorator'
import { AdvertModule } from '@/store/advert'

import AdvertBlock from '@/components/page/adverts/AdvertBlock.vue'

@Component({
  components: {
    AdvertBlock
  }
})
export default class AdvertsAddPage extends Vue {
  isLoading = false
  categoryModel = [{}]

  get category() {
    return AdvertModule.category
  }

  get selectedCategory(): any {
    return this.categoryModel[this.categoryModel.length - 1]
  }

  get isAddBtnDisabled(): boolean {
    return Boolean(!this.selectedCategory.id || (this.selectedCategory.items && this.selectedCategory.items.length))
  }

  async mounted() {
    try {
      this.isLoading = true
      await AdvertModule.getCategory()
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}
