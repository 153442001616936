
import { Component, Vue } from 'vue-property-decorator'
import { InfoModule } from '@/store/info'

@Component
export default class ForumPage extends Vue {
  isLoading = false

  get forum() {
    return InfoModule.forum
  }

  async mounted() {
    try {
      this.isLoading = true
      await InfoModule.getForum()
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error)
    }
  }
}
