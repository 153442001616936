
import { Component, Vue } from 'vue-property-decorator'

import { PageModule } from '@/store/page'

@Component
export default class PersonalPage extends Vue {
  isLoading = false

  get isDark() { return PageModule.isDark }

  changeTheme() {
    PageModule.changeTheme({ theme: this.isDark ? 'light' : 'dark', self: this })
  }
}
