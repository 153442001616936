
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IImageFormObject } from '@/model/page/service'

@Component
export default class SummaryParser extends Vue {
  @Prop({ type: Array }) summary!: any
  @Prop({ type: Boolean }) isPayable!: boolean

  imageModal = {
    view: false,
    src: ''
  }

  openModalImage(image: string) {
    this.imageModal.view = true
    this.imageModal.src = image
  }

  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 10,
    mousewheel: true
  }
}
