
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { PageModule } from '@/store/page'

import ThemeButton from '@/components/general/ThemeButton.vue'

// eslint-disable-next-line no-unused-vars
import { IPlace } from '@/model/index'

@Component({
  components: {
    ThemeButton
  }
})
export default class AppDrawer extends Vue {
  @Prop({ default: false }) readonly expandOnHover!: boolean
  miniVariant = false

  get menuItems() {
    return PageModule.menuItems
  }

  get places() {
    return PageModule.places
  }

  get selectedPlace() {
    return PageModule.selectedPlace
  }

  async setSelectedPlace(place: IPlace) {
    PageModule.SET_SELECTED_PLACE(place)

    await PageModule.getSections()

    if (this.$route.path.split('/').filter(Boolean).length !== 1) {
      this.$router.push({ name: 'services' })
    }

    await PageModule.getAppLinks()
  }

  @Watch('$vuetify.breakpoint.smAndDown')
  onBreakPointChange(val : boolean) {
    this.$emit('update:expandOnHover', !val)
  }
}
