
import { Component, Vue, Prop } from 'vue-property-decorator'

import { InvoicesModule } from '@/store/invoices'

import TabRequisites from '@/components/page/invoices/tabs/TabRequisites.vue'
import TabCosts from '@/components/page/invoices/tabs/TabCosts.vue'

// eslint-disable-next-line no-unused-vars
import { IInvoicesItem } from '@/model/page/invoices'

@Component({
  components: {
    TabRequisites,
    TabCosts
  }
})
export default class InvoicesDetail extends Vue {
  @Prop({ type: Boolean, default: false }) addCalendar!: boolean

  tabsModel = 0

  get invoice() {
    return InvoicesModule.invoiceDetail
  }

  get tabs(): Array<{ title: string, component: string }> {
    const tabs:Array<{ title: string, component: string }> = []

    tabs.push({ title: 'Реквизиты', component: 'TabRequisites' })
    tabs.push({ title: 'Тарифы', component: 'TabCosts' })

    return tabs
  }

  tabProps(component: string): Object {
    if (component === 'TabInvoices') {
      return { invoice: this.invoice }
    }
    return { invoiceId: this.invoice && this.invoice.id }
  }
}
