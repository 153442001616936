
import { Component, Vue, Prop } from 'vue-property-decorator'
import WaveformData from 'waveform-data'

// eslint-disable-next-line no-unused-vars
import { IMessage } from '@/model/page/chat'

type IMessageOptions = Array<{ title: string; callback: Function }>

const context = new (window.AudioContext || (window as any).webkitAudioContext)()
let sourceNode: any
let gainNode: any
let progressInterval: any

@Component
export default class AudioController extends Vue {
  @Prop({ type: ArrayBuffer }) file!: ArrayBuffer
  @Prop({ type: String, required: true }) name!: string
  @Prop({ type: String }) url!: string
  @Prop({ type: Boolean }) isLoading!: boolean

  paused = true
  loading = true
  hasError = false
  barWidth = 3

  currentTime = 0

  buffer: any = []
  pausedAt = 0
  startedAt = 0

  peaks = [] as number[]

  get innerLoading() {
    return this.isLoading || this.loading
  }

  get progress() {
    return Math.round(this.currentTime / this.buffer.duration * 100)
  }

  mounted() {
    try {
      const setBuffer = (buffer: AudioBuffer) => {
        this.buffer = buffer
        this.loading = false

        progressInterval = setInterval(() => {
          if (!this.paused) {
            this.currentTime += 0.1
          }

          if (this.progress >= 100) {
            this.paused = true
            this.currentTime = 0
          }
        }, 100)

        const options = {
          audio_buffer: buffer
        }

        WaveformData.createFromAudio(options, (error, waveform) => {
          if (error) {
            return
          }

          const width = ((this.$el as HTMLElement).offsetWidth || 100) / 4
          const resampledWaveform = waveform.resample({ width })

          const channel = resampledWaveform.channel(0)

          const array: number[] = []

          for (let i = 0; i < resampledWaveform.length; i++) {
            const min = channel.min_sample(i)
            const max = channel.max_sample(i)

            array.push(Math.abs(max) + Math.abs(min))
          }

          this.peaks = array.map(height => height / 256 * 50)
        })
      }

      if (this.url) {
        const request = new XMLHttpRequest()
        request.open('get', this.url, true)
        // request.responseType = 'arraybuffer'
        // request.setRequestHeader('sec-fetch-dest', 'audio')
        // request.setRequestHeader('sec-fetch-mode', 'no-cors')
        // sec-fetch-dest: audio

        request.onload = () => {
          context.decodeAudioData(request.response, (buffer) => {
            setBuffer(buffer)
          })
        }
        request.send()
        request.onerror = () => {
          this.hasError = true
        }
      } else if (this.file) {
        context.decodeAudioData(this.file, (buffer) => {
          setBuffer(buffer)
        })
      }
    } catch (error) {
      this.hasError = true
    }
  }

  changeTime(event: MouseEvent) {
    const { offsetX } = event
    const { offsetWidth } = event.target as HTMLElement
    const cursorSize = 4
    const percent = (offsetX + cursorSize) / offsetWidth
    const time = Math.floor(this.buffer.duration * percent)

    this.play(time)
  }

  play(time?: number) {
    this.disconnect()

    gainNode = context.createGain()
    sourceNode = context.createBufferSource()
    sourceNode.connect(gainNode)
    sourceNode.buffer = this.buffer
    this.paused = false
    gainNode.connect(context.destination)

    if (time !== undefined) {
      this.startedAt = Date.now() - (time * 1000)
      sourceNode.start(0, time)
      this.currentTime = time
    } else if (this.pausedAt) {
      this.startedAt = Date.now() - this.pausedAt
      sourceNode.start(0, this.pausedAt / 1000)
    } else {
      this.startedAt = Date.now()
      sourceNode.start(0)
    }
    gainNode.gain.setValueAtTime(0.5, context.currentTime)

    sourceNode.onended = () => {
      if (this.progress >= 100) {
        this.paused = true
        this.currentTime = 0
      }
    }
  }

  toggleAudio() {
    if (this.paused) this.play()
    else this.pause()
  }

  disconnect() {
    if (sourceNode) {
      sourceNode.disconnect()
    }
  }

  pause() {
    sourceNode.stop(0)
    this.pausedAt = Date.now() - this.startedAt
    this.paused = true
  }

  beforeDestroy() {
    this.disconnect()

    sourceNode = null
    gainNode = null
    clearInterval(progressInterval)
  }
}
