
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PlaceModule } from '@/store/place'
import { HandleApiRequest } from '@/utils/helper'

@Component
export default class SpecificationPage extends Vue {
  isLoading = true

  @Prop({ type: Boolean }) isLayout!: boolean

  get info() {
    return PlaceModule.info
  }

  @HandleApiRequest({ id: 'GETCurrentplaceInfo' })
  async mounted() {
    localStorage.setItem('placesActiveMenu', 'SpecificationPage')
    await PlaceModule.getPlaceInfo()
  }
}
