import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router.ts'
import store from '@/store/index.ts'

import vuetify from '@/plugins/vuetify'
import i18n from '@/i18n'

import '@/plugins/base'
import '@/plugins/components'
import '@/plugins/filter'
import '@/plugins/chartist'

import '@/plugins/axios'
import '@/plugins/swiper.js'

import 'intersection-observer'
import '@/plugins/polyfills'

import handler from './plugins/handler'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

if (!window.location.host.includes('localhost')) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [new VueIntegration({ Vue, attachProps: true })]
  })
}

Vue.config.productionTip = false

Vue.use(handler)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
