
import { Component, Vue } from 'vue-property-decorator'
import { $put } from '@/plugins/axios'
import { NewsFeedModule } from '@/store/newsFeed'

import NewsFeedCard from '@/components/page/newsFeed/NewsFeedCard.vue'

@Component({
  components: {
    NewsFeedCard
  }
})
export default class NewsFeed extends Vue {
  isLoading = false
  isMoreLoading = false
  nextRow = 0

  get isAvailable() {
    return NewsFeedModule.isAvailable || true
  }

  get isMoreExist() {
    return NewsFeedModule.isMoreExist
  }

  get newsFeed() {
    return NewsFeedModule.newsFeed
  }

  get newsFeedTypes() {
    return NewsFeedModule.newsFeedTypes
  }

  get selectedNewsFeedTypeId() {
    return NewsFeedModule.selectedNewsFeedTypeId
  }

  async sendReaction({ id, reactionId }: {id: number, reactionId: number}) : Promise<void> {
    try {
      const item = this.newsFeed.find(item => item.id === id)
      const { data } = await $put(`news/${id}/reaction/${reactionId}`)
      item!.reactions = data.reactions
    } catch (e) {
      this.$handleApiError(e, this)
    }
  }

  async getNewsFeed() {
    try {
      this.isMoreLoading = true
      await NewsFeedModule.getNewsFeed()
      this.isMoreLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async getNewsFeedTypes() {
    try {
      await NewsFeedModule.getNewsFeedTypes()
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async changeNewsFeedTypeId(id: number) {
    try {
      NewsFeedModule.SET_STATE_NEWSFEED({ key: 'newsFeed', value: [] })
      NewsFeedModule.SET_STATE_NEWSFEED({ key: 'selectedNewsFeedTypeId', value: id })
      NewsFeedModule.SET_STATE_NEWSFEED({ key: 'nextRow', value: 0 })
      await this.getNewsFeed()
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async getMoreNewsFeed(entries: Array<IntersectionObserverEntry>) {
    try {
      if (entries[0].isIntersecting) {
        await this.getNewsFeed()
      }
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async mounted() {
    try {
      NewsFeedModule.SET_STATE_NEWSFEED({ key: 'newsFeed', value: [] })
      NewsFeedModule.SET_STATE_NEWSFEED({ key: 'nextRow', value: 0 })
      this.isLoading = true
      await this.getNewsFeedTypes()
      await this.getNewsFeed()
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}
