
import { Component, Vue } from 'vue-property-decorator'

import PassLayout from '@/components/page/place/PassLayout.vue'

@Component({
  components: {
    PassLayout
  }
})
export default class PassesFormPage extends Vue {}
