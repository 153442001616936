import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'

import store from '@/store'
import { $get, $post } from '@/plugins/axios'
import {
  IBrandPage,
  IServiceFull,
  PostServiceForm,
  ApiSectionLayout,
  ServiceStructure,
  PostServicesIdAvalibleTime
} from '@/model/page/service'

import { SubmitFormOptions, GetAgreement } from '@/model/index'

export interface ServiceState {
  services: ServiceStructure
}
@Module({ dynamic: true, store, name: 'service' })
class Service extends VuexModule implements ServiceState {
  services = {
    items: {
      pinned: [],
      all: [],
      cards: []
    }
  } as unknown as ServiceStructure

  agreement = ''

  @Mutation
  SET_STATE_SERVICE<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Action
  async getService(id: string) {
    const { data } = await $get<IServiceFull>(`/services/${id}`)

    return data
  }

  @Action
  async getPass() {
    const { data } = await $get<IServiceFull>('/pass')

    return data
  }

  @Action
  async submitService({ id, formData, force }: SubmitFormOptions) {
    const placeId = parseInt(localStorage.getItem('placeId') || '')
    const buildingId = parseInt(localStorage.getItem('buildingId') || '')

    const body = {
      placeId,
      formData,
      buildingId
    }
    console.log({ id, formData, force }, 'sumbit')
    const { data } = await $post<PostServiceForm>(`/services/${id}/order`, force === 0 ? body : { formData: [...formData], force: force })

    return data
  }

  @Action
  async updateTimestamp({ id, date }: { id: string | number, date: number }) {
    const { data } = await $post<PostServicesIdAvalibleTime>(`/services/${id}/availableTime`, null, { params: { date } })

    return data.items
  }

  @Action
  async getBrandpage(id: number | string) {
    const { data } = await $get<IBrandPage>(`/brandpages/${id}`)

    return data
  }

  @Action
  async getServices(searchText: string | undefined = undefined) {
    const { data } = await $get<ServiceStructure>('/sections/services/', { params: { searchQuery: searchText } })

    this.SET_STATE_SERVICE({ key: 'services', value: data })
  }

  @Action
  async getServiceAgreement(id: number | string) {
    const { data } = await $get<GetAgreement>(`/services/${id}/agreement`)

    this.SET_STATE_SERVICE({ key: 'agreement', value: data.content })
  }
}

export const ServiceModule = getModule(Service)
