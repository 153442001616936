
import { Vue, Component } from 'vue-property-decorator'
import { PageModule } from '@/store/page'

import { NewsFeedModule } from '@/store/newsFeed'

// eslint-disable-next-line no-unused-vars
import { ICustomerNotification } from '@/model/page/newsFeed'
import { HandleApiRequest } from '@/utils/helper'

@Component
export default class CustomerNotificationsIdPage extends Vue {
  isLoading = false
  notification = {} as ICustomerNotification

  @HandleApiRequest()
  async mounted() {
    PageModule.SET_STATE_PAGE({
      key: 'routeReturnFunciton',
      value: (url: string) => {
        this.$router.push({ name: 'newsFeed' })
      }
    })

    const { id } = this.$route.params

    this.notification = await NewsFeedModule.getNotification(id)
    if (!this.notification.isViewed) {
      await NewsFeedModule.viewNotification(id)
    }
  }
}

