
import { Component, Vue } from 'vue-property-decorator'
// @ts-ignore
import { ImageCapture } from 'image-capture'

let setUp: any = false
let cameras: any = false

@Component
export default class FlashlightButton extends Vue {
  isActive = false
  show = 'mediaDevices' in navigator

  async mounted() {
    const devices = await navigator.mediaDevices.enumerateDevices()
    cameras = devices.filter((device) => device.kind === 'videoinput')

    if (cameras.length === 0) {
      this.show = false
    }
  }

  async setUpAccess() {
    try {
      const camera = cameras[cameras.length - 1]

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: camera.deviceId,
          facingMode: ['environment'],
          height: { ideal: 0 },
          width: { ideal: 0 }
        }
      })

      const track = stream.getVideoTracks()[0]

      // @ts-ignore
      const imageCapture = new ImageCapture(track)

      await imageCapture.getPhotoCapabilities()

      setUp = ((track: MediaStreamTrack) => {
        return (torch: boolean) => {
          this.isActive = torch
          if (!torch) {
            stream.getTracks().forEach((track) => {
              track.stop()
            })
          } else {
            this.setUpAccess()
          }
        }
      })(track)

      track.applyConstraints({
        advanced: [{ torch: true }] as any
      })

      this.isActive = true
    } catch (error) {
      this.$handleApiError(error)
    }
  }

  async toggleLight() {
    if (!setUp) {
      await this.setUpAccess()
    } else {
      setUp(!this.isActive)
    }
  }
}
