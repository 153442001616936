
import { Vue, Component, Prop } from 'vue-property-decorator'
import VueEasyLightbox from 'vue-easy-lightbox'

// eslint-disable-next-line no-unused-vars
import { IAdvertComplaint, IAdvertFull } from '@/model/page/adverts'
import { AdvertModule } from '@/store/advert'
import { PageModule } from '@/store/page'
import AppIcon from '@/components/app/AppIcon.vue'
import { $get, $post } from '@/plugins/axios'
import { IButtonApi } from '@/model/page/autopay'

@Component({
  components: {
    AppIcon,
    VueEasyLightbox
  }
})
export default class AdvertLayout extends Vue {
  @Prop({ required: true }) info!: IAdvertFull

  isShowPhoneNumber = false
  swiperIndex = 0
  complaintModal = false;
  complaintsTypes = [];
  complaint: IAdvertComplaint = this.getComplaintPrototype();

  swiperOption = {
    slidesPerView: 1,
    spaceBetween: 0,
    watchOverflow: true,
    navigation: {
      nextEl: '.swiper-arrow--next',
      prevEl: '.swiper-arrow--prev'
    },
    pagination: {
      el: '.swiper-pagination'
    }
  }

  lightbox = {
    visible: false,
    index: 0
  }

  get isMy() {
    return this.$route.name === 'advertsMyId'
  }

  get complaintButton(): IButtonApi | boolean {
    return this.info.buttons.find((button) => button.name === 'complain') || false
  }

  get actions() {
    const action: any = {
      pay: {
        icon: 'ruble-inner',
        callback: () => {
          window.open(this.info.paymentContext ? `/pay/init?c=${this.info.paymentContext}` : this.info.paymentButtonLink)
        }
      },
      edit: {
        icon: 'edit',
        callback: () => {
          const name = `adverts${this.$route.name === 'advertsMyId' ? 'My' : ''}IdEdit`

          this.$router.push({ name, params: { id: this.$route.params.id } })
        }
      },
      delete: {
        icon: 'delete',
        callback: async() => {
          try {
            await AdvertModule.deleteAdvert(this.$route.params.id)

            setTimeout(() => {
              if (PageModule.routePast) {
                this.$router.go(-1)
              } else {
                this.$router.push({ name: 'advertsMy' })
              }
            }, 100)
          } catch (error) {
            this.$handleApiError(error)
          }
        }
      },
      publish: {
        icon: 'mdi-share',
        callback: async() => {
          try {
            await AdvertModule.publishAdvert(this.$route.params.id)

            setTimeout(() => {
              this.$router.go(0)
            }, 100)
          } catch (error) {
            this.$handleApiError(error)
          }
        }
      },
      unpublish: {
        icon: 'unpublish',
        callback: async() => {
          try {
            await AdvertModule.unpublishAdvert(this.$route.params.id)

            setTimeout(() => {
              this.$router.go(0)
            }, 100)
          } catch (error) {
            this.$handleApiError(error)
          }
        }
      },
      republish: {
        icon: 'mdi-share',
        callback: async() => {
          try {
            await AdvertModule.republishAdvert(this.$route.params.id)

            setTimeout(() => {
              this.$router.go(0)
            }, 100)
          } catch (error) {
            this.$handleApiError(error)
          }
        }
      }
    }

    return this.info.actions.map(({ name, title }) => {
      return {
        name,
        title,
        icon: action[name] ? action[name].icon : '',
        callback: action[name] ? action[name].callback : () => {}
      }
    })
  }

  getComplaintPrototype(): IAdvertComplaint {
    return {
      complainTypeId: null,
      message: ''
    }
  }

  async createComplaint(): Promise<void> {
    try {
      await $post(`/ad-board/${this.info.id}/complain`, this.complaint)

      this.complaint = this.getComplaintPrototype()
      this.complaintModal = false
    } catch (error) {
      this.$handleApiError(error)
    }
  }

  handleClickSlide(index: number): void {
    this.lightbox.index = index
    this.lightbox.visible = true
  }

  handleChangeSlide(): void {
    this.swiperIndex = (this.$refs.swiper as any).$swiper.activeIndex
  }

  showPhoneNumber(): void {
    setTimeout(() => { this.isShowPhoneNumber = true }, 50)
  }

  get complaintMessageRules(): ((value: string) => string | boolean)[] {
    return [
      (value: string): string | boolean => !!value || 'Поле обязательно для заполнения',
      (value: string): string | boolean => (value || '').length <= 600 || 'Длина текста не должна превышать 600 символов'
    ]
  }

  onCancelCreateComplaintButtonClick(): void {
    this.complaint = this.getComplaintPrototype()
    this.complaintModal = false
  }

  async created(): Promise<void> {
    try {
      this.complaintsTypes = (await $get('ad-board/complain-types'))?.data
    } catch (error) {
      this.$handleApiError(error)
    }
  }
}
