
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class AppSmartBanner extends Vue {
  @Prop({ type: String, default: '' }) appIcon!: string;
  @Prop({ type: String, default: '' }) appUrl!: string;
  @Prop({ type: String, default: '' }) appTitle!: string;
  @Prop({ type: String, default: '' }) appAuthor!: string;
  @Prop({ type: String, default: '' }) appType!: string;
}

