import io from 'socket.io-client'
import { ChatModule } from '@/store/chat'
import { PlaceModule } from '@/store/place'
import { InvoicesModule } from '@/store/invoices'
import { UserModule } from '@/store/user'
import { PageModule } from '@/store/page'

import { debounce } from 'lodash'
const ioUrl = process.env.VUE_APP_IO_URL || 'wss://websocket.domyland.ru/socket.io/'
const socket = io(ioUrl, { secure: true, transports: ['websocket', 'polling'] })

export default {
  async mounted() {
    if (socket && socket.connected) {
      socket.disconnect()
      await this.$nextTick()
      socket.connect()
    }
    this.connect()
  },
  methods: {
    connect() {
      const self = this

      if (!socket) {
        setTimeout(this.connect, 5000)
        return
      }

      socket.on('connect', () => {
        const token = localStorage.getItem('token') || null

        socket.emit('authorization_req', { token })
        socket.on('authorization_res', (data) => {
          const statusMessage = `socket connection: ${data.status}`

          if (data.statusCode === 200) {
            console.log({ statusMessage })
          } else {
            console.warn(statusMessage)
            self.reconnect(5000)
            return ''
          }
        })

        socket.on('chat', (data) => {
          const orderId = parseInt(self.$route.params.id)
          const scopeTypeId = parseInt(data.scopeTypeId || self.$route.query.scopeTypeId)

          if (self.$route.name === 'placesChat' && orderId === parseInt(self.$route.params.id)) {
            ChatModule.getChat({ orderId, scopeTypeId, fromRow: 0, forse: true })
            ChatModule.viewChat({ orderId, scopeTypeId })
          }
        })
        socket.on('chat-exploitation', () => {
          ChatModule.UpdateChat()
        })
        socket.on('payment', (data) => {
          if (self.$route.name === 'placesOrdersId') {
            const { id } = self.$route.params
            const scopeTypeId = parseInt(data.scopeTypeId || self.$route.query.scopeTypeId)
            PlaceModule.getOrder({ scopeTypeId, id })
          }
        })

        socket.on('invoice', debounce((data) => {
          UserModule.getUser()

          if (self.$route.name === 'invoices') {
            InvoicesModule.getInvoices()
            PageModule.getOnePaymentButton()
          }
          if (self.$route.name === 'invoicesId') {
            const { scopeId, scopeTypeId, type } = self.$route.query
            const { id } = self.$route.params
            if (type === 'invoice') {
              InvoicesModule.getInvoicesId({ id, scopeId, scopeTypeId })
            } else {
              InvoicesModule.getInvoicesCalendarId({ id, scopeId, scopeTypeId })
            }
          }
          if (self.$route.name === 'payments') {
            InvoicesModule.SET_STATE_INVOICES({ key: 'payments', value: [] })
            InvoicesModule.SET_STATE_INVOICES({ key: 'paymentsNextRow', value: 0 })
            InvoicesModule.getPayments()
          }
        }, 500))
        socket.on('connect_error', (event) => {
          console.log('Connection Error:\n', event)
          self.reconnect(5000)
        })
        socket.on('error', (event) => {
          console.log('Socket.io reported a generic error:\n', event)
          self.reconnect(5000)
        })
      })
    },
    reconnect(timeout = 1000) {
      if (socket) {
        socket.disconnect()
      }
      setTimeout(this.connect, timeout)
    },
    removeSocketListeners() {
      socket.removeAllListeners()
    }
  }
}
