
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IInvoicesPlaceholder } from '@/model/page/invoices'

interface ButtonItem {
  title: string
  icon: string
  link?: string
  to?: string
  target?: string
}

@Component
export default class InvoicesInfoBlock extends Vue {
  @Prop({ type: String }) debtTitle!: string
  @Prop({ type: String }) debtSubTitle!: string
  @Prop({ type: String }) buttonTitle!: string
  @Prop({ type: String }) subTitle!: string
  @Prop({ type: String }) title!: string
  @Prop({ type: String }) image!: string
  @Prop({ type: String }) type!: string
  @Prop({ type: String }) buttonLink!: string
  @Prop({ type: String }) paperButtonTitle!: string
  @Prop({ type: String }) paperButtonLink!: string
  @Prop({ type: Boolean, default: false }) isInner!: boolean
  @Prop({ type: Boolean, default: true }) showButton!: boolean
  @Prop({ type: Boolean, default: false }) isAutopay!: boolean
  @Prop({ type: String }) tooltip!: string
  @Prop({ type: String, default: '' }) paymentContext!: string

  get innerShowButton() {
    if (this.showButton === false) {
      return false
    }
    return this.type !== 'invoice'
  }

  get scopeTypeId() {
    return this.$route.query.scopeTypeId as string || '-1'
  }

  get scopeId() {
    return this.$route.query.scopeId as string || '-1'
  }

  get buttons() {
    const { id } = this.$route.params
    let buttons: Array<ButtonItem> = []

    if (this.paperButtonLink) {
      buttons.push({
        title: this.paperButtonTitle,
        icon: 'mdi-file-document-outline',
        link: this.paperButtonLink,
        target: '_blank'
      })
    }

    buttons = buttons.concat([
      {
        title: 'Реквизиты',
        icon: 'mdi-file-document-outline',
        to: `/invoices/${id}/requisites?scopeId=${this.scopeId}&scopeTypeId=${this.scopeTypeId}`
      },
      {
        title: 'Тарифы',
        icon: 'mdi-file-document-outline',
        to: `/invoices/${id}/costs?scopeId=${this.scopeId}&scopeTypeId=${this.scopeTypeId}`
      }
    ])

    return buttons
  }
}

