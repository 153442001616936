
import { Component, Vue } from 'vue-property-decorator'

import { PageModule } from '@/store/page'
import { ServiceModule } from '@/store/service'

// eslint-disable-next-line no-unused-vars
import { IBrandPage } from '@/model/page/service'
import { HandleApiRequest } from '@/utils/helper'

@Component
export default class BrandpagesId extends Vue {
  isLoading = false
  brandpage = {} as IBrandPage
  swiperIndex = 0

  swiperOption = {
    slidesPerView: 1,
    spaceBetween: 0,
    mousewheel: true,
    watchOverflow: true,
    navigation: {
      nextEl: '.swiper-arrow--next',
      prevEl: '.swiper-arrow--prev'
    },
    pagination: {
      el: '.swiper-pagination'
    }
  }

  mapMarker = {
    layout: 'default#image',
    imageHref: require('@/assets/marker.svg'),
    imageSize: [37, 37],
    imageOffset: [-13, -25]
  }

  clusterOptions = {
    1: {
      clusterIconContentLayout: '<div style="color: black; font-size: 14px; text-indent: -2px; transform: translateY(-2px)">{{ properties.geoObjects.length }}</div>',
      clusterIcons: [{
        href: require('@/assets/cluster.svg'),
        size: [40, 40],
        offset: [-20, -20]
      },
      {
        href: require('@/assets/cluster.svg'),
        size: [60, 60],
        offset: [-30, -30],
        shape: {
          type: 'Circle',
          coordinates: [0, 0],
          radius: 30
        }
      }]
    }
  }

  get centerCoords() {
    if (!this.brandpage.addresses) return null

    return this.brandpage.addresses.reduce((center, address) => {
      center = [center[0] + +address.latitude[0], center[1] + +address.longitude[1]]

      return center
    }, [0, 0]).map(coord => coord / this.brandpage.addresses.length)
  }

  get generateRequisites() {
    return [
      this.brandpage.companyLegalName,
      this.brandpage.companyInn ? `ИНН ${this.brandpage.companyInn}` : false,
      this.brandpage.companyOgrn ? `ОГРН ${this.brandpage.companyOgrn}` : false
    ].filter(Boolean).join(', ')
  }

  @HandleApiRequest()
  async mounted() {
    if (PageModule.routePast) {
      PageModule.SET_STATE_PAGE({
        key: 'routeReturnFunciton',
        value: () => this.$router.go(-1)
      })
    } else {
      PageModule.SET_STATE_PAGE({
        key: 'routeReturnFunciton',
        value: () => this.$router.push('/services')
      })
    }

    const id = this.$route.params.brandId
    this.brandpage = await ServiceModule.getBrandpage(id)

    PageModule.changeColor({ color: this.brandpage.color, self: this })

    // выравнивание иконок контактов по левому краю
    this.$nextTick(() => {
      if (!this.brandpage.contacts || !this.brandpage.contacts.length) {
        return
      }
      const contactsWrapper = this.$refs.contacts as HTMLElement
      if (contactsWrapper && contactsWrapper.childNodes?.length) {
        const elWrap = contactsWrapper.childNodes[0] as HTMLElement
        const width = (elWrap.childNodes[0] as HTMLElement).clientWidth
        contactsWrapper.style.marginLeft = `-${(elWrap.clientWidth - width) / 2}px`
      }
    })
  }

  beforeDestroy() {
    PageModule.changeColor({ self: this })
  }

  handleChangeSlide(): void {
    this.swiperIndex = (this.$refs.swiper as any).$swiper.activeIndex
  }
}
