
import { Component, Vue } from 'vue-property-decorator'
import { HandleApiRequest } from '@/utils/helper'

import { InvoicesModule } from '@/store/invoices'

// eslint-disable-next-line no-unused-vars
import { InvocesPageId } from '@/model/page/invoices'

import InvoicesInfoBlock from '@/components/page/invoices/InvoicesInfoBlock.vue'
import InvoicesCalendar from '@/components/page/invoices/InvoicesCalendar.vue'
import TabAccounts from '@/components/page/invoices/tabs/TabAccounts.vue'
import { PageModule } from '@/store/page'

@Component({
  components: {
    TabAccounts,
    InvoicesCalendar,
    InvoicesInfoBlock
  }
})
export default class DetailInvoicesIdPage extends Vue {
  isLoading = true

  get invoice() {
    return InvoicesModule.invoiceDetail
  }

  get scopeTypeId() {
    return this.$route.query.scopeTypeId as string || '-1'
  }

  get scopeId() {
    return this.$route.query.scopeId as string || '-1'
  }

  get infoBlock() {
    if (!this.invoice) {
      return {}
    }

    const {
      title,
      image,
      debtTitle,
      debtSubTitle,
      debtInfo: info,
      paperButtonLink,
      paperButtonTitle,
      paymentAutomaticleTitle,
      accountNumberTitle: subTitle,
      paymentButtonLink: buttonLink,
      paymentButtonTitle: buttonTitle,
      paymentContext: buttonPaymentContext
    } = this.invoice

    const isAutopay = !!paymentAutomaticleTitle

    return {
      info,
      title,
      image,
      subTitle,
      debtTitle,
      buttonLink: buttonPaymentContext ? `/pay/init?c=${buttonPaymentContext}` : buttonLink,
      buttonTitle,
      buttonPaymentContext,
      debtSubTitle: isAutopay ? paymentAutomaticleTitle : debtSubTitle,
      paperButtonLink,
      paperButtonTitle
    }
  }

  @HandleApiRequest()
  async created() {
    PageModule.SET_STATE_PAGE({
      key: 'routeReturnFunciton',
      value: (url: string) => {
        this.$router.go(-1)
      }
    })

    const id = this.$route.params.detailId
    await InvoicesModule.getInvoiceDetail({ id, scopeId: this.scopeId, scopeTypeId: this.scopeTypeId })
  }
}
