
import { Component, Vue, Prop } from 'vue-property-decorator'

import { PassModule } from '@/store/pass'
import { PageModule } from '@/store/page'

import FormParser from '@/components/general/FormParser.vue'

// eslint-disable-next-line no-unused-vars
import { ComputedServiceForm, IFormElement } from '@/model/page/service'

@Component({
  components: {
    FormParser
  }
})
export default class PassLayout extends Vue {
  @Prop({ default: false, type: Boolean }) isPermanent!: boolean

  isLoading = false

  get form() {
    return this.isPermanent ? PassModule.permanentForm : PassModule.form
  }

  async handleForm(form: ComputedServiceForm) {
    form.forEach((element) => {
      if (element.value === undefined) element.value = ''
    })

    try {
      await PassModule.submitCreatePass({ form, isPermanent: this.isPermanent })

      if (this.$vuetify.breakpoint.mdAndUp) {
        this.$router.push(`/places${this.isPermanent ? '?type=permanent' : ''}`)
      } else {
        this.$router.push(`/places/passes/${this.isPermanent ? '?type=permanent' : ''}`)
      }
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async mounted() {
    try {
      localStorage.setItem('placesActiveMenu', 'PassesPage')

      if (this.$vuetify.breakpoint.mdAndUp) {
        PageModule.SET_STATE_PAGE({
          key: 'routeReturnFunciton',
          value: () => this.$router.push('/places')
        })
      } else if (PageModule.routePast) {
        PageModule.SET_STATE_PAGE({
          key: 'routeReturnFunciton',
          value: () => this.$router.go(-1)
        })
      }

      this.isLoading = true

      const query = this.$route.query || {}
      if (this.isPermanent) {
        await PassModule.getPassesPermanentForm({ id: query.id as string || '' })
      } else {
        await PassModule.getPassesForm({ id: query.id as string || '' })
      }

      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error)
    }
  }
}
