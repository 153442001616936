import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'

import store from '@/store'
import { $get } from '@/plugins/axios'

import {
  ILoyalty,
  ILoyaltyPartner,
  ILoyaltyCategoryPage,
  ILoyaltyPartnerCard
} from '@/model/page/loyalty'

export interface LoyaltyState {
  loyalty: ILoyalty
  category: { title: string, itemsCount: number, programTitle: string }
  categoryItems: ILoyaltyPartnerCard[]
  categoryTags: {id: number; title: string}[]
  selectedTagId: number
  partner: ILoyaltyPartner
}

@Module({ dynamic: true, store, name: 'loyalty' })
class Loyalty extends VuexModule implements LoyaltyState {
  loyalty = {} as ILoyalty
  category = { title: '', itemsCount: 0, programTitle: '' }
  categoryItems: ILoyaltyPartnerCard[] = []
  categoryTags: {id: number; title: string}[] = []
  selectedTagId = 0
  partner = {} as ILoyaltyPartner

  @Mutation
  SET_STATE_LOYALTY<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Mutation
  ADD_CATEGORIES(items: ILoyaltyPartnerCard[]) {
    this.categoryItems.push(...items)
  }

  @Action
  async getLoyalty() {
    const { data } = await $get<ILoyalty>('/loyalty-program')
    this.SET_STATE_LOYALTY({ key: 'loyalty', value: data })
  }

  @Action
  async getLoyaltyCategories({ id, fromRow } : { id: string, fromRow: number }): Promise<number> {
    const params = { fromRow } as { fromRow: number; tagId: number }
    if (this.selectedTagId !== 0) {
      params.tagId = this.selectedTagId
    }

    const { data } = await $get<ILoyaltyCategoryPage>(`/loyalty-program/category/${id}`, { params })
    this.SET_STATE_LOYALTY({
      key: 'category',
      value: { title: data.title, itemsCount: data.totals?.itemsCount || 0, programTitle: data.programTitle }
    })
    this.ADD_CATEGORIES(data.items)
    return data.nextRow
  }

  @Action
  async getLoyaltyTags(categoryId: string) {
    const { data } = await $get<{ items: {id: number; title: string}[] }>(`/loyalty-program/tag/${categoryId}`)
    this.SET_STATE_LOYALTY({ key: 'categoryTags', value: data.items })
  }

  @Action
  async getLoyaltyPartner(id: string) {
    const { data } = await $get<ILoyaltyPartner>(`/loyalty-program/partner/${id}`)
    this.SET_STATE_LOYALTY({ key: 'partner', value: data })
  }
}

export const LoyaltyModule = getModule(Loyalty)
