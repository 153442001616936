import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'

import store from '@/store'
import { $get, $put, $post } from '@/plugins/axios'
import uniqBy from 'lodash/uniqBy'

import { IPassesPermission, IPassForm, IPass } from '@/model/page/pass'
import { ComputedServiceForm, IServiceFull, IFormElement } from '@/model/page/service'

import {
  ApiItemsLayout
} from '@/model/index'

export interface PassState {
  form: IServiceFull
  permanentForm: IServiceFull
  passes: Array<IPass>
  permanentPasses: Array<IPass>
  passesPermission: IPassesPermission
}

@Module({ dynamic: true, store, name: 'pass' })
class Pass extends VuexModule implements PassState {
  form = {} as IServiceFull
  permanentForm = {} as IServiceFull
  passes: Array<IPass> = []
  permanentPasses: Array<IPass> = []
  passesPermission = {} as IPassesPermission

  @Mutation
  SET_STATE_PASS<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Mutation
  ADD_PASS(items: Array<IPass>) {
    this.passes = uniqBy([...this.passes, ...items], 'id')
  }

  @Mutation
  CLEAR_PASS() {
    this.passes = []
    this.permanentPasses = []
  }

  @Mutation
  ADD_PERMANENT(items: Array<IPass>) {
    this.permanentPasses = uniqBy([...this.permanentPasses, ...items], 'id')
  }

  @Mutation
  UPDATE_FORM({ name, form }: { name: 'permanentForm' | 'form', form: Array<IFormElement> }) {
    this[name].orderForm = form
  }

  @Action
  async getPassesPermission() {
    const { data } = await $get<IPassesPermission>('/sections/places/passes/')

    this.SET_STATE_PASS({ key: 'passesPermission', value: data })
  }

  @Action
  async getPasses({ fromRow } = { fromRow: 0 }) {
    const { data } = await $get<ApiItemsLayout<IPass>>('/pass/', { params: { fromRow } })

    this.ADD_PASS(data.items)

    return data.nextRow
  }

  @Action
  clearPasses() {
    this.CLEAR_PASS()
  }

  @Action
  async getPassesPermanent({ fromRow } = { fromRow: 0 }) {
    const { data } = await $get<ApiItemsLayout<IPass>>('/permanentpass/', { params: { fromRow } })

    this.ADD_PERMANENT(data.items)

    return data.nextRow
  }

  @Action
  async getPassesForm({ id }: { id?: string } = {}) {
    const url = id ? `/pass/${id}/retry` : '/pass/form/'
    const { data } = await $get<IServiceFull>(url)

    this.SET_STATE_PASS({ key: 'form', value: data })

    return data
  }

  @Action
  async getPassesPermanentForm({ id }: { id?: string } = {}) {
    const url = id ? `/permanentpass/${id}/retry` : '/permanentpass/form/'
    const { data } = await $get<IServiceFull>(url)

    this.SET_STATE_PASS({ key: 'permanentForm', value: data })

    return data
  }

  @Action
  async cancelPass({ id, isPermanent }: { id: number, isPermanent: boolean }) {
    const url = isPermanent ? `/permanentpass/${id}/reject` : `/pass/${id}/cancel`

    await $put(url)

    if (isPermanent) {
      await this.getPassesPermanent()
    } else {
      await this.getPasses()
    }
  }

  @Action
  async submitCreatePass({ form, isPermanent }: { form: ComputedServiceForm, isPermanent: boolean }) {
    const placeId = parseInt(localStorage.getItem('placeId') || '')
    const buildingId = parseInt(localStorage.getItem('buildingId') || '')

    const body = {
      placeId,
      formData: form,
      buildingId
    }

    const url = isPermanent ? '/permanentpass/' : '/pass/'
    const { data } = await $post(url, body)

    return data
  }
}

export const PassModule = getModule(Pass)
