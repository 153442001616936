
import { Component, Vue } from 'vue-property-decorator'
import { PageModule } from '@/store/page'

@Component
export default class ErrorPage extends Vue {
  isLoading = false

  get pageCode() {
    return PageModule.pageCode
  }

  mounted() {
    this.checkRequest()
  }

  async checkRequest() {
    try {
      if (this.isLoading) {
        return
      }

      this.isLoading = true

      await PageModule.initPage()

      const pastRoute = localStorage.getItem('pastErrorRoute') || ''
      if (!pastRoute) {
        if (this.$route.path === '/error') {
          this.$router.push('/')
        } else {
          this.$router.go(0)
        }
        return
      }

      this.$router.push(pastRoute)
    } finally {
      setTimeout(() => {
        this.isLoading = false
      }, 1500)
    }
  }
}
