
import { Component, Vue, Prop } from 'vue-property-decorator'
import { UserModule } from '@/store/user'
import { ICurrentCustomer, INotOwnerRegistrationButton } from '@/model'
import { $remove } from '@/plugins/axios'

@Component
export default class AuthUserView extends Vue {
  @Prop({ type: String, default: 'primary' }) color!: string;

  // data
  areYouSureModal = false;
  isShowNonOwnerModal = false;
  notOwnerRegistrationButtons: INotOwnerRegistrationButton[] | null = null;
  // getters
  get user(): Partial<ICurrentCustomer> {
    return UserModule.user
  }

  get showCanselButton(): boolean {
    if (!this.user.registrations?.length) return false

    return this.user.registrations[0].statusId === 1
  }

  get showRegisterAgainButton(): boolean {
    if (!this.user.registrations?.length) return false

    return this.user.registrations[0].statusId === 3
  }

  get notOwnerRegistrationDescription(): string[] | string {
    if (this.user.notOwnerRegistrationInfo?.description) {
      let str: string[] | string = this.user.notOwnerRegistrationInfo.description
      str = str.split('Вы')
      return str
    }
    return ''
  }

  // actions
  exit() {
    this.$emit('change-step', 'login-card')
    localStorage.setItem('pushToken', '')
    localStorage.setItem('token', '')
  }

  async canselRegistration(): Promise<void> {
    await $remove(`/customerregistrationrequest/${this.user.registrations![0].id}`)
    UserModule.getUser()
  }

  async registerAgain(): Promise<void> {
    await this.canselRegistration()
    this.$emit('change-step', 'register-card')
  }

  registerBtnEvents(name?: string): void {
    if (name === 'cancel') {
      this.isShowNonOwnerModal = false
    } else if (name === 'continue') {
      UserModule.SET_STATE_USER({ key: 'user', value: { ...this.user, isOwner: false } })
      this.$emit('change-step', 'register-card')
    } else {
      UserModule.SET_STATE_USER({ key: 'user', value: { ...this.user, isOwner: true } })
      this.$emit('change-step', 'register-card')
    }
  }

  mounted(): void {
    if (this.user.notOwnerRegistrationInfo?.buttons) {
      this.notOwnerRegistrationButtons = Array.from(this.user.notOwnerRegistrationInfo?.buttons).reverse()
    }
  }
}
