
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IInvoicesPlaceholder } from '@/model/page/invoices'

@Component
export default class InvoicesTopCard extends Vue {
  @Prop(Boolean) readonly isAvailable?: boolean
  @Prop(String) readonly debtInfo: string | undefined

  get placeholder() {
    return this.settings.placeholder
  }

  @Prop({ type: Object, required: true }) settings!: any
}

