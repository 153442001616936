
import { Component, Vue } from 'vue-property-decorator'
import { HandleApiRequest } from '@/utils/helper'
import { LoyaltyModule } from '@/store/loyalty'
import { PageModule } from '@/store/page'
import LoyaltyCard from '@/components/page/loyalty/LoyaltyCard.vue'
import LoyaltyPartnerCard from '@/components/page/loyalty/LoyaltyPartnerCard.vue'

@Component({
  components: {
    LoyaltyCard,
    LoyaltyPartnerCard
  }
})
export default class LoyaltyPage extends Vue {
  isLoading = true

  swiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20
  }

  get loyalty() {
    return LoyaltyModule.loyalty
  }

  get blockWidth() {
    return this.$vuetify.breakpoint.smAndDown ? 1 : 2
  }

  @HandleApiRequest()
  async mounted() {
    await LoyaltyModule.getLoyalty()

    PageModule.SET_STATE_PAGE({ key: 'breadCrumbsTitle', value: this.loyalty.programTitle })
  }
}
