
import { Vue, Component } from 'vue-property-decorator'
import { PageModule } from '@/store/page'

// eslint-disable-next-line no-unused-vars
import { GetNotificationSubscribe } from '@/model'

@Component
export default class EditNotification extends Vue {
  pushInfo: GetNotificationSubscribe = {
    subscribe: [],
    token: ''
  }

  showNotificationsSettings = false

  get mayShowNotification() {
    return Object.prototype.hasOwnProperty.call(window, 'Notification') && Notification.permission === 'granted'
  }

  get formattedSubscribeInfo() {
    return {
      token: this.pushInfo.token,
      subscribe: this.pushInfo.subscribe.reduce((acc: any, item) => {
        acc[item.name] = item.enabled
        return acc
      }, {})
    }
  }

  handleShowClick() {
    // if Notification.permission === denied => send permission request

    this.showNotificationsSettings = !this.showNotificationsSettings
  }

  async mounted() {
    const settings = await PageModule.getNotifications()

    this.pushInfo = settings
  }

  async updateNotification() {
    await PageModule.updatePushToken(this.formattedSubscribeInfo)
  }
}
