
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ServiceCard extends Vue {
  @Prop() readonly description!: string
  @Prop() readonly icon!: string
  @Prop() readonly image!: string
  @Prop() readonly name!: string
  @Prop() readonly title!: string
}
