
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { INewsFeed } from '@/model/page/newsFeed'

@Component
export default class NewsFeedCard extends Vue {
  @Prop() readonly newsFeed!: INewsFeed
  // data
  showEmotionPicker = false;
  // getters
  get hasReactions(): boolean {
    return this.newsFeed.reactions.length > 0
  }

  // actions
  reactToNewsItem(id: number):void {
    this.$emit('send-reaction', { id: this.newsFeed.id, reactionId: id })
    this.showEmotionPicker = false
  }
}
