
import { Vue, Component, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IService } from '@/model/page/service'

@Component
export default class ServiceBlock extends Vue {
  @Prop({ required: true }) service!: IService
  @Prop({ default: false }) pinned!: Boolean
}
