
import { Component, Vue, Watch } from 'vue-property-decorator'
import { HandleApiRequest } from '@/utils/helper'

import { ServiceModule } from '@/store/service'

import ServiceCard from '@/components/page/service/ServiceCard.vue'
import ServiceBlock from '@/components/page/service/ServiceBlock.vue'

// eslint-disable-next-line no-unused-vars
import { ServiceStructure } from '@/model/page/service'
import { Debounce } from '@/utils/decorators'

@Component({
  components: {
    ServiceCard,
    ServiceBlock
  }
})
export default class ServicePage extends Vue {
  isLoading = true
  search = ''
  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 20
  }

  generateBlockWidth(length: number) {
    const increaceAmount = this.$vuetify.breakpoint.smAndDown ? 2 : 3
    return length > increaceAmount ? 1 : 2
  }

  @Watch('search')
  @Debounce(300)
  async handleSearch(value: string) {
    localStorage.setItem('serviceSearch', value)

    await ServiceModule.getServices(this.search)
  }

  get pinned() {
    let pinned = this.services.items.pinned

    if (this.services.hasPretensionService) {
      pinned.unshift({
        id: 'pass',
        title: this.services.pretensionServiceTitle || '',
        icon: this.services.pretensionServiceIcon || '',
        image: this.services.pretensionServiceImage || '',
        priceLabel: this.services.pretensionServiceTitle || ''
      })
    }

    if (this.services.hasPassService) {
      pinned.unshift({
        id: 'pass',
        title: this.services.passServiceTitle || '',
        icon: this.services.passServiceIcon || '',
        image: this.services.passServiceImage || '',
        priceLabel: this.services.passServiceTitle || ''
      })
    }

    pinned = pinned.filter(({ title }) => title.toLowerCase().includes(this.search.toLowerCase()))

    return pinned
  }

  get services(): ServiceStructure {
    return JSON.parse(JSON.stringify(ServiceModule.services))
  }

  get cards() {
    return ServiceModule.services.items.cards
  }

  @HandleApiRequest()
  async mounted() {
    const search = localStorage.getItem('serviceSearch') || ''

    this.search = search

    await ServiceModule.getServices(this.search)
  }
}
