import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

dayjs.locale('ru')

Vue.filter('ellipsis', (str: string, length: number, maxLineLength?: number) => {
  if (str.length > length) {
    str = str.slice(0, length - str.length - 3) + '...'
  }
  if (maxLineLength && str.split(' ').some(word => word.length > maxLineLength)) {
    str = str.trim().slice(0, maxLineLength - str.length - 3) + '...'
  }

  return str
})

Vue.filter('fileSize', (size: number): string => {
  size = size / 1024
  let ext = 'Kb'
  if (size > 100) {
    size = size / 1024
    ext = 'Mb'
  }
  return `${size.toFixed(2)} ${ext}`
})

Vue.filter('dayjs', (timestamp: number, format: string): string => {
  if (!dayjs.unix(timestamp).isValid()) {
    return ''
  }
  return dayjs(timestamp).format(format)
})

Vue.filter('getDate', (timestamp: number): string => {
  if (!dayjs.unix(timestamp).isValid()) {
    return ''
  }
  return dayjs.unix(timestamp).format('D MMM')
})

Vue.filter('getTime', (timestamp: number): string => {
  if (!dayjs.unix(timestamp).isValid()) {
    return ''
  }
  return dayjs.unix(timestamp).format('HH:mm')
})

Vue.filter('formatTime', (time: number) => {
  const hours = Math.floor(time / 3600)
  const minutes = ('0' + Math.floor(time % 3600 / 60)).slice(-2)
  const seconds = ('0' + Math.floor(time % 60)).slice(-2)

  return [hours, minutes, seconds].filter(Boolean).join(':')
})

Vue.filter('formatCurrency', (amount: number | string, round: boolean = false): string => {
  const number = Math.abs(round ? parseInt(amount + '') : parseFloat(amount + ''))

  return number.toLocaleString('ru-RU', {
    style: 'currency',
    minimumFractionDigits: round ? 0 : 2,
    currency: 'RUB'
  })
})
