
import { Component, Vue } from 'vue-property-decorator'
import { InfoModule } from '@/store/info'
import InfoContacts from '@/components/page/info/InfoContacts.vue'
import InfoDocuments from '@/components/page/info/InfoDocuments.vue'
import InfoRequisites from '@/components/page/info/InfoRequisites.vue'

@Component({
  components: {
    InfoContacts,
    InfoDocuments,
    InfoRequisites
  }
})
export default class CompanyPage extends Vue {
  isLoading = false

  get company() {
    return InfoModule.company
  }

  async mounted() {
    try {
      this.isLoading = true
      await InfoModule.getCompany()
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error)
    }
  }
}
