import Vue from 'vue'
import router from '@/router'

import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { UserModule } from '@/store/user'
import { PageModule } from '@/store/page'

const instance = axios.create({
  baseURL: (process.env.VUE_APP_BASE_URL || 'https://customer-api-stage.domyland.dev'),
  timeout: 15000,
  headers: {
    withCredentials: true
  }
})

Vue.prototype.$axios = axios

instance.interceptors.request.use(async(config) => {
  const host = window.location.hostname
  const appName = host
  config.headers.AppName = appName
  config.headers.AppVersion = '3.0.1'
  config.headers.TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const oldToken = localStorage.getItem('authorization')
  let token = localStorage.getItem('token')

  if (oldToken && !token && config.url !== '/auth/renew') {
    try {
      await UserModule.renewToken(oldToken)
    } catch (error) {
      console.warn(`Error with updating token ${config.url}`)
      router.go(0)
    } finally {
      localStorage.removeItem('authorization')
    }
  }

  token = localStorage.getItem('token')

  if (!config.headers.authorization && token) {
    config.headers.authorization = token
  }

  const placeId = localStorage.getItem('placeId')
  const buildingId = localStorage.getItem('buildingId')
  const ignoreUrlRequests = ['/currentcustomer']

  if (placeId && buildingId && config.url ? !(ignoreUrlRequests.includes(config.url) && config.method === 'get') : true) {
    config.headers.placeId = placeId
    config.headers.buildingId = buildingId
  } else {
    const params = config.params || {}
    config.params = params
  }

  return config
}, (error) => {
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
  return response
}, async(error: AxiosError) => {
  const code = error.response?.status || 200

  if (code === 401) {
    const oldToken = localStorage.getItem('authorization')
    localStorage.removeItem('authorization')
    localStorage.removeItem('token')
    localStorage.removeItem('pushToken')

    if (oldToken && error.config.url !== '/auth/renew') {
      try {
        await UserModule.renewToken(oldToken)
      } catch {
        router.push('/login')
        console.warn('Error with updating token, warm')
      }
    }
  } else if (![400].includes(code)) {
    const url = error.response?.config.url || ''
    const ignoreRoutes = ['/pushToken']

    if (ignoreRoutes.includes(url)) {
      return Promise.reject(error)
    }

    const userMessages = error?.response?.data?.userMessages
    if (!userMessages || !userMessages.length) {
      error.message = 'ignoreError'
      PageModule.SET_STATE_PAGE({ key: 'pageCode', value: code })
    }
  }

  return Promise.reject(error)
})

interface ApiLAyout<T = any> {
  data: T
  success: boolean
}

export const get = <D = any>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<D>> => instance.get(url, config)
export const put = <D = any>(url: string, data: Object | null = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<D>> => instance.put(url, data, config)
export const post = <D = any>(url: string, data: Object | null = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<D>> => instance.post(url, data, config)
export const patch = <D = any>(url: string, data: Object | null = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<D>> => instance.patch(url, data, config)
export const remove = <D = any>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<D>> => instance.delete(url, config)

export const $get = <D = any, L = ApiLAyout<D>>(url: string, config: AxiosRequestConfig = {}): Promise<L> => {
  return <Promise<L>>instance.get(url, config)
    .then(({ data }) => data)
}

export const $put = <D = any, L = ApiLAyout<D>>(url: string, data: Object | null = {}, config: AxiosRequestConfig = {}): Promise<L> => {
  return <Promise<L>>put(url, data, config)
    .then(({ data }) => data)
}

export const $post = <D = any, L = ApiLAyout<D>>(url: string, data: Object | null = {}, config: AxiosRequestConfig = {}): Promise<L> => {
  return <Promise<L>>post(url, data, config)
    .then(({ data }) => data)
}

export const $patch = <D = any, L = ApiLAyout<D>>(url: string, data: Object | null = {}, config: AxiosRequestConfig = {}): Promise<L> => {
  return <Promise<L>>patch(url, data, config)
    .then(({ data }) => data)
}

export const $remove = <D = any, L = ApiLAyout<D>>(url: string, config: AxiosRequestConfig = {}): Promise<L> => {
  return <Promise<L>>remove(url, config)
    .then(({ data }) => data)
}
