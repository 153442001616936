
import { Component, Vue } from 'vue-property-decorator'
import { PageModule } from '@/store/page'

@Component
export default class NotFoundPage extends Vue {
  goHome() {
    const menu = PageModule.menuItems
    if (menu && menu[0]) {
      this.$router.push({ name: menu[0]?.name })
    } else {
      this.$router.push('/')
    }
  }
}
