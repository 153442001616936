
import { Component, Vue, Prop } from 'vue-property-decorator'

import { InvoicesModule } from '@/store/invoices'

@Component
export default class TabRequisites extends Vue {
  @Prop({ required: true }) readonly invoiceId!: number

  isLoading: boolean = false

  get requisites() {
    return InvoicesModule.requisites
  }

  get scopeTypeId() {
    return this.$route.query.scopeTypeId as string || '-1'
  }

  get scopeId() {
    return this.$route.query.scopeId as string || '-1'
  }

  async created() {
    this.isLoading = true
    await InvoicesModule.getRequisites({ id: this.invoiceId, scopeTypeId: this.scopeTypeId, scopeId: this.scopeId })
    this.isLoading = false
  }
}
