
import { Vue, Component, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { ILoyalty } from '@/model/page/loyalty'

@Component
export default class LoyaltyCard extends Vue {
  @Prop({ required: true }) loyalty!: ILoyalty

  isModalShow = false

  get isCardExist() {
    return this.loyalty.customerBarCode ?? null
  }

  get cardNumber() {
    return this.loyalty.customerBarCode?.toString()?.match(/.{1,4}/g)?.join(' ')
  }

  get cardStyle() {
    return this.loyalty.cardHex ? `background-color:${this.loyalty.cardHex}` : `background-image: url("${this.loyalty.cardBackground}")`
  }

  get cardHolderMargin() {
    return this.loyalty.customerBarCode ? '34px' : '92px'
  }
}
