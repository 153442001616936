
import { Component, Vue } from 'vue-property-decorator'
import { CounterModule } from '@/store/counter'
import { HandleApiRequest } from '@/utils/helper'

import CounterBlock from '@/components/page/counter/CounterBlock.vue'

@Component({
  components: {
    CounterBlock
  }
})
export default class CountersPage extends Vue {
  // data
  isLoading = true
  hasHistoryButton = false
  historyButtonTitle = ''
  hasHistoryButtonGeneral = false
  historyButtonTitleGeneral = ''
  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    mousewheel: true
  }

  tab = 'individual'
  tabs: { name: string, title: string }[] = [];

  // getters
  get counters() {
    return CounterModule.counters.filter(({ meteringGroups }) => meteringGroups.length)
  }

  get generalCounters() {
    return CounterModule.generalCounters.filter(({ meteringGroups }) => meteringGroups.length)
  }

  // actions
  async getIndividualCounters(): Promise<void> {
    const { hasHistoryButton, historyButtonTitle, tabs } = await CounterModule.getCounters()

    this.hasHistoryButton = hasHistoryButton
    this.historyButtonTitle = historyButtonTitle
    this.tabs = tabs.items
  }

  async getGeneralsCounters(): Promise<void> {
    const { hasHistoryButton, historyButtonTitle } = await CounterModule.getCounters(1)

    this.hasHistoryButtonGeneral = hasHistoryButton
    this.historyButtonTitle = historyButtonTitle
  }

  // hooks
  @HandleApiRequest()
  async mounted() {
    await this.getIndividualCounters()
    await this.getGeneralsCounters()
  }
}
