import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'

import { PageModule } from '@/store/page'
import Authorization from '@/views/Authorization.vue'
import UiPage from '@/views/ui.vue'
import ServicePage from '@/views/service/index.vue'
import AutopayPage from '@/views/info/autopay.vue'
import ServicePageId from '@/views/service/id.vue'
import BrandIdPage from '@/views/service/brandpagesId.vue'
import PlacesPage from '@/views/places/index.vue'
import PlacesOrders from '@/views/places/orders.vue'
import PlacesOrdersId from '@/views/places/ordersId.vue'
import PlacesChat from '@/views/places/chat.vue'
import PlacesCountersPage from '@/views/places/counters.vue'
import PlacesCountersIdPage from '@/views/places/countersId.vue'
import PlacesCountersEventIdPage from '@/views/places/countersEventId.vue'
import PlacesHistoryPage from '@/views/places/history.vue'
import PlacesHistoryIdPage from '@/views/places/historyId.vue'
import PlacesSpecificationPage from '@/views/places/specification.vue'
import PlacesPassesPage from '@/views/places/passes.vue'
import PlacesPassesFormPage from '@/views/places/passesForm.vue'
import PlacesPassesPermanentFormPage from '@/views/places/passesPermanentForm.vue'
import PlacesPeoplePage from '@/views/places/people.vue'
import PlacesPeopleIdPage from '@/views/places/peopleId.vue'
import PlacesPeopleCreatePage from '@/views/places/peopleCreate.vue'
import PlacesDocumentsPage from '@/views/places/documents.vue'
import InvoicesPage from '@/views/invoices/index.vue'
import InvoicesIdPage from '@/views/invoices/id.vue'
import InvoicesDetailPage from '@/views/invoices/idDetail.vue'
import InvoicesRequisitesPage from '@/views/invoices/requisites.vue'
import InvoicesCostsPage from '@/views/invoices/costs.vue'
import InvoicesPaymentsPage from '@/views/invoices/invoicesPayments.vue'
import ErrorPage from '@/views/error.vue'
import NotFoundPage from '@/views/404.vue'
import NewsFeedPage from '@/views/newsfeed/newsFeed.vue'
import NewsСustomerNotificationsIdPage from '@/views/newsfeed/customerNotificationsId.vue'
import NewsVotesIdPage from '@/views/newsfeed/votesId.vue'
import NewsIdPage from '@/views/newsfeed/newsId.vue'
import PollsIdPage from '@/views/newsfeed/pollsId.vue'
import CommunicationsIdPage from '@/views/newsfeed/communicationsId.vue'
import InfoPage from '@/views/info/index.vue'
import CamerasPage from '@/views/info/cameras.vue'
import ContactsPage from '@/views/info/contacts.vue'
import ForumPage from '@/views/info/forum.vue'
import FaqPage from '@/views/info/faq.vue'
import OfficesPage from '@/views/info/offices.vue'
import AboutPage from '@/views/info/about.vue'
import BuildingPage from '@/views/info/building.vue'
import CompanyPage from '@/views/info/company.vue'
import PersonalPage from '@/views/info/personal.vue'
import AdvertsPage from '@/views/info/adverts.vue'
import AdvertsMyPage from '@/views/info/advertsMy.vue'
import AdvertsIdPage from '@/views/info/advertsId.vue'
import AdvertsAddPage from '@/views/info/advertsAdd.vue'
import AdvertsAddIdPage from '@/views/info/advertsAddId.vue'
import AdvertsIdEditPage from '@/views/info/advertsEditId.vue'
import InfoPlacesPage from '@/views/info/places.vue'
import LoyaltyPage from '@/views/service/loyalty/index.vue'
import LoyaltyCategoryIdPage from '@/views/service/loyalty/loyaltyCategoryId.vue'
import LoyaltyPartnerIdPage from '@/views/service/loyalty/loyaltyPartnerId.vue'
import EditUserFio from '@/views/user/editUserFio.vue'
import EditUserPassword from '@/views/user/EditUserPassword.vue'
import InfoChatPage from '@/views/info/infoChatPage.vue'
import PaymentPage from '@/views/payment.vue'
import { LoginStatus } from '@/model'

Vue.use(VueRouter)

interface IRouterMeta {
  meta?: {
    headerTitle: string
    layout?: string
  }
}

export type TAppRoute = RouteConfig & IRouterMeta

const routes: Array<TAppRoute> = [
  {
    path: '/services',
    name: 'services',
    component: ServicePage,
    meta: {
      headerTitle: 'Сервис'
    }
  },
  {
    path: '/services/brandpages',
    redirect: '/services'
  },
  {
    path: '/services/brandpages/:brandId',
    name: 'brandId',
    component: BrandIdPage,
    meta: {
      headerTitle: 'Страница бренда'
    }
  },
  {
    path: '/services/:id',
    name: 'servicesId',
    component: ServicePageId,
    meta: {
      headerTitle: 'Сервис'
    }
  },
  {
    path: '/services/orders/:id/chat',
    name: 'serviceChat',
    component: PlacesChat,
    meta: {
      headerTitle: 'Чат по заявке'
    }
  },
  {
    path: '/places',
    name: 'places',
    component: PlacesPage,
    meta: {
      headerTitle: 'Помещение'
    }
  },
  {
    path: '/places/orders',
    name: 'placesOrders',
    component: PlacesOrders,
    meta: {
      headerTitle: 'Заказы'
    }
  },
  {
    path: '/places/orders/:id',
    name: 'placesOrdersId',
    component: PlacesOrdersId,
    meta: {
      headerTitle: 'Заказы'
    }
  },
  {
    path: '/places/orders/:id/chat',
    name: 'placesChat',
    component: PlacesChat,
    meta: {
      headerTitle: 'Сообщения'
    }
  },
  {
    path: '/places/counters',
    name: 'placesCounters',
    component: PlacesCountersPage,
    meta: {
      headerTitle: 'Счетчики'
    }
  },
  {
    path: '/places/counters/history',
    name: 'history',
    component: PlacesHistoryPage,
    meta: {
      headerTitle: 'История'
    }
  },
  {
    path: '/places/counters/history/:id',
    name: 'historyId',
    component: PlacesHistoryIdPage,
    meta: {
      headerTitle: 'История'
    }
  },
  {
    path: '/places/counters/:id/:eventId',
    name: 'countersEventId',
    component: PlacesCountersEventIdPage,
    meta: {
      headerTitle: 'Счетчики'
    }
  },
  {
    path: '/places/counters/:id',
    name: 'placesCountersId',
    component: PlacesCountersIdPage,
    props: true,
    meta: {
      headerTitle: 'Счетчики'
    }
  },
  {
    path: '/places/info',
    name: 'placesInfo',
    component: PlacesSpecificationPage,
    meta: {
      headerTitle: 'Информация'
    }
  },
  {
    path: '/places/passes',
    name: 'placesPasses',
    component: PlacesPassesPage,
    meta: {
      headerTitle: 'Пропуска'
    }
  },
  {
    path: '/places/passes/form',
    name: 'placesPassesForm',
    component: PlacesPassesFormPage,
    meta: {
      headerTitle: 'Пропуска'
    }
  },
  {
    path: '/places/passes/permanent-form',
    name: 'placesPassesPermanentForm',
    component: PlacesPassesPermanentFormPage,
    meta: {
      headerTitle: 'Постоянные Пропуска'
    }
  },
  {
    path: '/places/people',
    name: 'placesPeople',
    component: PlacesPeoplePage,
    meta: {
      headerTitle: 'Люди'
    }
  },
  {
    path: '/places/people/create',
    name: 'peopleCreate',
    component: PlacesPeopleCreatePage,
    meta: {
      headerTitle: 'Создать'
    }
  },
  {
    path: '/places/people/:id',
    name: 'placesPeopleId',
    component: PlacesPeopleIdPage,
    meta: {
      headerTitle: 'Люди'
    }
  },
  {
    path: '/places/documents',
    name: 'placesDocuments',
    component: PlacesDocumentsPage,
    meta: {
      headerTitle: 'Документы'
    }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: InvoicesPage,
    meta: {
      headerTitle: 'Счета'
    }
  },
  {
    path: '/invoices/payments',
    name: 'payments',
    component: InvoicesPaymentsPage,
    meta: {
      headerTitle: 'История оплат'
    }
  },
  {
    path: '/invoices/:id',
    name: 'invoicesId',
    component: InvoicesIdPage,
    meta: {
      headerTitle: 'Счета'
    }
  },
  {
    path: '/invoices/:id/requisites',
    name: 'requisites',
    component: InvoicesRequisitesPage,
    meta: {
      headerTitle: 'Реквизиты'
    }
  },
  {
    path: '/invoices/:id/costs',
    name: 'costs',
    component: InvoicesCostsPage,
    meta: {
      headerTitle: 'Тарифы'
    }
  },
  {
    path: '/invoices/:id/:detailId',
    name: 'invoicesDetail',
    component: InvoicesDetailPage,
    meta: {
      headerTitle: 'Счета'
    }
  },
  {
    path: '/newsfeed',
    name: 'newsFeed',
    component: NewsFeedPage,
    meta: {
      headerTitle: 'События'
    }
  },
  {
    path: '/newsfeed/communications/:id',
    name: 'communicationId',
    component: CommunicationsIdPage,
    meta: {
      headerTitle: 'События'
    }
  },
  {
    path: '/newsfeed/news',
    name: 'news',
    component: NewsFeedPage,
    meta: {
      headerTitle: 'Новости'
    }
  },
  {
    path: '/newsfeed/news/:id',
    name: 'newsId',
    component: NewsIdPage,
    meta: {
      headerTitle: 'Новости'
    }
  },
  {
    path: '/newsfeed/customerNotifications',
    name: 'customerNotifications',
    component: NewsFeedPage,
    meta: {
      headerTitle: 'Уведомления'
    }
  },
  {
    path: '/newsfeed/customerNotifications/:id',
    name: 'customerNotificationsId',
    component: NewsСustomerNotificationsIdPage,
    meta: {
      headerTitle: 'Уведомления'
    }
  },
  {
    path: '/newsfeed/votes',
    name: 'votes',
    component: NewsFeedPage,
    meta: {
      headerTitle: 'Общие собрания'
    }
  },
  {
    path: '/newsfeed/votes/:id',
    name: 'votesId',
    component: NewsVotesIdPage,
    meta: {
      headerTitle: 'Общие собрания'
    }
  },
  {
    path: '/newsfeed/general-meeting/:id',
    name: 'generalMeetingId',
    component: NewsVotesIdPage,
    meta: {
      headerTitle: 'Общие собрания'
    }
  },
  {
    path: '/newsfeed/polls',
    name: 'polls',
    component: NewsFeedPage,
    meta: {
      headerTitle: 'Опросы'
    }
  },
  {
    path: '/newsfeed/polls/:id',
    name: 'pollsId',
    component: PollsIdPage,
    meta: {
      headerTitle: 'Опросы'
    }
  },
  {
    path: '/info',
    name: 'info',
    component: InfoPage,
    meta: {
      headerTitle: 'Меню'
    }
  },
  {
    path: '/info/chat',
    name: 'info-chat',
    component: InfoChatPage,
    meta: {
      headerTitle: 'Чат'
    }
  },
  {
    path: '/info/cameras',
    name: 'cameras',
    component: CamerasPage,
    meta: {
      headerTitle: 'Камеры'
    }
  },
  {
    path: '/info/contacts',
    name: 'contacts',
    component: ContactsPage,
    meta: {
      headerTitle: 'Полезные контакты'
    }
  },
  {
    path: '/info/forum',
    name: 'forum',
    component: ForumPage,
    meta: {
      headerTitle: 'Форум'
    }
  },
  {
    path: '/info/faq',
    name: 'faq',
    component: FaqPage,
    meta: {
      headerTitle: 'Вопросы и ответы'
    }
  },
  {
    path: '/info/offices',
    name: 'offices',
    component: OfficesPage,
    meta: {
      headerTitle: 'Офисы продаж'
    }
  },
  {
    path: '/info/about',
    name: 'about',
    component: AboutPage,
    meta: {
      headerTitle: 'О приложении'
    }
  },
  {
    path: '/info/places',
    name: 'InfoPlaces',
    component: InfoPlacesPage,
    meta: {
      headerTitle: 'Мои помещения'
    }
  },
  {
    path: '/info/personal',
    name: 'personal',
    component: PersonalPage,
    meta: {
      headerTitle: 'Профиль',
      hasReturnButton: false
    }
  },
  {
    path: '/info/personal/fio',
    name: 'edit_fio',
    component: EditUserFio,
    meta: {
      headerTitle: 'Профиль'
    }
  },
  {
    path: '/info/personal/password',
    name: 'password-change',
    component: EditUserPassword,
    meta: {
      headerTitle: 'Профиль'
    }
  },
  {
    path: '/info/personal/autopay',
    name: 'autopay',
    component: AutopayPage,
    meta: {
      headerTitle: 'Автоплатёж',
      hasReturnButton: false
    }
  },
  {
    path: '/info/adverts',
    name: 'adboard',
    component: AdvertsPage,
    meta: {
      headerTitle: 'Объявления'
    }
  },
  {
    path: '/info/adverts/:id',
    name: 'advertsId',
    component: AdvertsIdPage,
    meta: {
      headerTitle: 'Объявление'
    }
  },
  {
    path: '/info/adverts-add',
    name: 'advertsAdd',
    component: AdvertsAddPage,
    meta: {
      headerTitle: 'Создать объявление'
    }
  },
  {
    path: '/info/adverts/:id/edit',
    name: 'advertsIdEdit',
    component: AdvertsIdEditPage,
    meta: {
      headerTitle: 'Редактирование объявления'
    }
  },
  {
    path: '/info/adverts-my',
    name: 'advertsMy',
    component: AdvertsMyPage,
    meta: {
      headerTitle: 'Мои объявления'
    }
  },
  {
    path: '/info/adverts-my/:id',
    name: 'advertsMyId',
    component: AdvertsIdPage,
    meta: {
      headerTitle: 'Объявление'
    }
  },
  {
    path: '/info/adverts-add/:id',
    name: 'advertsAddId',
    component: AdvertsAddIdPage,
    meta: {
      headerTitle: 'Создать объявление'
    }
  },
  {
    path: '/info/adverts-my/:id/edit',
    name: 'advertsMyIdEdit',
    component: AdvertsIdEditPage,
    meta: {
      headerTitle: 'Редактирование объявления'
    }
  },
  {
    path: '/info/building',
    name: 'building',
    component: BuildingPage,
    meta: {
      headerTitle: 'Дом'
    }
  },
  {
    path: '/info/company',
    name: 'company',
    component: CompanyPage,
    meta: {
      headerTitle: 'Компания'
    }
  },
  {
    path: '/loyalty',
    name: 'loyalty',
    component: LoyaltyPage,
    meta: {
      headerTitle: ''
    }
  },
  {
    path: '/loyalty/category/:id',
    name: 'loyaltyCategory',
    component: LoyaltyCategoryIdPage,
    meta: {
      headerTitle: ''
    }
  },
  {
    path: '/loyalty/:id',
    name: 'loyaltyPartner',
    component: LoyaltyPartnerIdPage,
    meta: {
      headerTitle: ''
    }
  },
  {
    path: '/secret-ui',
    name: 'ui',
    component: UiPage,
    meta: {
      headerTitle: 'UI'
    }
  },
  {
    path: '/empty',
    name: 'empty',
    component: () => import('@/views/empty.vue'),
    meta: {
      headerTitle: 'empty'
    }
  },
  {
    path: '/',
    name: 'login',
    component: Authorization,
    meta: {
      headerTitle: 'Вход в систему',
      layout: 'none'
    }
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
    meta: {
      headerTitle: 'Ошибка',
      layout: 'none'
    }
  },
  {
    path: '/404',
    name: '404',
    component: NotFoundPage,
    meta: {
      headerTitle: '404',
      layout: 'none'
    }
  },
  {
    path: '/pay/:c',
    name: 'pay',
    component: PaymentPage,
    props: (route) => ({ query: route.query.c }),
    meta: {
      headerTitle: 'Оплатить задолженность',
      layout: 'PaymentLayout'
    }
  },
  {
    path: '*',
    redirect: '/'
  }

]

const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to: Route) => {
  if (to.meta?.headerTitle && document.title !== to.meta?.headerTitle) {
    document.title = to.meta.headerTitle!
  }
})

router.beforeEach(async(to: Route, from: Route, next: Function) => {
  const token = localStorage.getItem('token')

  PageModule.UPDATE_ROUTES({ from, to })

  const dontHasToken = !localStorage.getItem('token') && !localStorage.getItem('authorization')

  if (dontHasToken) {
    if (!['login', 'ui', 'empty', 'pay'].includes(to.name as string)) {
      return next('/')
    } else {
      return next()
    }
  }

  if (PageModule.isInited) {
    PageModule.getSections()
  }

  if (to.name === 'login' && token) {
    const status = await PageModule.initPage()
    if (status !== LoginStatus.USER_DONT_EXIST) { return next('/services') } else return next()
  }
  if (to.name !== 'login' && !PageModule.isInited) {
    try {
      localStorage.setItem('pastErrorRoute', '')
      const status = await PageModule.initPage()
      if (status === LoginStatus.USER_DONT_EXIST) {
        return next('/')
      }
    } catch (error) {
      if (to.name === 'error') {
        return next()
      }
      if (token) {
        localStorage.setItem('pastErrorRoute', to.fullPath)
        return next('/error')
      } else {
        return next('/')
      }
    }
  }

  if (PageModule.routeReturnFunciton) {
    PageModule.SET_STATE_PAGE({ key: 'routeReturnFunciton', value: null })
  }

  if (to.path === '/' || (to.name && PageModule.unavailableRoutes.includes(to.name) && from.name !== 'login')) {
    const menu = PageModule.menuItems

    return next({ name: menu[0]?.name || 'error' })
  }
  if (to.meta && to.meta.headerTitle) {
    document.title = to.meta.headerTitle
    PageModule.SET_STATE_PAGE({ key: 'breadCrumbsTitle', value: to.meta.headerTitle })
  }
  return next()
})
export default router
