import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'

import store from '@/store'
import { $get, $post } from '@/plugins/axios'

import {
  IAutopay
} from '@/model/page/autopay'

export interface AutopayState {}

@Module({ dynamic: true, store, name: 'autopay' })
class Autopay extends VuexModule implements AutopayState {
  @Mutation
  SET_STATE_AUTOPLAY<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Action
  async getAutopay() {
    const { data } = await $get<IAutopay>('/payment-automatically')

    return data
  }

  @Action
  async saveAutopay(info: any) {
    const { data } = await $post<IAutopay>('/payment-automatically', info)

    return data
  }

  @Action
  async deleteCard(tokenId: number) {
    const { data } = await $post<IAutopay>(`/payment-automatically/${tokenId}/card-delete`)

    return data
  }
}

export const AutopayModule = getModule(Autopay)
