
import { Component, Vue } from 'vue-property-decorator'
import { AutopayModule } from '@/store/autopay'
import { HandleApiRequest } from '@/utils/helper'
import AutoPayBlock from '@/components/page/autopay/AutoPayBlock.vue'
// @ts-ignore
import Cleave from 'cleave.js'

// eslint-disable-next-line no-unused-vars
import { IAutopay, IAutopayToken } from '@/model/page/autopay'
import { PageModule } from '@/store/page'

let cleave: any

@Component({
  components: {
    AutoPayBlock
  }
})
export default class AutoPayPage extends Vue {
  isLoading = true
  isPayLoading = false
  paymentIndex = 0
  oldPaymentIndex = 0
  autopay: IAutopay | null = null
  oldAutopay: IAutopay | null = null

  get isDark() { return PageModule.isDark }

  get currentPayment() {
    return this.autopay?.paymentToken[this.paymentIndex]
  }

  get isUpdated() {
    return JSON.stringify(this.oldAutopay) !== JSON.stringify(this.autopay) || this.paymentIndex !== this.oldPaymentIndex
  }

  get formettedAutopay() {
    const limit = (this.autopay?.limit.value || 0) + ''

    return {
      places: this.autopay?.places || [],
      limit: limit.replaceAll(' ', ''),
      paymentTokenId: this.currentPayment?.scopeId,
      paymentEmail: this.autopay?.paymentEmail.value
    }
  }

  @HandleApiRequest({ prop: 'isPayLoading' })
  async handleClick() {
    this.autopay = await AutopayModule.saveAutopay(this.formettedAutopay)
    this.oldAutopay = JSON.parse(JSON.stringify(this.autopay))
    this.oldPaymentIndex = this.paymentIndex

    cleave.setRawValue(this.autopay?.limit.value)
    PageModule.SEND_NOTIFICATION({ type: 'success', message: this.autopay.successSaveMessage })
  }

  @HandleApiRequest()
  async onDeletePaymentToken(token: IAutopayToken) {
    if (confirm(`Вы точно хотите удалить карту ${token.title}`)) {
      await AutopayModule.deleteCard(token.scopeId)
      if (this.autopay !== null) {
        const deleteIndex = this.autopay.paymentToken.findIndex(({ scopeId }) => scopeId === token.scopeId)

        this.autopay.paymentToken.splice(deleteIndex, 1)

        if (deleteIndex === this.paymentIndex) {
          this.paymentIndex = -1
        } else if (deleteIndex < this.paymentIndex) {
          this.paymentIndex--
        }

        if (this.paymentIndex === -1 && this.autopay.paymentToken.length > 1) {
          this.paymentIndex = 0
        }

        if (deleteIndex === this.oldPaymentIndex) {
          this.oldPaymentIndex = -1
        } else if (deleteIndex < this.oldPaymentIndex) {
          this.oldPaymentIndex--
        }
      }
    }
  }

  onValueChanged({ target }: { target: HTMLInputElement | any }) {
    this.$nextTick(() => {
      if (this.autopay) {
        this.autopay.limit.value = target.value
        this.$emit('input', target.rawValue)
      }
    })
  }

  updatePayment(item: number | IAutopayToken) {
    let index = 0
    let payment = this.autopay?.paymentToken[index]

    if (typeof item === 'number') {
      index = item
      payment = this.autopay?.paymentToken[item]
    } else {
      payment = item
      index = this.autopay?.paymentToken.findIndex(({ scopeId }) => scopeId === item.scopeId) || 0
    }

    if (!payment) return

    if (payment.type !== 'new') {
      this.paymentIndex = index
    } else {
      window.open(payment.paymentLink, '_blank')
    }
  }

  @HandleApiRequest()
  async mounted() {
    const autopay = await AutopayModule.getAutopay()

    this.autopay = autopay

    const { isActiveAll } = this.$route.query

    if (this.autopay.paymentToken[0].type === 'new') {
      this.paymentIndex = -1
      this.oldPaymentIndex = -1
    } else {
      const index = this.autopay?.paymentToken.findIndex(({ isDefault }) => isDefault)
      this.paymentIndex = index
      this.oldPaymentIndex = index
    }

    if (isActiveAll === 'true' && this.autopay) {
      this.autopay.places = this.autopay.places.map(place => {
        place.types = place.types.map(type => Object.assign(type, { enabled: true }))
        place.enabled = true

        return place
      })
    }

    setTimeout(() => {
      const input = (this.$refs.paymentInput as Vue).$el.querySelector('input')

      cleave = new Cleave(input, {
        numeral: true,
        delimiter: ' ',
        numeralThousandsGroupStyle: 'thousand',
        onValueChanged: this.onValueChanged.bind(this)
      })

      cleave.setRawValue(this.autopay?.limit.value)
      this.$nextTick(() => { this.oldAutopay = JSON.parse(JSON.stringify(this.autopay)) })
    })
  }
}
