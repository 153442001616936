
import { Component, Vue } from 'vue-property-decorator'
import { InfoModule } from '@/store/info'

@Component
export default class FaqPage extends Vue {
  isLoading = this.faq.length

  get faq() {
    return InfoModule.faq
  }

  async mounted() {
    try {
      this.isLoading = true
      await InfoModule.getFaq()
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error)
    }
  }
}
