
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { GetCounterChart } from '@/model/page/counter'
import { PageModule } from '@/store/page'

type ChartType = Array<ChartItem>

interface ChartItem {
  data: Array<Array<number>>
  year: string
}

@Component
export default class CounterChart extends Vue {
  @Prop({ required: true }) info!: GetCounterChart
  yearsIndex = 0
  currentIndex: null | number = null
  maxHeight = 150

  get isDark() { return PageModule.isDark }

  get maxValue() {
    return Math.max(...this.charts[this.yearsIndex].data.flat())
  }

  get infoBlocks() {
    return this.info.items.map(({ color, measureUnitTitle: measure, title, years }) => {
      const obj: Record< 'title' | 'color' | 'measure' | 'value', string> = { title, color, measure, value: '0' }

      if (this.currentIndex !== null) {
        obj.value = years[this.yearsIndex].months[this.currentIndex]?.delta || '0'
      } else {
        obj.value = years[this.yearsIndex].summary.delta + ''
      }

      return obj
    })
  }

  labels = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']

  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    mousewheel: true
  }

  get charts(): ChartType {
    if (!Object.keys(this.info).length) {
      return []
    }

    const years: any = {}

    this.info.items.forEach((item) => {
      item.years.forEach(year => {
        if (!years[year.id]) {
          years[year.id] = {
            data: []
          }
        }

        const months = year.months.map((month) => parseInt(month?.delta || '0'))
        years[year.id].data.push(months)
      })
    })
    const charts = Object.entries(years).map(([year, info]) => {
      return {
        year,
        ...info as any
      }
    })

    return charts
  }

  mounted() {
    document.documentElement.addEventListener('keydown', this.handleKeydown)

    this.yearsIndex = this.info.items[0].years.length - 1
  }

  beforeDestroy() {
    document.documentElement.removeEventListener('keydown', this.handleKeydown)
  }

  handleKeydown(event: KeyboardEvent) {
    const isEscape = event.which === 27 || event.key === 'Escape'

    if (isEscape) {
      this.currentIndex = null
    }
  }

  toZero() {
    this.currentIndex = null
  }

  activateBar(i: number) {
    if (this.currentIndex === i) {
      this.currentIndex = null
    } else {
      this.currentIndex = i
    }
  }

  isEmpty(i: number) {
    const value = this.charts[this.yearsIndex].data.map((values) => values[i])

    return !value.find(item => item !== 0)
  }

  generateHeight(i: number, j: number) {
    const value = this.charts[this.yearsIndex].data[j][i]
    let percent = 100 / (this.maxValue / value)

    if (percent < 3) {
      percent = 3
    }

    return `${percent * (this.maxHeight / 100)}px`
  }

  generateColor(j: number) {
    return this.info.items[j].color
  }
}
