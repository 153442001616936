
import { Component, Vue } from 'vue-property-decorator'
import { PageModule } from '@/store/page'

import { InvoicesModule } from '@/store/invoices'

import InvoicesTopCard from '@/components/page/invoices/InvoicesTopCard.vue'
import InvoicesCalendar from '@/components/page/invoices/InvoicesCalendar.vue'
import InvoicesDetailInfo from '@/components/page/invoices/InvoicesDetailInfo.vue'
import InvoicesDetail from '@/components/page/invoices/InvoicesDetail.vue'
import AppPlaceButtonPay from '@/components/general/AppPlaceButtonPay.vue'

// eslint-disable-next-line no-unused-vars
import { InvocesPageId } from '@/model/page/invoices'

@Component({
  components: {
    InvoicesDetail,
    InvoicesTopCard,
    InvoicesCalendar,
    AppPlaceButtonPay,
    InvoicesDetailInfo
  }
})
export default class InvoicesPage extends Vue {
  isLoading = false
  detailLoading = false
  invoiceLoading = false
  showButtonPay = true

  get isRouteAnimating() {
    return PageModule.isRouteAnimating
  }

  get invoice() {
    return InvoicesModule.invoice
  }

  get invoices() {
    return InvoicesModule.invoices
  }

  get invoiceDetail() {
    return InvoicesModule.invoiceDetail
  }

  get settingsPayment() {
    const {
      paymentButtonLink: link,
      paymentButtonTitle: buttonTitle,
      debtTitle: title,
      title: text,
      hasPaymentButton: showButton,
      isAvailable,
      placeholder,
      tooltip: info,
      debtSubTitle: subTitle,
      paymentContext: context
    } = this.invoices

    return {
      link: context ? `/pay/init?c=${context}` : link,
      text,
      info,
      title,
      subTitle,
      showButton,
      isAvailable,
      placeholder,
      buttonTitle,
      context
    }
  }

  get items() {
    return this.invoices.items
  }

  get showHistory() {
    return this.invoices.isPaymentHistoryAvailable
  }

  async created() {
    try {
      this.isLoading = true
      await InvoicesModule.getInvoices()
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}
