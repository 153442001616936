
import { Component, Vue, Watch } from 'vue-property-decorator'

import AdvertPageLayout from '@/components/page/adverts/AdvertPageLayout.vue'
import { AdvertModule } from '@/store/advert'

// eslint-disable-next-line no-unused-vars
import { IAdvertLayout, IAdvertFilterReachType, IAdvertFilterReachTypeItem, IAdvertFilterMultilevel, IAdvertFilterMultilevelItem } from '@/model/page/adverts'
import { HandleApiRequest } from '@/utils/helper'

type formType = Record<string, number[] | IAdvertFilterMultilevelItem[]>
type formValuesType = Record<string, IAdvertFilterMultilevel | IAdvertFilterReachType>

@Component({
  components: {
    AdvertPageLayout
  }
})
export default class AdvertsPage extends Vue {
  filterDialogView = false
  loadingFilter = true
  isLoading = false

  form = {} as formType
  formValues = {} as formValuesType

  get advertsFilter() {
    return AdvertModule.filterOptions
  }

  get hasFilter() {
    const queryKey = Object.keys(this.$route.query)

    return queryKey.includes('advertCategoryId')
  }

  get adverts() {
    return AdvertModule.adverts
  }

  get queryForm() {
    const query = Object.entries(this.form)

    if (!query.length) {
      return this.$route.query
    }

    return query.reduce((acc: Record<string, string>, [key, value]) => {
      if (typeof value[0] === 'object') {
        acc[key] = (value as IAdvertFilterMultilevelItem[])
          .filter(({ values }) => Array.isArray(values) && !values.length)
          .map(item => item.value)
          .join(',')
      } else {
        acc[key] = value + ''
      }

      return acc
    }, {})
  }

  @Watch('queryForm')
  async updateSearch(query: Object, oldQuery: Object) {
    if (JSON.stringify(query) === JSON.stringify(oldQuery)) { return }

    this.loadingFilter = true
    await AdvertModule.filterAdverts(this.queryForm)
    this.loadingFilter = false
  }

  async viewFilteredAdverts() {
    AdvertModule.SET_STATE_ADVERT({ key: 'adverts', value: [] })
    AdvertModule.getAdverts({ params: this.queryForm })
    this.$router.replace({ query: this.queryForm })

    this.filterDialogView = false
  }

  cancelFilter() {
    this.$router.replace({})
    AdvertModule.getAdverts()
    this.setFormValues()
  }

  toggleCategory(name: string, category: IAdvertFilterMultilevelItem, filter: IAdvertFilterMultilevel) {
    let formElement = this.form[name] as IAdvertFilterMultilevelItem[]

    if (!filter.multiple) {
      // Удалить все категории
      formElement = formElement.filter(({ values }) => Array.isArray(values) && values.length)
      formElement.push(category)

      this.form[name] = formElement
      return
    }

    const hasCategory = formElement.find(({ value }) => value === category.value)

    if (hasCategory) {
      formElement = formElement.filter(({ value }) => value !== category.value)
    } else {
      formElement.push(category)
    }

    this.form[name] = formElement
  }

  updateCheckbox(element: IAdvertFilterReachType, input: IAdvertFilterReachTypeItem, event: boolean) {
    let formElement = this.form[element.name] as number[]
    const hasItem = formElement.includes(input.value)

    if (!hasItem) {
      if (element.multiple === false) {
        formElement = []
      }

      formElement.push(input.value)
    } else {
      if (formElement.length !== 1) {
        formElement = formElement.filter((value) => value !== input.value)
      }
    }

    this.form[element.name] = formElement
  }

  setFormValues() {
    const { query } = this.$route

    this.form = this.advertsFilter.filters
      .reduce((acc: formType, item) => {
        acc[item.name] = []

        if (item.type === 'tags') {
          acc[item.name] = item.values.filter(item => item.selected).map((item) => item.value)
        }

        if (query[item.name]) {
          const id = parseInt(query[item.name] as string || '0')
          let treeFilters: Array<IAdvertFilterMultilevelItem> = []
          let finedId = false

          if (item.type === 'multilevel') {
            const handleMultilevel = (arr: Array<IAdvertFilterMultilevelItem>) => {
              return arr.forEach(element => {
                if (element.value === id) {
                  treeFilters.push(element)
                  finedId = true
                }

                if (!finedId && Array.isArray(element.values) && element.values.length) {
                  treeFilters.push(element)
                  handleMultilevel(element.values)

                  if (!finedId) {
                    treeFilters = treeFilters.filter(({ value }) => value !== element.value)
                  }
                }
              })
            }
            const rootItem = item.values

            handleMultilevel(rootItem.values as Array<IAdvertFilterMultilevelItem>)

            acc[item.name] = treeFilters
          }
        }

        return acc
      }, {})

    this.formValues = this.advertsFilter.filters
      .reduce((acc: formValuesType, item) => {
        if (item.type === 'multilevel') {
          const handledItem = Array.isArray(item.values) ? item : item.values
          acc[item.name] = handledItem
        } else {
          acc[item.name] = item
        }

        return acc
      }, {})
  }

  @HandleApiRequest()
  async mounted() {
    this.loadingFilter = true
    await AdvertModule.filterAdverts(this.queryForm)
    this.loadingFilter = false

    this.setFormValues()
  }
}
