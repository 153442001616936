
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IInvoiceCalendar } from '@/model/page/invoices'

import { InvoicesModule } from '@/store/invoices'

import InvoicesCalendar from '@/components/page/invoices/InvoicesCalendar.vue'
import TabRequisites from '@/components/page/invoices/tabs/TabRequisites.vue'
import TabAccounts from '@/components/page/invoices/tabs/TabAccounts.vue'
import TabCosts from '@/components/page/invoices/tabs/TabCosts.vue'

@Component({
  components: {
    InvoicesCalendar,
    TabRequisites,
    TabAccounts,
    TabCosts
  }
})
export default class InvoiceDesktopLayout extends Vue {
  tabsModel = 0

  @Prop({ type: String }) type!: string

  get hasCalendar() {
    return this.type !== 'invoice'
  }

  get invoiceInner() {
    return InvoicesModule.invoice
  }

  get invoice() {
    return InvoicesModule.invoice
  }

  get scopeTypeId() {
    return this.$route.query.scopeTypeId as string || '-1'
  }

  get scopeId() {
    return this.$route.query.scopeId as string || '-1'
  }

  get tabs(): Array<{ title: string, component: string }> {
    if (this.invoice) {
      return [
        { title: 'Реквизиты', component: 'TabRequisites' },
        { title: 'Тарифы', component: 'TabCosts' }
      ]
    }

    return []
  }

  tabProps(component: string): Object {
    if (component === 'TabInvoices') {
      return { invoice: this.invoice }
    }
    return { invoiceId: this.invoice && this.invoice.id }
  }

  async selectInvoice(detailId: number) {
    // @ts-ignore
    this.$router.push({ name: 'invoicesDetail', params: { detailId }, query: { scopeTypeId: this.scopeTypeId, scopeId: this.scopeId } })
  }
}

