
import { Component, Vue } from 'vue-property-decorator'
import { HandleApiRequest } from '@/utils/helper'

import { InvoicesModule } from '@/store/invoices'

// eslint-disable-next-line no-unused-vars
import { InvocesPageId } from '@/model/page/invoices'

import InvoicesInfoBlock from '@/components/page/invoices/InvoicesInfoBlock.vue'
import InvoicesDetail from '@/components/page/invoices/tabs/TabInvoicesDetail.vue'
import InvoicesCalendar from '@/components/page/invoices/InvoicesCalendar.vue'
import InvoiceMobileLayout from '@/components/page/invoices/InvoiceMobileLayout.vue'
import InvoiceDesktopLayout from '@/components/page/invoices/InvoiceDesktopLayout.vue'

@Component({
  components: {
    InvoicesDetail,
    InvoicesCalendar,
    InvoicesInfoBlock,
    InvoiceMobileLayout,
    InvoiceDesktopLayout
  }
})
export default class InvoicesIdPage extends Vue {
  isLoading = true

  detailLoading = false
  isDetailModalShow = false

  get invoice() {
    return InvoicesModule.invoice
  }

  get type() {
    return this.$route.query.type || 'invoice-calendar'
  }

  get scopeTypeId() {
    return this.$route.query.scopeTypeId as string || '-1'
  }

  get scopeId() {
    return this.$route.query.scopeId as string || '-1'
  }

  get infoBlock() {
    const {
      title,
      image,
      debtTitle,
      debtSubTitle,
      debtInfo: info,
      paperButtonLink,
      paperButtonTitle,
      accountNumberTitle: subTitle,
      paymentButtonLink: buttonLink,
      paymentButtonTitle: buttonTitle,
      paymentContext: buttonPaymentContext,
      paymentAutomaticallyTitle,
      tooltip
    } = this.invoice

    const isAutopay = !!paymentAutomaticallyTitle

    return {
      info,
      title,
      image,
      subTitle,
      isAutopay,
      debtTitle,
      buttonLink: buttonPaymentContext ? `/pay/init?c=${buttonPaymentContext}` : buttonLink,
      buttonTitle,
      debtSubTitle: isAutopay ? paymentAutomaticallyTitle : debtSubTitle,
      type: this.type,
      paperButtonLink,
      paperButtonTitle,
      buttonPaymentContext,
      tooltip
    }
  }

  @HandleApiRequest()
  async created() {
    const { id } = this.$route.params

    if (this.type === 'invoice') {
      await InvoicesModule.getInvoicesId({ id, scopeId: this.scopeId, scopeTypeId: this.scopeTypeId })
    } else {
      await InvoicesModule.getInvoicesCalendarId({ id, scopeId: this.scopeId, scopeTypeId: this.scopeTypeId })
    }
  }
}
