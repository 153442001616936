
import { Component, Vue } from 'vue-property-decorator'
import { CounterModule } from '@/store/counter'
import { PageModule } from '@/store/page'

import CounterChart from '@/components/page/counter/CounterChart.vue'
import CounterForm from '@/components/page/counter/CounterForm.vue'

// eslint-disable-next-line no-unused-vars
import { GetCounterChart, ICounterFormLayout, ICounterFormSubmit, ICounterFormSubmitItem } from '@/model/page/counter'

@Component({
  components: {
    CounterChart,
    CounterForm
  }
})
export default class CountersIdPage extends Vue {
  info: GetCounterChart = {} as GetCounterChart
  form: ICounterFormLayout = {} as ICounterFormLayout
  isLoading = !Object.keys(this.info) && !Object.keys(this.form)
  showDotAlert = false

  get isReadonly() {
    return !this.form.isAllowed
  }

  async handleSubmit() {
    try {
      const _form = (this.$refs['counter-form'] as CounterForm).form
      const form: ICounterFormSubmit = {
        meteringData: _form.items.filter(item => item.isAllowed).map(item => {
          const res = {
            meteringDeviceId: item.meteringDeviceId
          } as ICounterFormSubmitItem

          Array.from(new Array(item.measureMethod)).forEach((_, index) => {
            const value = item[`value${index + 1}` as 'value1'] || ''

            if (value && value.includes('.') && this.form.isInteger) {
              this.showDotAlert = true
            }

            res[`value${index + 1}` as 'value1'] = item[`value${index + 1}` as 'value1'] || ''
          })
          console.log(item)
          return res
        })
      }

      if (this.showDotAlert) return

      const { id } = this.$route.params

      await CounterModule.submitForm({ form, meteringGroupId: id })

      this.$router.go(0)
    } catch (error) {
      this.$handleApiError(error)
    }
  }

  async mounted() {
    try {
      this.isLoading = true

      const { id } = this.$route.params
      const { isGeneral } = this.$route.query
      const params = { meteringGroupId: id, isGeneral: isGeneral } as Record<string, string>

      this.info = await CounterModule.getCounterChart(params)
      this.form = await CounterModule.getCounterForm(params)

      PageModule.SET_STATE_PAGE({
        key: 'breadCrumbsTitle',
        value: this.form.meteringGroupTitle || 'Счетчики'
      })

      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}
