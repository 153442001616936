import Vue from 'vue'
import VueI18n from 'vue-i18n'

// import { ru, en } from 'vuetify/lib/locale'
import en from '@/locales/en.ts'
import ru from '@/locales/ru.ts'

Vue.use(VueI18n)

const messages = {
  en: {
    ...en,
    $vuetify: en.vuetify
  },
  ru: {
    ...ru,
    $vuetify: ru.vuetify
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ru',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ru',
  messages
})
