
import { Component, Vue, Prop } from 'vue-property-decorator'
import { InfoModule } from '@/store/info'

@Component
export default class InfoContacts extends Vue {
  @Prop({ type: String }) route!: string

  isLoading = false
  word = {
    addresses: 'Адрес',
    phones: 'Телефон',
    websites: 'Сайт',
    emails: 'Email',
    social: 'В соцсетях'
  }

  get contacts() {
    return InfoModule.contacts
  }

  async mounted() {
    try {
      this.isLoading = true
      await InfoModule.getContacts(this.route)
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error)
    }
  }
}
