
import { Vue, Component, Prop } from 'vue-property-decorator'
import EditNotification from '@/components/page/place/EditNotification.vue'
import AppPhoneField from '@/components/general/AppPhoneField.vue'
import { isSupported } from 'firebase/messaging'
import dayjs from 'dayjs'

import { UserModule } from '@/store/user'
import { PlaceModule } from '@/store/place'

// eslint-disable-next-line no-unused-vars
import { IHuman, IPeopleRole } from '@/model/page/counter'
import { $put } from '@/plugins/axios'
import { HandleError } from '@/utils/helper'
import { AxiosError } from 'axios'
import { PageModule } from '@/store/page'
import AppDateTimePicker from '@/components/app/AppDatePicker.vue'
import AppDatePickerBirthday from '@/components/app/AppDatePickerBirthday.vue'

enum placeToCustomerStatuses {
  OWNER = 1,
  FAMILY_MEMBER = 2,
  REPRESENTATIVE = 3,
  TENANT = 4,
  GUEST = 100,
}

@Component({
  components: {
    AppDatePickerBirthday,
    AppDateTimePicker,
    EditNotification,
    AppPhoneField
  }
})
export default class PeopleInfo extends Vue {
  @Prop({ required: true, type: Object }) human!: IHuman
  @Prop({ type: Boolean, default: false }) isPersonal!: Boolean

  moment = dayjs;
  deleteModalView = false
  isSaveLoading = false
  paymentDay = this.human.paymentDay
  roles: IPeopleRole[] = []

  editHumanModalShow = false

  rules = {
    required: (value: string) => !!value || 'Поле обязательно для заполнения'
  }

  get user() {
    return UserModule.user
  }

  get date() {
    return this.isPersonal ? this.human.birthDate : dayjs(this.human.createdAt * 1000).format('DD MMMM YYYY')
  }

  get isNotificationsEnabled(): boolean {
    const supportNotification = Object.prototype.hasOwnProperty.call(window, 'Notification')
    const isSupportedFirebase = isSupported()
    if (!supportNotification || !isSupportedFirebase) return false

    return Boolean(localStorage.getItem('pushToken'))
  }

  get isOwner(): boolean {
    return this.human.placeToCustomerStatusId === placeToCustomerStatuses.OWNER
  }

  get isGuest(): boolean {
    return this.human.placeToCustomerStatusId === placeToCustomerStatuses.GUEST
  }

  get isTenant(): boolean {
    return this.human.placeToCustomerStatusId === placeToCustomerStatuses.TENANT
  }

  get placeToCustomerStatuses() {
    return placeToCustomerStatuses
  }

  async fetchRoles() {
    return await PlaceModule.getPeopleRoles()
  }

  async deleteHuman() {
    const data = await PlaceModule.deleteHuman(this.human.id)

    if (data.success) {
      this.$router.go(-1)
    }
  }

  async updateHuman(): Promise<void> {
    if (this.human.firstName === '') {
      (this.$refs.firstName as HTMLInputElement).focus()
      return
    }

    if (!this.human?.dateBirth) this.human.dateBirth = this.human.birthDate
    delete this.human.birthDate

    try {
      this.isSaveLoading = true
      const guest = await $put(`currentplace/updateCustomer/${this.human.id}`, this.human)
      this.human = guest.data
      this.editHumanModalShow = false
      this.paymentDay = this.human.paymentDay
      PageModule.SEND_NOTIFICATION({ type: 'success', message: 'Информация успешно изменена' })
    } catch (e) {
      HandleError(e as Error | AxiosError<any>)
    } finally {
      this.isSaveLoading = false
    }
  }

  saveBirthday(date: string) {
    this.human.birthDate = Math.round(new Date(date).getTime() / 1000)
  }

  async created() {
    this.roles = await this.fetchRoles()
  }
}
