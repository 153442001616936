
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class AppFrame extends Vue {
  @Prop({ type: String, required: true }) src!: string

  get dom() {
    if (!this.src) { return '' }

    const dom = this.src.match(/<body>[\s\d\w\S\D\W<>=\-\\///а-яА-Я"]{1,}/)
    if (dom && dom[0]) {
      return dom[0].replace('</html>', '')
    }
    return ''
  }
}
