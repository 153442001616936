
import { Component, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'

import { InvoicesModule } from '@/store/invoices'
import InvoicesPaymentsCard from '@/components/page/invoices/InvoicesPaymentsCard.vue'

@Component({
  components: {
    InvoicesPaymentsCard
  }
})
export default class InvoicesPaymentsPage extends Vue {
  isLoading = false
  isMoreLoading = false
  datePickerMenu = false
  dates: Array<string> = []
  datesModel: Array<string> = []

  get payments() {
    return InvoicesModule.payments
  }

  get isAvailable() {
    return InvoicesModule.isPaymentsAvailable
  }

  get placeholder() {
    return InvoicesModule.paymentsPlaceholder
  }

  get isMoreExist() {
    return InvoicesModule.isMorePaymentsExist
  }

  get datesText() {
    return this.paymentsDates.join(' ~ ')
  }

  get paymentsDates() {
    if (InvoicesModule.paymentsDates.start && InvoicesModule.paymentsDates.end) {
      const { start, end } = InvoicesModule.paymentsDates
      return [dayjs.unix(start).format('YYYY-MM-DD'), dayjs.unix(end).format('YYYY-MM-DD')]
    }
    return []
  }

  async setPaymentsDates(val: Array<string>) {
    try {
      this.dates = val
      if (this.dates.length === 2) {
        const timestamps = this.dates.map(date => dayjs(date).unix())
        if (timestamps[0] < timestamps[1]) {
          InvoicesModule.SET_STATE_INVOICES({ key: 'paymentsDates', value: { start: timestamps[0], end: timestamps[1] } })
        } else {
          InvoicesModule.SET_STATE_INVOICES({ key: 'paymentsDates', value: { start: timestamps[1], end: timestamps[0] } })
        }
        this.datePickerMenu = false
        await this.getPayments()
      }
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async created() {
    try {
      await this.getPayments()
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async deleteTimeRange() {
    this.dates = []
    this.datesModel = []
    InvoicesModule.SET_STATE_INVOICES({ key: 'paymentsDates', value: { start: null, end: null } })

    try {
      await this.getPayments()
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async getPayments() {
    try {
      InvoicesModule.SET_STATE_INVOICES({ key: 'payments', value: [] })
      InvoicesModule.SET_STATE_INVOICES({ key: 'paymentsNextRow', value: 0 })
      this.isLoading = true
      await InvoicesModule.getPayments()
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async getMorePayments(entries: Array<any>) {
    try {
      if (entries[0].isIntersecting) {
        this.isMoreLoading = true
        await InvoicesModule.getPayments()
        this.isMoreLoading = false
      }
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}
