
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PageModule } from '@/store/page'

// eslint-disable-next-line no-unused-vars
import { IButtonPayFilter } from '@/model'
import { HandleApiRequest } from '@/utils/helper'

@Component
export default class AppPlaceButtonPay extends Vue {
  @Prop({ default: true, type: Boolean }) mayShow!: Boolean

  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 10,
    mousewheel: true,
    touchRatio: Number(this.$vuetify.breakpoint.smAndDown)
  }

  get isUpdating() {
    return (PageModule.buttonPayLoading && Object.keys(this.info).length)
  }

  get isLoading() {
    return PageModule.buttonPayLoading
  }

  get payButton() {
    if (!this.info.buttons) { return null }

    return this.info?.buttons.find(({ name }) => name === 'pay')
  }

  get info() {
    return PageModule.buttonPay
  }

  get currentFilters() {
    if (!this.info.filters) return undefined

    return this.info.filters.map((filter) => filter.active ? filter.id : -1).filter(id => id !== -1)
  }

  get companyId() {
    if (!this.info.company) { return undefined }

    const company = this.info?.company.find(({ active }) => active)

    return company ? company.id : undefined
  }

  @HandleApiRequest({ prop: '' })
  async mounted() {
    await PageModule.getOnePaymentButton(this.finalQuery())
  }

  async clickPayment() {
    window.open(this.info.paymentContext ? `/pay/init?c=${this.info.paymentContext}` : this.info.paymentLink)
  }

  finalQuery({ placeTypeIds, companyId }: { placeTypeIds?: number[] | undefined | null, companyId?: number } = {}) {
    const query: { placeTypeIds?: string, companyId?: string } = {}
    placeTypeIds = placeTypeIds === null ? placeTypeIds : placeTypeIds || this.currentFilters
    companyId = companyId || this.companyId

    if (companyId) {
      query.companyId = companyId + ''
    }

    if (placeTypeIds) {
      query.placeTypeIds = placeTypeIds.join(',') || '-1'
    }

    return query
  }

  changeSelection(value: number) {
    PageModule.getOnePaymentButton(this.finalQuery({ companyId: value, placeTypeIds: null }))
  }

  updateFilter(filter: IButtonPayFilter) {
    if (!this.currentFilters) return

    if (this.currentFilters.includes(filter.id)) {
      const placeTypeIds = this.currentFilters.filter((id) => id !== filter.id)

      PageModule.getOnePaymentButton(this.finalQuery({ placeTypeIds }))
    } else {
      const placeTypeIds = this.currentFilters.concat(filter.id)

      PageModule.getOnePaymentButton(this.finalQuery({ placeTypeIds }))
    }
  }
}
