

import { Component, Vue } from 'vue-property-decorator'

import { InvoicesModule } from '@/store/invoices'

// eslint-disable-next-line no-unused-vars
import { InvocesPageId } from '@/model/page/invoices'
import { PageModule } from '@/store/page'

@Component
export default class RequisitesPage extends Vue {
  isLoading = false

  get requisites() {
    return InvoicesModule.requisites
  }

  get scopeTypeId() {
    return this.$route.query.scopeTypeId as string || '-1'
  }

  get scopeId() {
    return this.$route.query.scopeId as string || '-1'
  }

  async created() {
    PageModule.SET_STATE_PAGE({
      key: 'routeReturnFunciton',
      value: (url: string) => {
        this.$router.go(-1)
      }
    })

    try {
      this.isLoading = true
      const { id } = this.$route.params
      await InvoicesModule.getRequisites({ id, scopeTypeId: this.scopeTypeId, scopeId: this.scopeId })
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}
