
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IInvoiceCalendar } from '@/model/page/invoices'

import { PageModule } from '@/store/page'

@Component
export default class InvoicesCalendar extends Vue {
  @Prop({ default: () => [] }) readonly calendar!: Array<IInvoiceCalendar>

  activeInvoice: null | number = null
  currentYear = 0

  get isDark() { return PageModule.isDark }

  get isPrevYearExist() {
    return Boolean(this.calendar[this.currentYear + 1])
  }

  get isNextYearExist() {
    return Boolean(this.calendar[this.currentYear - 1])
  }

  clickMonth(invoiceId: number) {
    this.activeInvoice = invoiceId
    this.$emit('openDetail', invoiceId)
  }

  setNextMonth() {
    this.calendar.forEach((year, i) => {
      if (this.activeInvoice) { return }
      const { invoiceId } = year.months.reverse().find(i => i.invoiceId) || {}
      if (invoiceId) {
        this.$emit('openDetail', invoiceId)
        this.activeInvoice = invoiceId
        this.currentYear = i
      }
      year.months.reverse()
    })
  }
}

