
import { Component, Vue, Prop } from 'vue-property-decorator'
import { CounterModule } from '@/store/counter'
import { HandleApiRequest } from '@/utils/helper'

import CounterBlock from '@/components/page/counter/CounterBlock.vue'

// eslint-disable-next-line no-unused-vars
import { IntersectionObserverEntry, EventLayout } from '@/model/index'

@Component({
  components: {
    CounterBlock
  }
})
export default class HistoryPage extends Vue {
  isLoading = true
  isMoreLoading = false
  nextRow = 0

  @Prop({ type: Boolean }) isLayout!: boolean

  get isEmptyHistory() {
    return Object.keys(this.history).length === 0
  }

  get history() {
    return CounterModule.history.reduce((final: any, arr: any) => {
      const isExistDateIndex = final.findIndex((item: any) => item.title === arr.date)

      if (isExistDateIndex !== -1) {
        final[isExistDateIndex].items.push(arr)
      } else {
        final.push({
          title: arr.date,
          items: [arr]
        })
      }

      return final
    }, [])
  }

  @HandleApiRequest({ prop: 'isMoreLoading' })
  async loadMore(entries: Array<IntersectionObserverEntry>) {
    if (entries[0].isIntersecting) {
      this.nextRow = await CounterModule.getEvents({ eventTypeIds: 4, fromRow: this.nextRow })
    }
  }

  get isMoreExist() {
    return this.nextRow > 1
  }

  @HandleApiRequest()
  async mounted() {
    CounterModule.SET_STATE_COUNTER({ key: 'history', value: [] })

    this.nextRow = await CounterModule.getEvents({ eventTypeIds: 4, fromRow: this.nextRow })
  }
}
