
import { Vue, Component, Prop } from 'vue-property-decorator'
import { PageModule } from '@/store/page'

import { NewsFeedModule } from '@/store/newsFeed'
import { HandleApiRequest } from '@/utils/helper'
import { $put } from '@/plugins/axios'

@Component
export default class NewsIdPage extends Vue {
  @Prop({ type: String, default: '' }) serviceId!: string | number;

  isLoading: boolean = false
  swiperOptions = {
    watchOverflow: true,
    navigation: {
      nextEl: '.swiper-arrow--next',
      prevEl: '.swiper-arrow--prev'
    },
    pagination: {
      el: '.swiper-pagination'
    }
  }

  get news() {
    return NewsFeedModule.newsId
  }

  async sendReaction(id: number) : Promise<void> {
    try {
      const { data } = await $put(`news/${this.news.id}/reaction/${id}`)
      this.news!.reactions = data.reactions
    } catch (e) {
      this.$handleApiError(e, this)
    }
  }

  @HandleApiRequest()
  async mounted() {
    PageModule.SET_STATE_PAGE({
      key: 'routeReturnFunciton',
      value: (url: string) => {
        this.$router.push({ name: 'newsFeed' })
      }
    })

    const id = this.$route.params.id
    await NewsFeedModule.getNewsDetail(id)
  }
}

