
import { Component, Vue } from 'vue-property-decorator'

import { InvoicesModule } from '@/store/invoices'

@Component
export default class InvoicesDetailInfo extends Vue {
  get invoice() {
    return InvoicesModule.invoiceDetail
  }
}
