
import { Component, Vue } from 'vue-property-decorator'

import { NewsFeedModule } from '@/store/newsFeed'
import { PageModule } from '@/store/page'

// eslint-disable-next-line no-unused-vars
import { ICommunicationsFull } from '@/model/page/newsFeed'

@Component
export default class CommunicationsIdPage extends Vue {
  isLoading = false
  communication = {} as ICommunicationsFull

  async created() {
    try {
      PageModule.SET_STATE_PAGE({
        key: 'routeReturnFunciton',
        value: (url: string) => {
          this.$router.push({ name: 'newsFeed', query: { type: 'service' } })
        }
      })

      this.isLoading = true
      const { id } = this.$route.params
      this.communication = await NewsFeedModule.getCommunication(id)

      await NewsFeedModule.viewCommunication(id)
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}

