
import { Component, Vue, Prop } from 'vue-property-decorator'
import AudioRecorder from 'audio-recorder-polyfill'
import { AudioContext } from 'standardized-audio-context'
import { PageModule } from '@/store/page'

// eslint-disable-next-line no-unused-vars
import { IMessage } from '@/model/page/chat'

type IMessageOptions = Array<{ title: string; callback: Function }>
let audioChunks: any[]
let analyser: any
let dataArray: any
let audioContext: any
let bufferLength: any
let timerInterval: any

@Component
export default class RecordController extends Vue {
  @Prop() readonly disabled!: boolean

  activator: Element | null = null
  mediaRecorder = null as any

  isActive = false
  isCancel = false
  stream: any = null
  timer = 0
  volume = 1
  show = 'mediaDevices' in navigator
  microphonePermissionStatus: PermissionState = 'prompt'

  get color() {
    return this.$vuetify.theme.currentTheme.primary + ''
  }

  mounted() {
    this.activator = (this.$refs.activator as Vue).$el

    if (this.activator) {
      this.activator.addEventListener('touchstart', this.handleTouchStart, { passive: false })
      this.activator.addEventListener('touchend', this.handleTouchEnd, { passive: false })
      this.activator.addEventListener('mousedown', this.toggleRecord, { passive: false })
    }
  }

  beforeDestroy() {
    if (this.activator) {
      this.activator.removeEventListener('touchstart', this.handleTouchStart)
      this.activator.removeEventListener('touchend', this.handleTouchEnd)
      this.activator.removeEventListener('mousedown', this.toggleRecord)
    }
  }

  handleTouchStart(event: Event) {
    if (event.cancelable) {
      event.stopPropagation()
      event.preventDefault()
    }

    this.startRecord()
  }

  handleTouchEnd(event: Event) {
    if (event.cancelable) {
      event.stopPropagation()
      event.preventDefault()
    }

    this.endRecord()
  }

  toggleRecord() {
    this.isActive ? this.endRecord() : this.startRecord()
  }

  async startRecord() {
    if (this.isActive) {
      return
    }

    const self = this

    try {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' })
      this.microphonePermissionStatus = permissionStatus.state

      permissionStatus.onchange = function() {
        self.microphonePermissionStatus = this.state
        if (this.state === 'granted') {
          self.startRecord()
        }
      }

      if (this.microphonePermissionStatus !== 'granted') {
        if (this.microphonePermissionStatus === 'prompt') {
          return navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
              if (stream) {
                if (stream.getTracks) {
                  stream.getTracks().forEach((stream: MediaStreamTrack) => stream.stop())
                  return
                }

                this.stream.stop()
              }

              this.startRecord()
            })
            .catch(() => {
              this.$emit('cancelRecord')
            })
        } else if (this.microphonePermissionStatus === 'denied') {
          return PageModule.SEND_NOTIFICATION({ type: 'error', message: 'Нет доступа к микрофону' })
        }
        return
      }
    } catch (error) {
      console.log(error)
    }

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        this.stream = stream
        this.mediaRecorder = new AudioRecorder(stream)
        this.mediaRecorder.start()

        this.mediaRecorder.addEventListener('dataavailable', (event: any) => {
          audioChunks.push(event.data)

          const audioBlob = new Blob(audioChunks, { type: 'audio/wav' })

          this.isActive = false

          if (this.isCancel) {
            this.isCancel = false
          } else if (this.timer < 2) {
            this.$emit('cancelRecord')
          } else {
            this.$emit('finishRecord', audioBlob)
          }

          this.timer = 0
        })

        timerInterval = setInterval(() => {
          this.timer += 0.1
        }, 100)

        audioChunks = []
        audioContext = new AudioContext()
        const source = audioContext.createMediaStreamSource(stream)

        analyser = audioContext.createAnalyser()
        analyser.fftSize = 2048
        bufferLength = analyser.frequencyBinCount
        dataArray = new Uint8Array(bufferLength)

        source.connect(analyser)

        this.isActive = true
        this.$emit('startRecord')

        this.$nextTick(() => {
          this.draw()
        })
      })
      .catch(() => {
        this.$emit('cancelRecord')
      })
  }

  endRecord() {
    if (timerInterval) {
      clearInterval(timerInterval)
    }

    if (this.stream) {
      if (this.stream.getTracks) {
        this.stream.getTracks().forEach((stream: MediaStreamTrack) => stream.stop())
      } else {
        this.stream.stop()
      }
    }

    this.mediaRecorder.stop()
  }

  cancelRecord() {
    if (this.isActive) {
      this.isCancel = true
    }
    this.endRecord()
  }

  draw() {
    if (!this.isActive) return

    analyser.getByteTimeDomainData(dataArray)
    const maxValue = Math.max(...dataArray)

    this.volume = maxValue / 128
    this.$emit('updateVolume', { volume: maxValue / 128, time: this.timer })

    requestAnimationFrame(this.draw)
  }
}
