
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IPlacesLayout, IHuman, IPlaceCard } from '@/model/page/counter'

import PeopleInfo from '@/components/page/place/PeopleInfo.vue'
import PeopleAvatar from '@/components/page/place/PeopleAvatar.vue'
import { UserModule } from '@/store/user'

@Component({
  components: {
    PeopleInfo,
    PeopleAvatar
  }
})
export default class PeopleIdLayout extends Vue {
  @Prop({ type: Object, required: true }) human!: IHuman
  @Prop({ type: Boolean, default: false }) isPersonal!: Boolean
  get user() {
    return UserModule.user
  }
}
