
import { Vue, Component, Prop } from 'vue-property-decorator'

import { PassModule } from '@/store/pass'

// eslint-disable-next-line no-unused-vars
import { IPass, PassStatus } from '@/model/page/pass'

@Component
export default class PassBlock extends Vue {
  @Prop({ required: true }) pass!: IPass
  @Prop({ default: false, type: Boolean }) isPermanent!: boolean

  cancelModalView = false

  get generateUrlForm() {
    const routeName = this.isPermanent ? 'placesPassesPermanentForm' : 'placesPassesForm'

    return { name: routeName, query: { id: this.pass.id } }
  }

  async cancelPass() {
    try {
      await PassModule.cancelPass({ id: this.pass.id, isPermanent: this.isPermanent })
    } catch (error) {
      this.$handleApiError(error, this)
    } finally {
      this.cancelModalView = false
    }
  }
}
