
import { Component, Vue } from 'vue-property-decorator'

import { PlaceModule } from '@/store/place'
import { PageModule } from '@/store/page'

import upperFirst from 'lodash/upperFirst'

// eslint-disable-next-line no-unused-vars
import { IPlaceCard } from '@/model/page/counter'

import ActiveOrders from '@/components/page/place/ActiveOrders.vue'
import SpecificationPage from '@/views/places/specification.vue'
import HistoryPage from '@/views/places/orders.vue'
import PeoplePage from '@/views/places/people.vue'
import PassesPage from '@/views/places/passes.vue'
import DocumentsPage from '@/views/places/documents.vue'

import { HandleApiRequest } from '@/utils/helper'

@Component({
  components: {
    SpecificationPage,
    PassesPage,
    PeoplePage,
    HistoryPage,
    ActiveOrders,
    DocumentsPage
  }
})
export default class PlaceDesktopLayout extends Vue {
  isLoading = true
  activeView = ''

  get isDark() { return PageModule.isDark }

  get places() {
    return PlaceModule.places
  }

  get orders() {
    return PlaceModule.activeOrders
  }

  get allCards(): Array<IPlaceCard> {
    let cards: Array<IPlaceCard> = []
    this.places.groups.map((group) => {
      cards = cards.concat(group.items)
    })
    return cards
  }

  get currentCard() {
    let card:null | IPlaceCard = null
    this.places.groups.forEach((group) => {
      const saveCard = group.items.find((card) => {
        return card.name + 'page' === this.activeView.toLowerCase()
      })
      if (saveCard) {
        card = saveCard
      }
    })
    return card
  }

  handleCardClick(card: IPlaceCard) {
    this.activeView = `${upperFirst(card.name)}Page`
    localStorage.setItem('placesActiveMenu', this.activeView)
  }

  @HandleApiRequest()
  async mounted() {
    await PlaceModule.getPlaces()

    const { sections } = this.$route.query

    this.activeView = sections ? `${upperFirst(sections as string)}Page` : localStorage.getItem('placesActiveMenu') || ''

    if (!this.currentCard) {
      this.activeView = `${upperFirst(this.allCards[0].name)}Page`
    }
  }

  beforeDestroy() {
    PlaceModule.SET_STATE_PLACE({ key: 'activeOrders', value: [] })
    localStorage.setItem('apiRequests', '{}')
  }
}
