
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Rating extends Vue {
  @Prop({ type: Boolean, default: false }) changeable?: boolean
  @Prop({ type: Number, default: 0 }) value!: number

  maxRating = 5

  get orangeStars() {
    return this.value
  }

  get silverStars() {
    return this.maxRating - this.value
  }

  get spanClass() {
    return {
      'cursor-pointer': this.changeable
    }
  }

  emitValue(rate: number) {
    if (rate === this.value) {
      rate = 0
    }
    this.$emit('input', rate)
  }
}
