
import { Component, Vue } from 'vue-property-decorator'
import ResizeObserver from 'resize-observer-polyfill'
import { HandleApiRequest } from '@/utils/helper'
import { PageModule } from '@/store/page'
import { LoyaltyModule } from '@/store/loyalty'
import { Debounce } from '@/utils/decorators'
import LoyaltyPartnerOfferCard from '@/components/page/loyalty/LoyaltyPartnerOfferCard.vue'

@Component({
  components: {
    LoyaltyPartnerOfferCard
  }
})
export default class LoyaltyPartnerIdPage extends Vue {
  id = ''
  isLoading = true
  infoText = null as unknown as Element
  isInfoTextExpanded = false
  infoTextDefaultHeight: string | number = 'auto'
  infoTextHeight = 0
  resizeObserver: ResizeObserver | null = null;

  get partner() {
    return LoyaltyModule.partner
  }

  @HandleApiRequest()
  async mounted() {
    PageModule.SET_STATE_PAGE({
      key: 'routeReturnFunciton',
      value: (url: string) => {
        this.$router.go(-1)
      }
    })

    this.id = this.$route.params.id
    await LoyaltyModule.getLoyaltyPartner(this.id)

    PageModule.SET_STATE_PAGE({ key: 'breadCrumbsTitle', value: this.partner.programTitle })

    this.resizeObserver = new ResizeObserver((entries, observer) => {
      this.calcInfoTextScale()
    })

    setTimeout(() => {
      this.infoText = this.$refs.infoText as Element
      this.calcInfoTextScale()
      this.resizeObserver!.observe(this.infoText)
    })
  }

  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }

  @Debounce(350)
  async calcInfoTextScale() {
    this.infoTextHeight = 0
    await this.$nextTick()
    this.infoTextHeight = this.infoText?.getBoundingClientRect().height
    this.infoTextDefaultHeight = this.infoTextHeight < 42 ? this.infoTextHeight : 42
  }

  prepareUrl(url: string) {
    var pattern = /^((http|https):\/\/)/
    return pattern.test(url) ? url : `//${url}`
  }
}
