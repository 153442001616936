
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PlaceModule } from '@/store/place'
import { HandleApiRequest } from '@/utils/helper'

@Component
export default class DocumentsPage extends Vue {
  isLoading = true
  currentLink = ''

  @Prop({ type: Boolean }) isLayout!: boolean

  get documents() {
    return PlaceModule.documents
  }

  get isImageFile() {
    return ['jpeg', 'jpg', 'png'].includes(this.currentLink.split('.').pop() || '')
  }

  clickDocument(url: string) {
    this.currentLink = url
  }

  @HandleApiRequest({ id: 'GETCurrentplaceFiles' })
  async mounted() {
    localStorage.setItem('placesActiveMenu', 'DocumentsPage')
    await PlaceModule.getFiles()
  }
}
