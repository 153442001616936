import { VueConstructor } from 'vue'

import { PageModule } from '@/store/page'
import { AxiosError } from 'axios'

export interface AninateOptions {
  duration: number
  timing: (time: number) => number
  draw: (progress: number) => void
  complete?: Function
}

export function handleApiError(error: AxiosError | any, ctx?: any) {
  const hideErrorsMessages = ['ignoreError', 'Cannot read property \'data\' of undefined']

  if (hideErrorsMessages.includes(error.message)) {
    return false
  } else if (error.isAxiosError) {
    const message = (Array.isArray(error?.response?.data?.userMessages))
      ? error?.response?.data?.userMessages.join(', ')
      : error?.response?.data?.userMessages
    PageModule.SEND_NOTIFICATION({ type: 'error', message })
  } else {
    PageModule.SEND_NOTIFICATION({ type: 'error', message: error.message })
  }

  const loadingWords = ['isLoading']

  loadingWords.forEach((word) => {
    if (ctx && Object.prototype.hasOwnProperty.call(ctx, word)) {
      ctx[word] = false
    }
  })
}

export function animate(options: AninateOptions) {
  const start = performance.now()

  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / options.duration
    if (timeFraction > 1) timeFraction = 1

    const progress = options.timing(timeFraction)

    options.draw(progress)

    if (timeFraction < 1) {
      requestAnimationFrame(animate)
    } else if (timeFraction === 1) {
      options.complete && options.complete()
    }
  })
}

export default {
  install(Vue: VueConstructor) {
    Vue.prototype.$handleApiError = handleApiError
    Vue.prototype.$animate = animate
  }
}
