
import { Component, Vue } from 'vue-property-decorator'
import { InfoModule } from '@/store/info'
import { HandleApiRequest } from '@/utils/helper'

@Component
export default class UsefullContactsPage extends Vue {
  isLoading = false

  get contacts() {
    return InfoModule.usefullContacts
  }

  @HandleApiRequest()
  async mounted() {
    await InfoModule.getUsefullContacts()
  }
}
