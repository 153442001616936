
import { Component, Vue } from 'vue-property-decorator'
import { CounterModule } from '@/store/counter'

import SummaryParser from '@/components/general/SummaryParser.vue'
import ButtonParser from '@/components/general/ButtonParser.vue'

// eslint-disable-next-line no-unused-vars
import { IEventFull } from '@/model/page/counter'

@Component({
  components: {
    SummaryParser,
    ButtonParser
  }
})
export default class CountersEventIdPage extends Vue {
  info = {} as IEventFull
  isLoading = false

  async cancelEventid() {
    try {
      const id = this.$route.params.eventId
      await CounterModule.cancelEvent(id)
      this.$router.go(0)
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }

  async mounted() {
    try {
      this.isLoading = true

      const id = this.$route.params.eventId
      this.info = await CounterModule.getEvent(id)

      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}
