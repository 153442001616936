
import { Component, Vue, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-unused-vars
import { IInvoicesItem } from '@/model/page/invoices'

@Component
export default class TabAccounts extends Vue {
  @Prop({ required: true }) invoice!: IInvoicesItem
}
