
import { Component, Vue } from 'vue-property-decorator'
import { InfoModule } from '@/store/info'

// eslint-disable-next-line no-unused-vars
import { IOfficeItem } from '@/model/page/info'

@Component
export default class OfficesPage extends Vue {
  isLoading = false

  get offices() {
    return InfoModule.offices
  }

  getLink(office: IOfficeItem) {
    const { latitude, longitude } = office
    if (!parseFloat(latitude) || !parseFloat(longitude)) {
      return null
    }
    return `https://yandex.ru/maps/?rtext=~${latitude},${longitude}&rtt=auto`
  }

  async mounted() {
    try {
      this.isLoading = true
      await InfoModule.getOffices()
      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error)
    }
  }
}
