
import { Component, Vue } from 'vue-property-decorator'
import { HandleApiRequest } from '@/utils/helper'

import PeopleIdLayout from '@/components/general/PeopleIdLayout.vue'

import { PlaceModule } from '@/store/place'
import { PageModule } from '@/store/page'

// eslint-disable-next-line no-unused-vars
import { IPlacesLayout, IPlaceCard } from '@/model/page/counter'

@Component({
  components: { PeopleIdLayout }
})
export default class PeopleIdPage extends Vue {
  isLoading = true
  human = {}

  @HandleApiRequest()
  async mounted() {
    if (PageModule.routePast) {
      PageModule.SET_STATE_PAGE({
        key: 'routeReturnFunciton',
        value: () => this.$router.go(-1)
      })
    }

    const { id } = this.$route.params
    const human = await PlaceModule.getHuman(id)
    this.human = human
  }
}
