import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'

import store from '@/store'
import { $get, $post, $put, $remove } from '@/plugins/axios'

import {
  ICost,
  IAdvert,
  ICategory,
  IReachType,
  IAdvertFull,
  IAdvertLayout,
  IAdvertFilters
} from '@/model/page/adverts'

export interface AdvertState {
  advert: IAdvertFull | null
  adverts: Array<IAdvert>
  advertsMy: Array<IAdvert>
  category: Array<ICategory>
  reachType: Array<IReachType>
  filterOptions?: IAdvertFilters
}

@Module({ dynamic: true, store, name: 'advert' })
class Advert extends VuexModule implements AdvertState {
  advert: IAdvertFull | null = null
  adverts = [] as Array<IAdvert>
  advertsMy = [] as Array<IAdvert>
  category = [] as Array<ICategory>
  reachType = [] as Array<IReachType>
  agreement = ''
  rules = {}
  smartHint = [] as Array<string>
  filterOptions = {} as IAdvertFilters

  get allCategories() {
    const flatten = (arr: any) => {
      return arr.reduce((acc: any, val: any) => {
        if (Array.isArray(val.items) && val.items.length) {
          acc.push(...flatten(val.items))
        } else {
          acc.push(val)
        }
        return acc
      }, [])
    }
    return flatten(this.category)
  }

  @Mutation
  SET_STATE_ADVERT<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Mutation
  ADD_ITEMS<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    // @ts-ignore
    this[key] = this[key].concat(value)
  }

  @Action
  async getAdverts({ params }: { params?: { fromRow?: number } } = { params: { fromRow: 0 } }) {
    const { fromRow } = params || {}

    if (fromRow === 0) {
      this.SET_STATE_ADVERT({ key: 'adverts', value: [] })
    }

    const { data } = await $get<IAdvertLayout>('/ad-board', { params })

    this.ADD_ITEMS({ key: 'adverts', value: data.items })

    delete data.items

    return data
  }

  @Action
  async updateAdvert({ form, id }: { form: any, id: number | string}) {
    const { data } = await $put(`/ad-board/${id}`, form)

    return form
  }

  @Action
  async filterAdverts(params?: any) {
    const { data } = await $get<IAdvertFilters>('/ad-board/filters', { params })

    this.SET_STATE_ADVERT({ key: 'filterOptions', value: data })

    return data
  }

  @Action
  async getAdvert(id: number | string) {
    if (this.advert?.id === parseInt(id + '')) {
      return
    }
    this.SET_STATE_ADVERT({ key: 'advert', value: null })

    const { data } = await $get<IAdvertFull>(`/ad-board/${id}`)

    this.SET_STATE_ADVERT({ key: 'advert', value: data })
  }

  @Action
  async getAdvertsMy(fromRow = 0) {
    if (fromRow === 0) {
      this.SET_STATE_ADVERT({ key: 'advertsMy', value: [] })
    }

    const { data } = await $get<IAdvertLayout>('/ad-board/my', { params: { fromRow } })

    this.ADD_ITEMS({ key: 'advertsMy', value: data.items })

    delete data.items

    return data
  }

  @Action
  async submitAdvert(form: any) {
    const { data } = await $post<IAdvertFull>('/ad-board', form)

    this.SET_STATE_ADVERT({ key: 'advert', value: data })
  }

  @Action
  async deleteAdvert(id: number | string) {
    await $remove(`/ad-board/${id}`)
  }

  @Action
  async publishAdvert(id: number | string) {
    await $put(`/ad-board/${id}/publish`)
  }

  @Action
  async unpublishAdvert(id: number | string) {
    await $put(`/ad-board/${id}/unpublish`)
  }

  @Action
  async republishAdvert(id: number | string) {
    await $put(`/ad-board/${id}/republish`)
  }

  @Action
  async getReachType() {
    const { data } = await $get<Array<IReachType>>('/ad-board/reach-type')

    this.SET_STATE_ADVERT({ key: 'reachType', value: data })
  }

  @Action
  async getCost({ category: advertCategoryId, reachType: advertReachTypeId, placeId, buildingId }: Record<'category' | 'reachType' | 'placeId' | 'buildingId', number >) {
    const { data } = await $post<ICost>('/ad-board/cost', { advertCategoryId, advertReachTypeId, placeId, buildingId })

    return data
  }

  @Action
  async getPlacement() {
    const { data } = await $get('/ad-board/rules/placement')

    this.SET_STATE_ADVERT({ key: 'agreement', value: data.content })
  }

  @Action
  async getCategory() {
    const { data } = await $get<Array<ICategory>>('/ad-board/category')

    this.SET_STATE_ADVERT({ key: 'category', value: data })
  }

  @Action
  async getSmartHint(categoryId: number | string) {
    const { data } = await $get(`/ad-board/smartHint/${categoryId}`)

    this.SET_STATE_ADVERT({ key: 'smartHint', value: data.items })
  }
}

export const AdvertModule = getModule(Advert)
