
import { Component, Vue } from 'vue-property-decorator'
import { PlaceModule } from '@/store/place'

import ActiveOrders from '@/components/page/place/ActiveOrders.vue'
import { HandleApiRequest } from '@/utils/helper'

// eslint-disable-next-line no-unused-vars
import { IPlaceCard } from '@/model/page/counter'

@Component({
  components: {
    ActiveOrders
  }
})
export default class PlaceMobileLayout extends Vue {
  isLoading = true
  swiperOption = {
    slidesPerView: 'auto',
    spaceBetween: 20
  }

  get places() {
    return PlaceModule.places
  }

  get orders() {
    return PlaceModule.activeOrders
  }

  get allCards(): Array<IPlaceCard> {
    let cards: Array<IPlaceCard> = []
    this.places.groups.map((group) => {
      cards = cards.concat(group.items)
    })
    return cards
  }

  handleCardClick(card: IPlaceCard) {
    const object: any = {
      history: 'orders',
      specification: 'info'
    }

    this.$router.push(`/places/${object[card.name] || card.name}`)
  }

  @HandleApiRequest()
  async mounted() {
    await PlaceModule.getPlaces()
    await PlaceModule.getActiveOrders()
  }
}
