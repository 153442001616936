
import { Component, Vue } from 'vue-property-decorator'

import AdvertModifyLayout from '@/components/page/adverts/AdvertModifyLayout.vue'

@Component({
  components: {
    AdvertModifyLayout
  }
})
export default class AdvertsAddPage extends Vue {}
