
import { Component, Vue } from 'vue-property-decorator'
import PeopleAvatar from '@/components/page/place/PeopleAvatar.vue'
import { UserModule } from '@/store/user'
// eslint-disable-next-line no-unused-vars
import { IHuman } from '@/model/page/counter'
// eslint-disable-next-line no-unused-vars
import { ICurrentCustomer } from '@/model'
import { HandleApiRequest, HandleError } from '@/utils/helper'
import { PlaceModule } from '@/store/place'
import { $post } from '@/plugins/axios'
import { PageModule } from '@/store/page'
import { AxiosError } from 'axios'

interface UserFio {
  firstName: string,
  lastName: string,
  middleName: string
}
@Component({
  components: {
    PeopleAvatar
  }
})
export default class PeopleIdLayout extends Vue {
  loading = false;
  types = [];
  data = {
    firstName: this.user.firstName,
    middleName: this.user.middleName,
    lastName: this.user.lastName,
    shortName: this.user.shortName,
    fullName: this.user.fullName,
    customerTypeId: this.user.customerTypeId
  } as Record<string, string | number>;

  get user(): Partial<ICurrentCustomer> {
    return UserModule.user
  }

  get isValid(): boolean {
    if (this.data.customerTypeId === 1 || this.data.customerTypeId === 2) {
      const { firstName, lastName } = this.data
      if (firstName !== '' && lastName !== '') return true
      else return false
    } else {
      const { fullName, shortName } = this.data
      if (fullName !== '' && shortName !== '') return true
      else return false
    }
  }

  async UpdateFIO() {
    try {
      this.loading = true
      await PlaceModule.UpdateUserFio(this.data)
      await this.$router.push('/info/personal/')
    } catch (error) {
      this.$handleApiError(error)
    } finally {
      this.loading = false
    }
  }

  @HandleApiRequest()
  async mounted() {
    await UserModule.getUserProfile()
    this.types = await PlaceModule.GetCustomerTypes()
  }
}
