
import { Component, Vue } from 'vue-property-decorator'

import { PageModule } from '@/store/page'

@Component
export default class AppSnackbar extends Vue {
  get show() {
    return PageModule.alert.show
  }

  set show(value) {
    const alert = Object.assign({}, PageModule.alert, { show: value })
    PageModule.SET_STATE_PAGE({ key: 'alert', value: alert })
  }

  get alert() {
    return PageModule.alert
  }
}
