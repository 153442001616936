import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'

import store from '@/store'
import { $get, $post, $put } from '@/plugins/axios'
import uniqBy from 'lodash/uniqBy'

import { IItemsLayout, ApiItemsLayout } from '@/model/index'

import {
  IEvent,
  ICounter,
  IEventFull,
  GetDashboard,
  GetCounterChart,
  ICounterFormLayout,
  ICounterFormSubmitLayout
} from '@/model/page/counter'

interface HistoryOptions {
  eventTypeIds: number
  fromRow: number
  searchQuery?: string
}

export interface CounterState {
  counters: Array<ICounter>
}

@Module({ dynamic: true, store, name: 'counter' })
class Counter extends VuexModule implements CounterState {
  counters = [] as Array<ICounter>
  history = [] as Array<IEvent>
  generalCounters = [] as Array<ICounter>
  generalHistory = [] as Array<IEvent>

  @Mutation
  SET_STATE_COUNTER<S extends this, K extends keyof this>({ key, value }: { key: K, value: S[K] }) {
    this[key] = value
  }

  @Mutation
  ADD_EVENTS(items: Array<IEvent>) {
    this.history = uniqBy([...this.history, ...items], 'id')
  }

  @Action
  async getCounterForm(query: Record<string, string>) {
    const { data } = await $get<ICounterFormLayout>('meteringdata/form', { params: { ...query } })

    return data
  }

  @Action
  async submitForm({ meteringGroupId, form }: ICounterFormSubmitLayout) {
    const { data } = await $post<ICounterFormLayout>('meteringdata/bunchCreate', form, { params: { meteringGroupId } })

    return data
  }

  @Action
  async getCounterChart(query: Record<string, string>) {
    const { data } = await $get<GetCounterChart>('meteringdata/chart', { params: { ...query } })

    return data
  }

  @Action
  async getCounters(isGeneral = 0) {
    const { data } = await $get<GetDashboard>('/meteringdata/dashboard/', { params: { isGeneral } })

    this.SET_STATE_COUNTER({ key: isGeneral === 0 ? 'counters' : 'generalCounters', value: data.items })

    return data
  }

  @Action
  async getEvents({ eventTypeIds, fromRow, searchQuery }: HistoryOptions) {
    const { data } = await $get<ApiItemsLayout<IEvent>>('/events/', { params: { eventTypeIds, fromRow, searchQuery } })

    this.ADD_EVENTS(data.items)

    return data.nextRow
  }

  @Action
  async getEvent(id: number | string) {
    const { data } = await $get<IEventFull>(`/events/${id}`)

    return data
  }

  @Action
  async cancelEvent(id: number | string) {
    await $get<IEventFull>(`/events/${id}/cancel`)
  }

  @Action
  async updateMetricName({ label, id }: { label: string, id: number }) {
    await $put('/meteringdata/deviceLabel', null, { params: { meteringDeviceLabel: label, meteringDeviceId: id } })
  }
}

export const CounterModule = getModule(Counter)
