
import { Component, Vue } from 'vue-property-decorator'
import { AdvertModule } from '@/store/advert'

import AdvertBlock from '@/components/page/adverts/AdvertBlock.vue'

// eslint-disable-next-line no-unused-vars
import { IAdvertLayout } from '@/model/page/adverts'

@Component({
  components: {
    AdvertBlock
  }
})
export default class AdvertsPage extends Vue {
  isLoading = false
  optionPage: IAdvertLayout | null = null

  get isMy() {
    return this.$route.name === 'advertsMy'
  }

  get isMoreExist() {
    return (this.optionPage?.nextRow || 0) > 0
  }

  get adverts() {
    return this.isMy ? AdvertModule.advertsMy : AdvertModule.adverts
  }

  get advertsAmount() {
    return this.optionPage?.totals.itemsCount || 0
  }

  async loadMore(entries: Array<IntersectionObserverEntry>) {
    if (entries[0].isIntersecting) {
      await this.load()
    }
  }

  async load() {
    const { query } = this.$route

    if (this.isMy) {
      this.optionPage = await AdvertModule.getAdvertsMy(this.optionPage?.nextRow || 0)
    } else {
      this.optionPage = await AdvertModule.getAdverts({ params: { fromRow: this.optionPage?.nextRow || 0, ...query } }) as IAdvertLayout
    }
  }

  async mounted() {
    try {
      this.isLoading = true

      await this.load()

      this.isLoading = false
    } catch (error) {
      this.$handleApiError(error, this)
    }
  }
}
